"use strict";

const ProjectEnforcementController = require("./projectEnforcementController");
const BaseEnforcementLetterManagement = require("../../enforcement/baseEnforcementLetterManagement");

const EnforcementLetterManagement = function () {
  const base = BaseEnforcementLetterManagement(ProjectEnforcementController);

  return {
    ...base,
  };
};

module.exports = EnforcementLetterManagement();
