"use strict";

const NewProjectInspectionValidator = function () {
  var getMissingRequiredFields = function (allData) {
    return FormValidator.getEmptyRequiredFieldsInData(
      allData,
      getMissingGeneralInfoFields(allData),
    );
  };

  var getMissingGeneralInfoFields = function (data) {
    var fields = [];

    fields.push({
      fieldName: "inspector-name",
      message: "Inspector Name",
    });

    fields.push({
      fieldName: "start-time",
      message: "Inspection Date & Start Time",
    });

    fields.push({
      fieldName: "inspection-type",
      message: "Inspection Type",
    });

    const observationData = Tree.get("currentProjectInspectionObservationData");
    if (observationData?.nonCompliantFindingsCount > 0) {
      fields.push({
        fieldName: "follow-up-inspection-type",
        message: "Follow Up Inspection Type",
      });
      fields.push({
        fieldName: "followUpInspectionDate",
        message: "Follow Up Due Date",
      });

      fields.push({
        fieldName: "isSiteRepPresent",
        message: "Is the site representative on site?",
      });
    }

    fields.push({
      fieldName: "flagEnforcement",
      message: "Flag for enforcement",
    });

    if (data.isSiteRepPresent) {
      fields.push({
        fieldName: "signatures[siteRepDate]",
        message: "Site representative signature date",
      });
      fields.push({
        fieldName: "signatures[siteRepName]",
        message: "Site representative name",
      });
      fields.push({
        fieldName: "signatures[siteRepEsignature]",
        message: "Site representative signature",
      });
    }

    fields.push({
      fieldName: "end-time",
      message: "Inspection End Date & Time",
    });
    fields.push({
      fieldName: "signatures[inspectorName]",
      message: "Inspector name",
    });
    fields.push({
      fieldName: "signatures[inspectorEsignature]",
      message: "Inspector signature",
    });

    return fields;
  };

  return {
    getMissingRequiredFields,
  };
};

module.exports = NewProjectInspectionValidator();

const FormValidator = require("../../general/formValidator");
const Tree = require("../../tree");
