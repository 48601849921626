"use strict";

const NewProjectInspectionSubmission = function () {
  const stringKey = "submission";
  const tabHeader = "Submission";
  let additionalCorrectiveActionConstants;
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    renderSummary(data);

    $page.on("click", "[name='preview']", previewButtonClick);
    $page.on("2N:FormInput", "[name='isSiteRepPresent']", onIsSiteRepPresentChange);
    $page.on("2N:FormInput", "[name='start-time'], [name='end-time']", onStartOrEndDateChange);
    $page.on("2N:FormInput", "[name='materialsDistributed']", onMaterialsDistributedChange);
  };

  var unloadListeners = function () {
    $page.off("click", "[name='preview']", previewButtonClick);
    $page.off("2N:FormInput", "[name='isSiteRepPresent']", onIsSiteRepPresentChange);
    $page.off("2N:FormInput", "[name='start-time'], [name='end-time']", onStartOrEndDateChange);
    $page.off("2N:FormInput", "[name='materialsDistributed']", onMaterialsDistributedChange);
  };

  var getProps = function (data) {
    additionalCorrectiveActionConstants =
      ToolSettings.getSetting("construction", "project-inspection-constants")
        .additionalCorrectiveActionConstants ?? [];

    return {
      additionalCorrectiveActionsByCategory: getAdditionalCorrectiveActionsByCategory(),
      materialTypeOptions: ToolSettings.getDomainOptions("project_inspection_material_type"),
      followUpInspectionTypeOptions:
        NewProjectInspectionConstants.getFollowUpInspectionTypeOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onIsSiteRepPresentChange = function (e, isSiteRepPresent) {
    const $fieldsContainer = $("#is-site-rep-present-field-container-js");
    $fieldsContainer.toggle(isSiteRepPresent);
    // Field data is conditionally nulled in the API
  };

  var previewButtonClick = async function () {
    return await InspectionPreview.preview(function () {
      const previewData = NewProjectInspectionController.getDataToUpload();
      previewData.projectId = NewProjectInspectionController.getAllData().project.id;
      return previewData;
    }, ApiCalls.downloadConstructionProjectInspectionPreview);
  };

  var onStartOrEndDateChange = function () {
    const startTime = $("[name='start-time']").val();
    const endTime = $("[name='end-time']").val();
    const $total = $("[name='total-inspection-time']");

    setDateRanges();
    if (startTime === "" || endTime === "") {
      $total.val("");
    } else {
      const minutes = Math.floor(
        (new Date(endTime) - new Date(startTime)) / (1000 * 60),
      ).toLocaleString("en-us");
      $total.val(minutes);
    }
  };

  var setDateRanges = function () {
    const startTime = $("[name='start-time']").val();
    const endTime = $("[name='end-time']").val();

    if (startTime) {
      DateTimePicker.setDatePickerRange($("#assessment-modal"), "end-time", false, startTime);
    }
    if (endTime) {
      DateTimePicker.setDatePickerRange($("#assessment-modal"), "start-time", true, endTime);
    }
  };

  var onMaterialsDistributedChange = function (e, materialsDistributed) {
    const $materialTypeField = $(".material-type");
    $materialTypeField.toggle(materialsDistributed);
  };

  var getActionItemsCheckboxValues = function () {
    const $actionItems = $('[name="actionItems"]:checked');
    return $actionItems.map((i, item) => item.value).get();
  };

  var getAdditionalCorrectiveActionsByCategory = function () {
    const grouped = new Map();
    const tempCategory = "Addional Corrective Actions";

    additionalCorrectiveActionConstants.forEach(function (item) {
      item.category = tempCategory;

      if (!grouped.has(item.category)) {
        grouped.set(item.category, []);
      }
      const category = grouped.get(item.category);
      category.push({
        name: item.display,
        value: item.id,
      });
    });
    return grouped;
  };

  var renderSummary = function (data) {
    $page
      .find("#js-new-project-inspection-submission-summary")
      .html(NewProjectInspectionSummary.getSummaryHtml(data));

    const observationData = Tree.get("currentProjectInspectionObservationData");
    $(".follow-up-section").toggle(observationData?.nonCompliantFindingsCount > 0);
  };

  var tabShownCallback = function () {
    setDateRanges();
  };

  return {
    loadListeners,
    unloadListeners,
    onStartOrEndDateChange,
    getStringKey,
    getTabHeader,
    getProps,
    getActionItemsCheckboxValues,
    renderSummary,
    tabShownCallback,
  };
};

module.exports = NewProjectInspectionSubmission();

const ApiCalls = require("../../apiCalls");
const NewProjectInspectionController = require("./newProjectInspectionController");
const NewProjectInspectionConstants = require("./newProjectInspectionConstants");
const InspectionPreview = require("../../general/inspectionPreview");
const ToolSettings = require("../../settings/toolSettings");
const NewProjectInspectionSummary = require("./newProjectInspectionSummary");
const DateTimePicker = require("../../general/dateTimePicker");
const Tree = require("../../tree");
