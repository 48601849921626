"use strict";

function FacilitySelfInspectionPage() {
  const stringKey = "page";

  function loadListeners(data) {}

  function unloadListeners() {}

  function getProps(data) {
    return {
      customCompliantRadioLabels: FacilityInspectionConstants.getCustomCompliantRadioLabels(),
      followUpInspectionTypeOptions: FormSettings.getDropdownOptions("follow-up-inspection-type"),
      contactIdOptions: getProjectContactIdOptions(data.businessOwnerContacts),
    };
  }

  function getStringKey() {
    return stringKey;
  }

  function getTabHeader() {
    return null;
  }

  function getProjectContactIdOptions(contacts) {
    contacts = (contacts ?? []).map(function (contact) {
      return {
        value: contact.id,
        name: `${contact.name}`,
      };
    });
    if (contacts.length === 1) {
      const initialFormData = FacilitySelfInspectionController.getInitialFormDataObject();
      initialFormData.selfInspection = initialFormData.selfInspection ?? {};
      initialFormData.selfInspection.contactId = contacts[0].value;
    }
    return contacts;
  }

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
}

module.exports = FacilitySelfInspectionPage();

const FormSettings = require("../../settings/formSettings");
const FacilityInspectionConstants = require("../facilityInspection/facilityInspectionConstants");
const FacilitySelfInspectionController = require("./facilitySelfInspectionController");
