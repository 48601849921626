"use strict";

const NewProjectInspectionController = function () {
  const htmlPath = "modals/newProjectInspection";
  const formKey = "project-inspection";

  let $modal;
  let tabs;
  let dataStore;

  var loadNewProjectInspection = async function (projectId, readOnly = false) {
    var newDataStore;

    try {
      newDataStore = await ResourceController.get("newConstructionProjectInspection").createNew(
        projectId,
      );
    } catch (e) {
      if (!(e instanceof DataStoreError)) {
        throw e;
      }

      return;
    }

    _showProjectInspectionModal(newDataStore, readOnly);
  };

  var loadExistingProjectInspection = async function (inspectionId, readOnly = false) {
    var newDataStore;

    try {
      newDataStore = await ResourceController.get("newConstructionProjectInspection").openExisting(
        inspectionId,
      );
    } catch (e) {
      if (!(e instanceof DataStoreError)) {
        throw e;
      }

      return;
    }
    _showProjectInspectionModal(newDataStore, readOnly);
  };

  var _showProjectInspectionModal = function (newDataStore, readOnly) {
    dataStore = newDataStore;
    tabs = [
      NewProjectInspectionGeneralInfo,
      NewProjectInspectionOpenFindings,
      NewProjectInspectionObservations,
      NewProjectInspectionSubmission,
    ];

    const renderData = mapTabProps(tabs);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });
    TabbedModal.setHeaderText(getHeaderHtml(renderData));
    Form.initializeAndLoadListeners($modal, formKey, { dataStore });
    FormSettings.customizeForm($modal, formKey);
    loadDropzone();
    loadListeners();
    setAdditionalModalHandlers();
    onChangeInspectionType();
    selectInspectionTypeCdot();
  };

  var loadListeners = function () {
    unloadListeners();
    $modal.on("2N:FormInput", ".compliant input, .condition select", rerenderSummary);
    $modal.on("2N:FormInput", "[name='inspection-type']", onChangeInspectionType);
  };

  var unloadListeners = function () {
    $modal.off("2N:FormInput", ".compliant input, .condition select", rerenderSummary);
    $modal.off("2N:FormInput", "[name='inspection-type']", onChangeInspectionType);
  };

  var onChangeInspectionType = function () {
    NewProjectInspectionObservations.onChangeInspectionType($modal);
    toggleVisibleTabs();
    rerenderSummary();
  };

  var rerenderSummary = function () {
    NewProjectInspectionSubmission.renderSummary(getAllData());
  };

  var reloadListeners = function () {
    if (!$modal) {
      return;
    }

    Form.initializeDatePickers($modal, formKey);
    Form.initializeAutoNumericFields($modal);
  };

  var mapTabProps = function (tabs) {
    const data = dataStore.getWithUpdates();

    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
      base: Config.get().apiUrl,
    };
  };

  var getHeaderHtml = function (data) {
    return nunjucks.render("modals/newProjectInspection/projectInspectionHeader.njk", data);
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {
      dataStore.discard();
      unloadListeners();
    });
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var saveButtonClick = function () {
    return new Promise((resolve) => {
      const fields = NewProjectInspectionValidator.getMissingRequiredFields(getAllData());
      _saveOrWarnInvalid(fields);
      resolve(false);
    });
  };

  var _saveOrWarnInvalid = function (fields) {
    showSaveAndWarnModal(fields);
    highlightInvalidFields(fields);
  };

  var saveAndCloseModal = async function () {
    let uploadingData;

    try {
      await Form.getReadyToSavePromise(formKey);
      if (dataStore.getForm().locked) {
        await dataStore.lock();
      } else {
        await dataStore.save();
      }

      TabbedModal.hide();
      unloadListeners();
      Tree.set("newConstructionProjectInspection", {});
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return uploadingData;
  };

  var getDataToUpload = function () {
    return getUpdateData(dataStore.getInitial(), dataStore.getForm(), dataStore.isNew());
  };

  var getUpdateData = function (initialData, dataToUpload, isNew) {
    if (isNew) {
      dataToUpload = mergeDefaultsIntoUpdates(initialData, dataToUpload);
    }

    prepareObservations(dataToUpload.inspectionObservations);
    addInspectionScore(dataToUpload);

    if (isNew) {
      return {
        projectId: initialData.project?.id,
        updates: dataToUpload,
      };
    } else {
      return {
        id: initialData.id,
        updates: dataToUpload,
      };
    }
  };

  var mergeDefaultsIntoUpdates = function (initialData, dataToUpload) {
    const result = $.extend(true, {}, initialData, dataToUpload);
    return result;
  };

  var prepareObservations = function (inspectionObservationUpdates) {
    for (const constantId in inspectionObservationUpdates) {
      if (inspectionObservationUpdates[constantId].length === 0) {
        delete inspectionObservationUpdates[constantId];
        return;
      }
      const observationUpdates = inspectionObservationUpdates[constantId];
      for (const index in observationUpdates) {
        const update = observationUpdates[index];
        const $observationCard = $modal.find(
          `.card[data-constant-id="${constantId}"][data-index="${index}"]`,
        );
        if ($observationCard?.length) {
          const findingsCode = $observationCard.data("findingsCode");
          if (findingsCode) {
            update.findingsCode = findingsCode;
          }
        }
      }

      inspectionObservationUpdates[constantId] = filterDeletedAndUnsaved(
        inspectionObservationUpdates[constantId],
      );
    }
  };

  var addInspectionScore = function (dataToUpload) {
    const observationData = Tree.get("currentProjectInspectionObservationData");

    dataToUpload.score = observationData?.scoreNumber;
  };

  var filterDeletedAndUnsaved = function (array) {
    return array.filter(
      (item) => item && (item.deleted !== true || item.findingsCode !== undefined),
    );
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getAllData = function () {
    return dataStore.getWithUpdates();
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var getFormKey = function () {
    return formKey;
  };

  var showSaveAndWarnModal = function (fields) {
    const messages = fields.map((field) => field.message);
    const uniqueMessages = new Set(messages);

    const incompleteFields = {
      entityName: dataStore.getInitial().project.projectIdentifier,
      entityKind: "inspection",
      incompleteFieldNames: [...uniqueMessages],
    };
    const buttonCallbacks = {
      cancelCallback: null,
      saveCallback: saveAndCloseModal,
      submitCallback: function () {
        setFormData(["locked"], true);
        saveAndCloseModal();
      },
    };

    SaveIncompleteModal.showWarnSaveSubmitModal(incompleteFields, buttonCallbacks);
  };

  var highlightInvalidFields = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    CommonModalFunctions.highlightFormFields($modal, fieldNames);
  };

  var selectInspectionTypeCdot = function () {
    if (Session.isCdot()) {
      $('[name="inspection-type"] option:eq(1)')
        .prop("selected", true)
        .trigger("input")
        .parents("fieldset")
        .hide();
    }
  };

  var toggleVisibleTabs = function () {
    const inspectionType = getAllData()?.inspectionType ?? null;
    TabbedModal.toggleTab("observations", !!inspectionType);

    ["open-findings"].forEach(function (tabIdentifier) {
      const $availableDocuments = $modal.find(`#${tabIdentifier} .match`);
      TabbedModal.toggleTab(tabIdentifier, $availableDocuments.length > 0);
    });
  };

  var loadDropzone = function () {
    const existingFiles = dataStore.getInitial().generalFiles ?? [];

    Form.initializeDropzone(formKey, $modal, {
      existingFiles: existingFiles,
      newFiles: dataStore.getForm().generalFiles,
      downloadCallback: ApiCalls.downloadConstructionProjectInspectionFile,
    });
  };

  var postUpdate = function () {
    ConstructionProjectGeoServerLayer.invalidateLayerData();
    ToDoListController.loadTodos();
  };

  return {
    getAllData,
    loadNewProjectInspection,
    loadExistingProjectInspection,
    saveAndCloseModal,
    setFormData,
    getFormKey,
    reloadListeners,
    getDataToUpload,
    _showProjectInspectionModal,
    getUpdateData,
    _saveOrWarnInvalid,
    postUpdate,
  };
};

module.exports = NewProjectInspectionController();

const NewProjectInspectionGeneralInfo = require("./newProjectInspectionGeneralInfo");
const NewProjectInspectionObservations = require("./newProjectInspectionObservations");
const NewProjectInspectionOpenFindings = require("./newProjectInspectionOpenFindings");
const NewProjectInspectionSubmission = require("./newProjectInspectionSubmission");

const ApiCalls = require("../../apiCalls");
const Session = require("../../login/session");
const CommonModalFunctions = require("../../modals/commonModalFunctions");
const Config = require("../../config");
const Form = require("../../general/form");
const FormSettings = require("../../settings/formSettings");
const SaveIncompleteModal = require("../../modals/saveIncompleteModal");
const TabbedModal = require("../../general/tabbedModal");
const UploadValidator = require("../../general/uploadValidator");
const NewProjectInspectionValidator = require("./newProjectInspectionValidator");
const ResourceController = require("../../offline/resourceController");
const ToDoListController = require("../../mapFunctions/toDoListController");
const ConstructionProjectGeoServerLayer = require("../../constructionG2/constructionProjectGeoServerLayer");
const DataStoreError = require("../../errors/dataStoreError");
const Tree = require("../../tree");
