"use strict";

const IncidentInventoryModalController = function () {
  const incidentInventoryFormKey = "incident-inventory";
  var groupKey = null;
  var generatedNameFields = {
    salinas: ["requestedDate"],
    hollister: ["requestedDate"],
    cdot: ["requestedDate", "highwayAssignment", "region"],
  };

  function loadFormListener() {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  }

  function getLatestIncidentInventoryData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(incidentInventoryFormKey, path);
  }

  function isNewRecord() {
    return !getLatestIncidentInventoryData("id");
  }

  async function requestGeneratedName() {
    var data = {};
    $.each(generatedNameFields[groupKey], function (i, fieldName) {
      data[fieldName] = Form.getFormDataAtPath(incidentInventoryFormKey, [fieldName]);
    });
    const incidentName = await ApiCalls.generateIncidentName(data);
    setFormDataField("incidentName", incidentName);
    $("input[name='incident-name']").val(incidentName);
  }

  async function onFormInput(e, value, path) {
    setModalTitle();

    if (path[0] === "requestingAffiliation") {
      hideOrShowPartnerAgencyField(value);
    }

    if (isNewRecord()) {
      if (!groupKey) {
        if (Session.isSalinas()) groupKey = "salinas";
        if (Session.isCdot()) groupKey = "cdot";
        if (Session.isHollister()) groupKey = "hollister";
      } else {
        if (generatedNameFields[groupKey].includes(path[0])) {
          Misc.debounce(requestGeneratedName);
        }
      }
    }

    checkEnableSaveButton(value, path);
  }

  function hideOrShowPartnerAgencyField(value) {
    value === "partner-agency"
      ? $("#incident-inventory-partner-agency").show()
      : $("#incident-inventory-partner-agency").hide();
  }

  function getExistingData(path) {
    const pathToCheck = $.isArray(path) ? path : [path];
    return Tree.get(["asset", incidentInventoryFormKey, ...pathToCheck]);
  }

  function unsetFormDataField(path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(incidentInventoryFormKey, path);
    return Form.getDataFromForm(incidentInventoryFormKey, false);
  }

  async function invokeIncidentInventoryModal(maybeId, options, startingPage) {
    InventoryModal.setOpenInventory(incidentInventoryFormKey);
    if (maybeId) {
      const incident = await ApiCalls.getIncident(maybeId);
      return initializeIncidentInventoryModalPages(incident, "incident-set-location", {
        existingLocation: true,
      });
    } else {
      const incident = IncidentInventoryConstants.getIncidentInventoryDefaults();
      if (Session.isSalinas() || Session.isCdot() || Session.isHollister()) {
        incident.incidentName = await ApiCalls.generateIncidentName();
      }
      return initializeIncidentInventoryModalPages(incident, startingPage, options);
    }
  }

  function invokeIncidentInventoryModalFromAsset(id, name, assetType, assetDisplayType) {
    InventoryModal.setOpenInventory(incidentInventoryFormKey);

    return initializeIncidentInventoryModalPages(
      IncidentInventoryConstants.getIncidentInventoryDefaults({
        id,
        name,
        assetType,
        assetDisplayType,
      }),
    );
  }

  async function initializeIncidentInventoryModalPages(processedData, startingPage, options = {}) {
    const pageOptions = {
      existingLocation: !!(processedData || {}).projectIdentifier,
      ...options,
    };

    $("#modalMap").hide();
    Tree.set(["asset", incidentInventoryFormKey], processedData);
    resetAllPages();
    const inputPages = getIncidentInventoryPages();

    await InventoryModal.showModal(inputPages, startingPage, pageOptions, {
      saveAndCloseHandler: checkSaveAndClose,
      cancelHandler: closeInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
    });

    if (processedData.id) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreSaveButton();
    } else {
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    }

    setModalTitle();

    InventoryModal.setHideInventoryOnClose(!options?.modalInModal);
    loadFormListener();
  }

  function noUpdatesInForm() {
    return Object.keys(Form.getDataFromForm(incidentInventoryFormKey, false)).length === 0;
  }

  function getIncidentInventoryPages() {
    const basePages = [IncidentInventorySetLocation, IncidentInventoryGeneralInfo];
    return basePages;
  }

  function loadExistingIncidentMarker() {
    const latlng = {
      lat: getLatestIncidentInventoryData("latitude"),
      lng: getLatestIncidentInventoryData("longitude"),
    };
    const marker = new L.marker(latlng, {
      icon: IncidentsIcon.getIncidentsNewMarkerIcon(),
      zIndexOffset: 2000,
    });
    ModalMap.setSingleMarker(marker);
  }
  function setModalTitleRequest() {
    const requestingType = getLatestIncidentInventoryData("requestingType");
    if (requestingType) {
      const requestName = IncidentInventoryConstants.getRequestingTypeOptions().getObjectWith(
        "value",
        requestingType,
      )?.name;
      InventoryModal.setModalTitle("Request Type: ", requestName, "catchid");
    } else {
      InventoryModal.setModalTitle("", null, "catchid");
    }
  }

  function setModalTitle() {
    const incidentName = getLatestIncidentInventoryData("incidentName");
    if (incidentName === undefined || incidentName === "") {
      InventoryModal.setModalTitle("Add New Incident");
    } else {
      const assetDisplayName = ToolSettings.getSetting(
        "constants",
        "assetDisplayNames",
        "incident",
      );
      InventoryModal.setModalTitle(`${assetDisplayName} ID: `, incidentName);
    }
    setModalTitleRequest();
  }
  function resetAllPages() {
    const allPages = [IncidentInventorySetLocation, IncidentInventoryGeneralInfo];
    allPages.forEach((page) => {
      if (page.resetState) {
        page.resetState();
      }
    });
  }

  function checkSaveAndClose() {
    return new Promise((resolve) => {
      saveAndCloseModal();
      resolve(false);
    });
  }

  function closeInventory() {
    resetInventory();
  }
  function resetInventory() {
    Form.clearForm(incidentInventoryFormKey);
    ModalMap.resetModalMap();
    ModalMap.setModalMapView();
  }

  function checkEnableSaveButton(value, path) {
    const name = getLatestIncidentInventoryData("incidentName");
    const requestingType = getLatestIncidentInventoryData("requestingType");

    name && requestingType
      ? InventoryModal.readySaveState(true)
      : InventoryModal.readySaveState(false);
  }

  function setFormDataField(path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(incidentInventoryFormKey, path, value);
  }

  function getInventoryForRender() {
    return InventoryModal.getAllData(incidentInventoryFormKey);
  }

  function renderPageContent(html) {
    InventoryModal.setModalDynamicContent(html);
  }

  function closeModal() {
    const IncidentLayer = require("./incidentLayer");

    InventoryModal.hide();
    IncidentLayer.invalidateLayerData();
  }

  async function saveAndCloseModal() {
    try {
      await Form.getReadyToSavePromise(incidentInventoryFormKey);
      const existingId = getExistingData("id");

      const incidentToLoad = Form.getDataFromForm(incidentInventoryFormKey);
      if (existingId) {
        const data = Form.toFormData({
          incidentId: existingId,
          incidentUpdates: incidentToLoad,
        });

        await ApiCalls.updateIncident(data);
      } else {
        const newIncidentData = $.extend(true, {}, getExistingData([]), incidentToLoad);
        const data = Form.toFormData({
          incidentData: newIncidentData,
        });

        await ApiCalls.createNewIncident(data);
      }

      closeModal();
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  }
  return {
    invokeIncidentInventoryModal,
    invokeIncidentInventoryModalFromAsset,
    initializeIncidentInventoryModalPages,
    getInventoryForRender,
    setFormDataField,
    renderPageContent,
    unsetFormDataField,
    getExistingData,
    setModalTitle,
    onFormInput,
    saveAndCloseModal,
    closeModal,
    getLatestIncidentInventoryData,
    loadExistingIncidentMarker,
    setModalTitleRequest,
  };
};

module.exports = IncidentInventoryModalController();

const ApiCalls = require("../../apiCalls");
const Form = require("../../general/form");
const InventoryModal = require("../../general/inventoryModal");
const Misc = require("../..//misc");
const ModalMap = require("../../mapFunctions/modalMap");
const IncidentInventorySetLocation = require("./incidentInventorySetLocation");
const IncidentInventoryGeneralInfo = require("./incidentInventoryGeneralInfo");
const IncidentInventoryConstants = require("./incidentInventoryConstants");
const IncidentsIcon = require("./incidentsIcon");
const Session = require("../../login/session");
const Tree = require("../../tree");
const UploadValidator = require("../../general/uploadValidator");
const ToolSettings = require("../../settings/toolSettings");
