"use strict";

const ConstructionProjectPopupGeoServerLayer = function () {
  var loadDomListeners = function () {
    $("#map").off("click", ".project-popup-history-button");
    $("#map").on("click", ".project-popup-history-button", inspectionHistoryButtonClick);

    $("#map").off("click", ".project-popup-back-button");
    $("#map").on("click", ".project-popup-back-button", clickOnBackButton);

    $("#map").off("click", ".project-popup-photos");
    $("#map").on("click", ".project-popup-photos", clickOnPhotosButton);

    $("#map").off("click", ".project-popup-fact-sheet");
    $("#map").on("click", ".project-popup-fact-sheet", openFactSheet);

    $("#map").off("click", ".project-popup-delete-button");
    $("#map").on("click", ".project-popup-delete-button", deleteConstructionProjectPopup);

    $("#map").off("click", ".view-project-inventory");
    $("#map").on("click", ".view-project-inventory", viewConstructionProjectInventory);

    $("#map").off("click", "#construction-complete-project-btn");
    $("#map").on("click", "#construction-complete-project-btn", openCompleteModal);

    $("#map").off("click", ".download-inspection");
    $("#map").on(
      "click",
      ".download-inspection",
      ConstructionProjectPopup.downloadInspectionReport,
    );

    $("#map").off("click", ".show-enforcement-action-modal");
    $("#map").on(
      "click",
      ".show-enforcement-action-modal",
      ConstructionProjectPopup.onEnforcementActionModalForInspection,
    );

    $("#map").off("click", ".show-enforcement-action");
    $("#map").on(
      "click",
      ".show-enforcement-action",
      ConstructionProjectPopup.showEnforcementActionModal,
    );

    $("#map").off("click", ".post-active-button", ConstructionProjectPopup.showPostActiveModal);
    $("#map").on("click", ".post-active-button", ConstructionProjectPopup.showPostActiveModal);

    $("#map").off("click", ".js-delete-construction-project-inspection", deleteProjectInspection);
    $("#map").on("click", ".js-delete-construction-project-inspection", deleteProjectInspection);
  };

  var viewConstructionProjectInventory = function () {
    const id = $(this).parents(".popup-construction").data("id");
    ProjectInventoryModalController.invokeProjectInventoryModal(id);
  };

  var openCompleteModal = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");

    ProjectCompleteModal.renderAndShow(id);
  };

  var getPopupHtml = function (properties) {
    return PopupContent.getLayerPopup(properties, "constructionProjectG2");
  };

  var onPopupOpen = async function (properties) {
    const id = properties.id;
    var data = await getPopupData(id, properties);
    const html = getPopupHtml(data);
    $(".leaflet-popup-content").html(html);
    ConstructionProjectPopup._renderSiteVisitHistoryBarGraph(data?.siteVisitHistory);
    Tree.set(["layers", "constructionProject", "selectedId"], id);
    return PopupContent.getLayerPopup(data, "constructionProjectG2");
  };

  var deleteConstructionProjectPopup = function () {
    const popupCloseButn = $(".leaflet-popup-close-button");
    const data = $(this).closest(".popup-general").data();
    MessageModal.showConfirmWarningModal(
      `Delete Construction Project ${data.name} ? Click "Delete" to permanently delete the Construction Project. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteConstructionProject(data.id);
        _closePopupAndReloadLayer(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "construction");
  };

  var getPopupData = async function (id) {
    const data = await ApiCalls.getProjectPopup(id);
    const constructionProps = await ConstructionProjectPopup.getConstructionPopupProps(data);
    constructionProps.groupId = data.groupId;
    return constructionProps;
  };

  var inspectionHistoryButtonClick = function () {
    var projectId = $(this).parents(".popup-general").data("id");
    _showInspectionHistory(projectId);

    Analytics.sendPopupEvent("view_historyTab", "construction");
  };

  var _showInspectionHistory = function (projectId) {
    const isCdot = Session.isCdot();
    ApiCalls.getConstructionProjectHistory(projectId, function (inspections) {
      $(".popup-construction-history").html(
        nunjucks.render("popups/constructionInspectionHistory.njk", {
          inspections,
          isCdot,
        }),
      );
      $(".popup-main-pane").hide();
      $(".popup-history-button").hide();
      $(".popup-construction-history").show();
      $(".project-popup-back-button").show();
      $(".project-popup-delete-button").show();
      $(".project-popup-fact-sheet").hide();
    });
  };

  var clickOnPhotosButton = function () {
    const data = $(this).closest(".popup-general").data();

    PopupPhotos.load("constructionProject", data.id);
    $(".popup-main-pane").hide();
    $(".project-popup-back-button").show();
    $(".popup-photos-pane").show();
    $(".project-popup-fact-sheet").hide();

    Analytics.sendPopupEvent("view_photos", "construction");
  };

  var clickOnBackButton = function () {
    $(".popup-main-pane").show();
    $(".popup-history-pane").hide();
    $(".popup-photos-pane").hide();
    $(".project-popup-back-button").hide();
    $(".project-popup-delete-button").hide();
    $(".project-popup-fact-sheet").show();
  };

  var openFactSheet = function () {
    const dataView = Tree.get("dataView");
    FactSheet._toggleDataListIcon();
    if (dataView === "construction-project") {
      Tree.set("navToggleSelection", "list");
      Analytics.sendPopupEvent("view_details", "construction");
    }
    const projectId = $(this).parents(".popup-construction").data("id");
    Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    Filters.setDataSort("projectIdentifier");
    ProjectFactSheetController.initFactSheet(projectId);
    FactSheet.handleFilterFlow();
  };

  var _closePopupAndReloadLayer = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
    ConstructionProjectGeoServerLayer.invalidateLayerData();
    ConstructionProjectGeoServerLayer.loadLayer();
  };

  var deleteProjectInspection = function () {
    const inspectionId = ConstructionProjectPopup.getRowId($(this));
    MessageModal.showDeleteRecordModal(async function () {
      await ApiCalls.deleteConstructionProjectInspection(inspectionId);
      ConstructionProjectGeoServerLayer.invalidateLayerData();
    });
  };

  return {
    loadDomListeners,
    getPopupHtml,
    onPopupOpen,
    getPopupData,
    _showInspectionHistory,
  };
};

module.exports = ConstructionProjectPopupGeoServerLayer();

const ApiCalls = require("../apiCalls");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const Analytics = require("../general/analytics");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const ConstructionProjectPopup = require("../construction/constructionProjectPopup");
const Session = require("../login/session");
const Tree = require("../tree");
const Filters = require("../mapFunctions/filters");
const ProjectFactSheetController = require("../construction/projectFactSheetController");
const FactSheet = require("../general/factSheet");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const ProjectCompleteModal = require("../construction/projectCompleteModal");
