"use strict";

const ActionDropdown = function () {
  const loadListeners = function () {
    const clickListeners = {
      ".construction-action-dropdown .edit-button": _constructionActionDropdownEditClick,
      ".construction-action-dropdown .map-button": _constructionActionDropdownGoToMapClick,
      ".construction-action-dropdown .phase-button": _constructionActionDropdownPhaseClick,
      ".construction-action-dropdown .certify-button": _constructionActionDropdownCertifyClick,
      ".construction-action-dropdown .download-button": _constructionActionDownloadClick,
      ".construction-action-dropdown .completed-button": openProjectCompleteModal,
      ".construction-action-dropdown .post-active-button": openPostActiveModal,
      ".construction-action-dropdown .pause-button": openPauseProjectModal,
      ".construction-action-dropdown .js-inspection-letter": openInspectionLetter,
      ".activity-action-dropdown .edit-button": _activityActionDropdownEditClick,
      ".activity-action-dropdown .delete-button": _activityActionDropdownDeleteClick,
      ".activity-action-dropdown .copy-button": _activityActionDropdownCopyClick,
      ".facility-action-dropdown .map-button": _facilityActionDropdownGoToMapClick,
      ".facility-action-dropdown .issue-facility-enforcement":
        _facilityActionDropdownIssueEnforcementClick,
      ".facility-action-dropdown .add-facility-inspection":
        _facilityActionDropdownAddInspectionClick,
      ".facility-action-dropdown .edit-button": _facilityActionDropdownEditClick,
      ".structural-bmps-action-dropdown .edit-button": _structuralBmpsActionDropdownGoToEditClick,
      ".catch-basin-action-dropdown .map-button": _muniCatchBasinActionDropdownGoToMapClick,
      ".catch-basin-action-dropdown .edit-button": _muniCatchBasinActionDropdownGoToEditClick,
      ".outfall-action-dropdown .map-button": _muniOutfallActionDropdownGoToMapClick,
      ".outfall-action-dropdown .edit-button": _muniOutfallActionDropdownGoToEditClick,
      ".trash-fcs-action-dropdown .edit-button": _bmpFcsActionDropdownGoToEditClick,
      ".scenario-action-dropdown .edit-button": _structuralBmpsActionDropdownGoToEditClick,
      ".scenario-action-dropdown .phase-button": _scenarioActionDropdownPhaseClick,
      ".scenario-action-dropdown .certify-button": _scenarioActionDropdownCertifyClick,
      ".scenario-action-dropdown .completed-button": _scenarioActionDropdownCompletedClick,
      ".scenario-action-dropdown .post-active-button": _scenarioActionDropdownPostActiveClick,
      ".construction-action-dropdown .issue-lid-enforcement":
        _constructionActionDropdownIssueLidEnforcementClick,
      ".construction-action-dropdown .issue-project-enforcement":
        _constructionActionDropdownIssueProjectEnforcementClick,
    };
    for (const selector in clickListeners) {
      $("#map-pages").on("click", selector, clickListeners[selector]);
    }
  };

  const getProjectId = function ($eventTarget) {
    return $eventTarget.closest(".construction-action-dropdown").data("id");
  };

  const getScenarioBmpProjectId = function ($eventTarget) {
    return $eventTarget.closest(".scenario-action-dropdown").data("relatedId");
  };

  const _constructionActionDropdownEditClick = function () {
    const projectId = getProjectId($(this));
    ProjectInventoryModalController.invokeProjectInventoryModal(projectId);
  };

  const _constructionActionDropdownGoToMapClick = function () {
    const projectId = getProjectId($(this));

    MapFunctions.openPopupById(projectId);
  };

  var _constructionActionDropdownPhaseClick = function () {
    const projectId = getProjectId($(this));

    const project = Tree.get("layers", "constructionProject", "data").find(
      (project) => project.id == projectId,
    );

    ProjectDeliveryPhaseModal.renderAndShow(project);
  };

  var _scenarioActionDropdownPhaseClick = function () {
    const projectId = getScenarioBmpProjectId($(this));

    const bmp = Tree.get("layers", "scenarios", "data").find((bmp) => bmp.project?.id == projectId);
    const project = bmp.project;

    ProjectDeliveryPhaseModal.renderAndShow(project);
  };

  var _constructionActionDownloadClick = async function () {
    const projectId = getProjectId($(this));
    await ApiCalls.downloadProjectPhotos(projectId);
  };

  var openProjectCompleteModal = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");
    ProjectCompleteModal.renderAndShow(id);
  };

  var _scenarioActionDropdownCompletedClick = function (e) {
    const projectId = getScenarioBmpProjectId($(this));
    ProjectCompleteModal.renderAndShow(projectId);
  };

  var _constructionActionDropdownCertifyClick = async function () {
    const projectId = getProjectId($(this));

    ProjectDeliveryCertifyModal.renderAndShow(projectId);
  };

  var _scenarioActionDropdownCertifyClick = async function () {
    const projectId = getScenarioBmpProjectId($(this));
    ProjectDeliveryCertifyModal.renderAndShow(projectId);
  };

  var openPostActiveModal = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");
    PostActiveModal.renderAndShow(id);
  };

  var openPauseProjectModal = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");
    PauseProjectModal.renderAndShow(id);
  };

  var openInspectionLetter = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");
    InspectionLetterModal.renderAndShow([id]);
  };

  var _scenarioActionDropdownPostActiveClick = function (e) {
    const projectId = getScenarioBmpProjectId($(this));
    PostActiveModal.renderAndShow(projectId);
  };

  var _constructionActionDropdownIssueLidEnforcementClick = function () {
    const id = $(this).closest(".construction-action-dropdown").data("id");

    LidEnforcementController.loadNewEnforcement(id);
  };

  var _constructionActionDropdownIssueProjectEnforcementClick = function () {
    const id = $(this).closest(".construction-action-dropdown").data("id");

    ProjectEnforcementController.loadNewEnforcement(id);
  };

  var _activityActionDropdownEditClick = function (e) {
    const activityId = $(this).closest(".activity-action-dropdown").data("id");
    ActivityInventoryModalController.invokeActivityInventoryModal(activityId);
  };

  var _activityActionDropdownCopyClick = function (e) {
    const activityId = $(this).closest(".activity-action-dropdown").data("id");
    ActivityInventoryModalController.copyActivityInventoryModal(activityId);
  };

  var _activityActionDropdownDeleteClick = function (e) {
    const activityId = $(this).closest(".activity-action-dropdown").data("id");
    const activityName = $(e.currentTarget)
      .closest(".actions-col")
      .siblings()
      .find(".activity-name")
      .text();

    ActivitiesListController.invokeDeleteActivityModal(activityId, activityName);
  };

  const _facilityActionDropdownIssueEnforcementClick = function () {
    const facilityId = $(this).closest(".facility-action-dropdown").data("id");

    FacilityEnforcementController.loadNewEnforcement(facilityId);
  };

  const _facilityActionDropdownAddInspectionClick = function () {
    const facilityId = $(this).closest(".facility-action-dropdown").data("id");

    FacilityInspectionController.loadNewFacilityInspection(facilityId);
  };

  const _facilityActionDropdownGoToMapClick = function () {
    const facilityId = $(this).closest(".facility-action-dropdown").data("id");

    MapFunctions.openPopupById(facilityId);
  };

  const _facilityActionDropdownEditClick = function () {
    const mode = ToolFunctions.getActiveTool();
    const options = { mode };
    const facilityId = $(this).closest(".facility-action-dropdown").data("id");

    IndcomInventoryModalController.invokeIndcomInventoryModal(facilityId, options);
  };

  const _structuralBmpsActionDropdownGoToEditClick = function () {
    const bmpId = $(this).closest(".action-dropdown").data("id");

    BmpFcsInventoryModalController.invokeBmpInventoryModal(bmpId);
  };

  const _muniCatchBasinActionDropdownGoToMapClick = function () {
    Tree.set("navToggleSelection", "data");
    const catchBasinId = $(this).closest(".catch-basin-action-dropdown").data("id");

    MapFunctions.openPopupById(catchBasinId);
  };

  const _muniCatchBasinActionDropdownGoToEditClick = function () {
    const catchBasinId = $(this).closest(".catch-basin-action-dropdown").data("id");

    BmpFcsInventoryModalController.invokeBmpInventoryModal(catchBasinId);
  };

  const _muniOutfallActionDropdownGoToMapClick = function () {
    Tree.set("navToggleSelection", "data");
    const outfallId = $(this).closest(".outfall-action-dropdown").data("id");

    MapFunctions.openPopupById(outfallId);
  };

  const _muniOutfallActionDropdownGoToEditClick = function () {
    const mode = ToolFunctions.getActiveTool();
    const outfallId = $(this).closest(".outfall-action-dropdown").data("id");

    OutfallInventoryModalController.invokeOutfallInventoryModal(outfallId, { mode });
  };

  const _bmpFcsActionDropdownGoToEditClick = function () {
    const bmpFcsId = $(this).closest(".trash-fcs-action-dropdown").data("id");

    BmpFcsInventoryModalController.invokeBmpInventoryModal(bmpFcsId);
  };

  return {
    loadListeners,
    _constructionActionDropdownEditClick,
    _constructionActionDropdownGoToMapClick,
    _constructionActionDropdownPhaseClick,
    _constructionActionDropdownCertifyClick,
    _muniCatchBasinActionDropdownGoToEditClick,
    _muniCatchBasinActionDropdownGoToMapClick,
    _muniOutfallActionDropdownGoToMapClick,
    _muniOutfallActionDropdownGoToEditClick,
    _scenarioActionDropdownPhaseClick,
    _scenarioActionDropdownCertifyClick,
    _scenarioActionDropdownCompletedClick,
    _scenarioActionDropdownPostActiveClick,
  };
};

module.exports = ActionDropdown();

const ActivityInventoryModalController = require("../peo/activityInventoryModalController");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const ProjectDeliveryPhaseModal = require("../construction/projectDeliveryPhaseModal");
const ProjectDeliveryCertifyModal = require("../construction/projectDeliveryCertifyModal");
const ProjectCompleteModal = require("../construction/projectCompleteModal");
const PostActiveModal = require("../construction/postActiveModal");
const PauseProjectModal = require("../construction/pauseProjectModal");
const ActivitiesListController = require("../peo/activitiesListController");
const FacilityEnforcementController = require("../indcom/facilityEnforcement/facilityEnforcementController");
const FacilityInspectionController = require("../indcom/facilityInspection/facilityInspectionController");
const IndcomInventoryModalController = require("../indcom/indcomInventoryModalController");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController.js");
const ToolFunctions = require("../toolFunctions.js");
const ApiCalls = require("../apiCalls");
const OutfallInventoryModalController = require("../outfalls/outfallInventoryModalController");
const InspectionLetterModal = require("../lid/inspectionSummaryLetterModal.js");
const LidEnforcementController = require("../lid/lidEnforcement/lidEnforcementController");
const ProjectEnforcementController = require("../construction/newEnforcement/projectEnforcementController");
