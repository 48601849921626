"use strict";

const UnitConversion = function () {
  const FOOT_INCHES = 12.0;
  const METER_FEET = 3.28084;
  const CENTI_FEET = 0.0328084;
  const ACRE_SQUARE_FEET = 43560.0;
  const ACRE_FEET_CUBIC_FEET = 43559.935;
  const ACRE_FOOT_GALLONS = 325851;
  const GALLON_CUBIC_FEET = 0.133681;

  const cubicFeetToAcreFeet = (cubicFeet) => {
    const acreFeet = parseFloat(cubicFeet) / ACRE_FEET_CUBIC_FEET;
    return acreFeet;
  };

  const cubicFeetToGallons = (cubicFeet) => {
    const gallons = parseFloat(cubicFeet) / GALLON_CUBIC_FEET;
    return gallons;
  };

  const gallonsToAcreFeet = (gallons) => {
    const acreFeet = parseFloat(gallons) / ACRE_FOOT_GALLONS;
    return acreFeet;
  };

  const gallonsToCubicFeet = (gallons) => {
    const cubicFeet = parseFloat(gallons) * GALLON_CUBIC_FEET;
    return cubicFeet;
  };

  const acreFeetToGallons = (acreFeet) => {
    const gallons = parseFloat(acreFeet) * ACRE_FOOT_GALLONS;
    return gallons;
  };

  const acreFeetToCubicFeet = (acreFeet) => {
    const cubicFeet = parseFloat(acreFeet) * ACRE_FEET_CUBIC_FEET;
    return cubicFeet;
  };

  const squareFeetToAcres = (squareFeet) => {
    const acres = parseFloat(squareFeet) / ACRE_SQUARE_FEET;
    return acres;
  };

  const acresToSquareFeet = (acres) => {
    const squareFeet = parseFloat(acres) * ACRE_SQUARE_FEET;
    return squareFeet;
  };

  const inchesToFeet = (inches) => {
    const feet = parseFloat(inches) / FOOT_INCHES;
    return feet;
  };

  const feetToInches = (feet) => {
    const inches = parseFloat(feet) * FOOT_INCHES;
    return inches;
  };

  const feetToMeters = (feet) => {
    const meters = parseFloat(feet) / METER_FEET;
    return meters;
  };

  const metersToFeet = (meters) => {
    const feet = parseFloat(meters) * METER_FEET;
    return feet;
  };

  const centimetersToFeet = (cent) => {
    const feet = parseFloat(cent) * CENTI_FEET;
    return feet;
  };

  const feetToCentimeters = (feet) => {
    const centimeters = parseFloat(feet) / CENTI_FEET;
    return centimeters;
  };

  const centimetersToMeters = (cent) => {
    const meters = parseFloat(cent) / 100;
    return meters;
  };

  const metersToCentimeters = (meters) => {
    const centimeters = parseFloat(meters) * 100;
    return centimeters;
  };

  const inchesToMeters = (inches) => {
    const meters = feetToMeters(inchesToFeet(inches));
    return meters;
  };

  const metersToInches = (meters) => {
    const inches = feetToInches(metersToFeet(meters));
    return inches;
  };

  const inchesToCentimeters = (inches) => {
    const meters = feetToMeters(inchesToFeet(inches)) * 100;
    return meters;
  };

  const centimetersToInches = (cent) => {
    const inches = metersToFeet(centimetersToMeters(cent)) * 12;
    return inches;
  };

  const convertVal = (currentValue, currentUnit, conversionUnit) => {
    if (currentUnit === conversionUnit) return currentValue;
    const conversionFunction =
      currentUnit + "To" + conversionUnit.charAt(0).toUpperCase() + conversionUnit.slice(1);
    switch (conversionFunction) {
      case "cubicFeetToAcreFeet":
        return cubicFeetToAcreFeet(currentValue);
      case "acreFeetToCubicFeet":
        return acreFeetToCubicFeet(currentValue);
      case "squareFeetToAcres":
        return squareFeetToAcres(currentValue);
      case "acresToSquareFeet":
        return acresToSquareFeet(currentValue);
      case "inchesToFeet":
        return inchesToFeet(currentValue);
      case "feetToInches":
        return feetToInches(currentValue);
      case "feetToMeters":
        return feetToMeters(currentValue);
      case "metersToFeet":
        return metersToFeet(currentValue);
      case "centimetersToFeet":
        return centimetersToFeet(currentValue);
      case "feetToCentimeters":
        return feetToCentimeters(currentValue);
      case "centimetersToMeters":
        return centimetersToMeters(currentValue);
      case "metersToCentimeters":
        return metersToCentimeters(currentValue);
      case "inchesToMeters":
        return inchesToMeters(currentValue);
      case "metersToInches":
        return metersToInches(currentValue);
      case "inchesToCentimeters":
        return inchesToCentimeters(currentValue);
      case "centimetersToInches":
        return centimetersToInches(currentValue);
      case "cubicFeetToGallons":
        return cubicFeetToGallons(currentValue);
      case "gallonsToAcreFeet":
        return gallonsToAcreFeet(currentValue);
      case "gallonsToCubicFeet":
        return gallonsToCubicFeet(currentValue);
      case "acreFeetToGallons":
        return acreFeetToGallons(currentValue);
      default:
        console.error("No unit conversion method found for: " + conversionFunction);
        return currentValue;
    }
  };

  var getUnitAbbreviation = function (unit) {
    switch (unit) {
      case "feet":
        return "ft";
      case "meters":
        return "m";
      case "centimeters":
        return "cm";
      case "inches":
        return "in";
      default:
        if (unit) {
          console.error(`Unit is not specified for ${unit}.`);
        }
        return unit;
    }
  };

  return {
    cubicFeetToAcreFeet,
    acreFeetToCubicFeet,
    squareFeetToAcres,
    acresToSquareFeet,
    inchesToFeet,
    feetToInches,
    feetToMeters,
    metersToFeet,
    centimetersToFeet,
    feetToCentimeters,
    convertVal,
    getUnitAbbreviation,
    cubicFeetToGallons,
    gallonsToAcreFeet,
    gallonsToCubicFeet,
    acreFeetToGallons,
  };
};

module.exports = UnitConversion();
