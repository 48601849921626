"use strict";

const MuniLiftStationsGeoServerLayer = function () {
  const layerName = "muniLiftStations";
  const layerKey = "muniLiftStationsLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: createIconFunction(feature),
    });
  };

  var createIconFunction = function (feature) {
    return L.divIcon({
      iconSize: _updateIconSizeOnZoom(),
      popupAnchor: [0, 5],
      className: feature.properties.icon.class,
    });
  };

  var _updateIconSizeOnZoom = function () {
    const currentZoomLevel = Tree.get("zoomLevel");
    const iconSizesArray = [18, 36, 36, 65, 65, 65, 65, 65];
    const updatedIconStyleProps = MapFunctions.resizeIconsOnZoom(
      currentZoomLevel,
      false,
      false,
      false,
      false,
      iconSizesArray,
    );
    const currentDataView = Tree.get("dataView");
    const size = currentDataView === "muni-lift-stations" ? updatedIconStyleProps.size : [24, 24];
    return size;
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniLiftStationsGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(
      layerName,
      mapId,
      muniLiftStationsGeoServerLayer,
    );

    muniLiftStationsGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniLiftStationsGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeMuniLayerData(data);
  };

  var organizeMuniLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      id: data.id,
      groupId: data.group_id,
      geometry: geometry,
      name: data.name,
      type: data.type,
      address: data.address,
      cof: data.cof_final_text,
      sump: data.sump,
      phase: data.phase,
      landOwnership: data.property,
      implementerType: data.implementer_type,
      maintainedBy: data.responsible_party_maintenance,
      icon: RoutineMaintenanceIcon.getMaintenanceIcon(
        "muni-lift-stations",
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      lastInspectionId: data.last_maintenance_inspection_id,
      lastInspectionDue: data.last_maintenance_inspection_due,
      condition: RoutineMaintenanceIcon.getLayerCondition(
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      installDate: data.install_date,
      correctiveAction: JSON.parse(data.corrective_actions),
      enforcementLevel: JSON.parse(data.severity),
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,
      routine: data.routine_inspection_dates ? JSON.parse(data.routine_inspection_dates) : null,
      repair: data.repair_dates ? JSON.parse(data.repair_dates) : null,
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
    _updateIconSizeOnZoom,
  };
};

module.exports = MuniLiftStationsGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
