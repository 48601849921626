"use strict";

const NunjucksFilters = function () {
  const displayFallback = "—";

  var setupFilters = function (env) {
    const filters = getFilters();
    for (const filterName in filters) {
      env.addFilter(filterName, filters[filterName]);
    }
  };

  var getFilters = function () {
    return {
      getStateAbbreviation,
      addCommaToNumber,
      updateAreaOfDisturbanceString,
      append,
      arrayCount,
      assembleNamePath,
      booleanToYesNo,
      booleanYesNoUndefined,
      capFirstLetterOfObject,
      capFirstLetter,
      capFirstLetters,
      squareFeetTosqft,
      commafy,
      commafyWithPlaces,
      commaSeparated,
      convertUnitWithOptions,
      daysToNow,
      decimalPlaces,
      smallNumberPlaces,
      displayPhase,
      filetype,
      formatDate,
      formatDueDate,
      formatInputsMapPercent,
      formatIsoDateString,
      formatLatLng,
      formatMillion,
      formatNumber,
      formatPhone,
      formatPrice,
      formatReduction,
      formatTelrNumber,
      formatToDoCount,
      hundredths,
      isFalse,
      isNonemptyString,
      isNull,
      isUndefined,
      kebabToCapFirstLetters,
      millionsAndBillions,
      millions,
      money,
      nan,
      noSpaces,
      numberInput,
      orFallback,
      orDefined,
      parameterCapitalization,
      plural,
      pluralIfMultiple,
      prepend,
      replaceNonWord,
      sumColumn,
      tenths,
      tenthsBig,
      tenthsCommafy,
      tenthsPercent,
      truncate,
      truncateAt,
      unitfy,
      unitsCapitalization,
      uuid,
      wholeNumber,
      formatConstructionProjectScore,
      wholeNumberPercent,
      percentToFactor,
      wholeNumberPercentZero,
      removeDashesCapitalize,
      formatScore,
      formatStandardNumbers,
      convertToAcresWhenLarge,
      convertToAcFtWhenLarge,
      abbreviateAddress,
      abbreviateCof,
      convertFtToInches,
      capFirstLetterKeepAcronyms,
      updateWithAcronyms,
      lightDarkText,
      formatLargeNumber,
      formatUnits,
      toKebabCase,
      initials,
      formatEmptyValue,
      listWords,
      displayStringsArray,
      numberOfThings,
    };
  };

  function getStateAbbreviation(stateName) {
    const stateAbbreviations = {
      alabama: "AL",
      alaska: "AK",
      arizona: "AZ",
      arkansas: "AR",
      california: "CA",
      colorado: "CO",
      connecticut: "CT",
      delaware: "DE",
      florida: "FL",
      georgia: "GA",
      hawaii: "HI",
      idaho: "ID",
      illinois: "IL",
      indiana: "IN",
      iowa: "IA",
      kansas: "KS",
      kentucky: "KY",
      louisiana: "LA",
      maine: "ME",
      maryland: "MD",
      massachusetts: "MA",
      michigan: "MI",
      minnesota: "MN",
      mississippi: "MS",
      missouri: "MO",
      montana: "MT",
      nebraska: "NE",
      nevada: "NV",
      "new-hampshire": "NH",
      "new-jersey": "NJ",
      "new-mexico": "NM",
      "new-york": "NY",
      "north-carolina": "NC",
      "north-dakota": "ND",
      ohio: "OH",
      oklahoma: "OK",
      oregon: "OR",
      pennsylvania: "PA",
      "rhode-island": "RI",
      "south-carolina": "SC",
      "south-dakota": "SD",
      tennessee: "TN",
      texas: "TX",
      utah: "UT",
      vermont: "VT",
      virginia: "VA",
      washington: "WA",
      "west-virginia": "WV",
      wisconsin: "WI",
      wyoming: "WY",
    };

    return stateAbbreviations[stateName?.toLowerCase() ?? null] || null;
  }

  // Same as API FormatUtilities::formatNumber()
  var formatNumber = function (
    numberString,
    intlOptions, // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options,
    {
      numberCallback = (number) => number,
      resultCallback = (string) => string,
      fallback = displayFallback,
    } = {},
  ) {
    let number = parseFloat(numberString);

    if (isNaN(number)) {
      return fallback;
    }

    number = numberCallback(number);
    const result = number.toLocaleString("en-US", intlOptions);
    return resultCallback(result);
  };

  // Same as API FormatUtilities::formatFixedNumber()
  var formatFixedNumber = function (numberString, decimalPlaces, formatOptions = {}) {
    return formatNumber(
      numberString,
      {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      },
      formatOptions,
    );
  };

  var convertFtToInches = function (str) {
    if (str == 0) {
      return 0;
    }

    return str ? str * 12 : null;
  };

  var squareFeetTosqft = function (str) {
    if (str) {
      return str.replace("squareFeet", "sq-ft");
    } else return "";
  };

  var abbreviateCof = function (str) {
    if (str) {
      if (str === "very-high") {
        return "VH";
      } else if (str === "high") {
        return "H";
      } else if (str === "moderate") {
        return "M";
      } else if (str === "low") {
        return "L";
      } else if (str === "very-low") {
        return "VL";
      } else {
        return "UNK";
      }
    } else return "UNK";
  };

  var updateAreaOfDisturbanceString = function (stringValue) {
    var matches = stringValue?.match(/(\d+)/);
    if (isInvalidData(stringValue) || matches === null) {
      return null;
    }
    var commifiedNumber = commafy(+matches[0]);
    return stringValue.replace(matches[0], commifiedNumber).replace("sqft", "sq-ft");
  };

  // Same as API FormatUtilities::formatStandardNumbers()
  var formatStandardNumbers = function (value) {
    const number = roundStandardNumbers(value);

    if (number === null) {
      return "";
    }

    return commafy(number);
  };

  // Same as API FormatUtilities::roundStandardNumbers()
  var roundStandardNumbers = function (number) {
    number = parseFloat(number);
    if (isNaN(number)) {
      return null;
    } else if (number % 1 === 0) {
      return number;
    } else {
      return Math.round((number + Number.EPSILON) * 10) / 10;
    }
  };

  var daysToNow = function (isoDate) {
    const date = DateTime.parseIsoDate(isoDate);

    if (isNaN(date)) {
      return displayFallback;
    }

    const days = DateTime.getDiffrenceInDays(date, new Date());
    return Math.round(days);
  };

  // Same as API FormatUtilities::money()
  var money = function (num, noCents = false) {
    num = parseFloat(num);

    if (isNaN(num)) {
      num = 0;
    }

    var decimals = num % 1 === 0 || noCents ? 0 : 2;
    return "$" + formatFixedNumber(num, decimals);
  };

  var formatScore = function (string) {
    if (!isNaN(string)) {
      const num = parseFloat(string);
      if (num >= 0 && num <= 2.04) {
        return "Poor";
      } else if (num > 2.04 && num <= 2.94) {
        return "Fair";
      } else if (num > 2.94 && num <= 3.94) {
        return "Good";
      } else if (num > 3.94) {
        return "Optimal";
      } else {
        return displayFallback;
      }
    } else {
      return string;
    }
  };

  var formatConstructionProjectScore = function (score) {
    if (!isNaN(score)) {
      const num = parseFloat(score);
      if (num >= 0 && num < 0.7) {
        return "Unsatisfactory";
      } else if (num >= 0.7) {
        return "Satisfactory";
      } else {
        return displayFallback;
      }
    } else {
      return displayFallback;
    }
  };

  var formatTelrNumber = function (num, smallSigFix = 2, noNegative = false) {
    num = parseFloat(num);

    if (num === 0 || (noNegative && num < 0)) {
      return "0";
    } else if (num < 0.1) {
      return formatFixedNumber(num, smallSigFix);
    } else {
      return formatFixedNumber(num, 1);
    }
  };

  var formatReduction = function (numStr, unit) {
    // Must pass in a formated number string
    if (isInvalidData(numStr)) {
      return displayFallback;
    }
    if (numStr === "0") {
      if (unit) {
        return unit === "%" ? `${numStr}%` : `${numStr} ${unit}`;
      }
      return numStr;
    } else {
      if (unit) {
        return unit === "%" ? `(${numStr}%)` : `(${numStr} ${unit})`;
      }
      return `(${numStr})`;
    }
  };

  var formatInputsMapPercent = function (num, sigFig = 0) {
    num = parseFloat(num);

    if (num === 0) {
      return "0";
    } else if (num < 1) {
      return formatFixedNumber(num, 1);
    } else {
      return formatFixedNumber(num, sigFig);
    }
  };

  var decimalPlaces = function (num, places, unlessZero) {
    num = parseFloat(num);

    if (unlessZero && parseFloat(num.toFixed(places)) === 0) {
      return "0";
    }

    return formatFixedNumber(num, places);
  };

  function smallNumberPlaces(num) {
    num = parseFloat(num);

    const fixedNum = parseFloat(num.toFixed(3));
    const decimals = fixedNum === 0.0 || fixedNum >= 0.005 ? 2 : 3;
    return formatFixedNumber(num, decimals);
  }

  var wholeNumber = function (num) {
    if (num !== null && !isNaN(num)) return Math.round(num);

    return displayFallback;
  };

  var wholeNumberPercent = function (num) {
    return formatFixedNumber(num, 0, { resultCallback: (string) => string + "%" });
  };

  var percentToFactor = function (num, places = 1) {
    if (num !== null && !isNaN(num)) return formatFixedNumber(num / 100, places);

    return displayFallback;
  };

  var tenthsPercent = function (num) {
    return formatFixedNumber(num, 1, { resultCallback: (string) => string + "%" });
  };

  var wholeNumberPercentZero = function (num) {
    return formatFixedNumber(num, 0, { resultCallback: (string) => string + "%", fallback: "0%" });
  };

  var addCommaToNumber = function (num) {
    if (num !== null && !isNaN(num)) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  var formatDueDate = function (dateWithTimestamp, inspectFreq) {
    return DateTime.formatDueDate(dateWithTimestamp, inspectFreq);
  };

  var formatDate = function (dateWithTimestamp) {
    if (isInvalidData(dateWithTimestamp)) return displayFallback;
    if (
      dateWithTimestamp.toLowerCase() === displayFallback ||
      dateWithTimestamp.toLowerCase() === "in progress"
    ) {
      return dateWithTimestamp;
    }
    return DateTime.formatTimestampStringAsDisplayDate(dateWithTimestamp);
  };

  var formatIsoDateString = function (string) {
    return DateTime.formatIsoString(string);
  };

  var millions = function (string, decimalPlaces = 2) {
    const num = parseFloat(string);

    if (isNaN(num)) {
      return displayFallback;
    }

    return (num / 1000000).toFixed(decimalPlaces);
  };

  var millionsAndBillions = function (string, decimalPlaces = 2) {
    const num = parseFloat(string);

    if (isNaN(num) || Math.abs(num) < 100000) {
      return formatFixedNumber(num, 0);
    } else if (Math.abs(num) < 1000000000) {
      return formatFixedNumber(num / 1000000, decimalPlaces) + "M";
    } else {
      return formatFixedNumber(num / 1000000000, decimalPlaces) + "B";
    }
  };

  var formatMillion = function (string, regardlessOfSize = false) {
    if (isInvalidData(string)) {
      return displayFallback;
    }
    string = string.toString();
    let value = string;
    if (string[0] === "$") {
      value = string.substr(1);
    } else {
      string = "$" + string;
    }
    value = parseFloat(value.replace(/,/g, ""));
    if (value === 0) {
      return "$0";
    }

    if (Math.abs(value) >= 100000 || (regardlessOfSize && Math.abs(value) >= 10000)) {
      return ("$" + formatFixedNumber(value / 1000000, 2) + "M").replace("$-", "-$");
    } else {
      return ("$" + formatFixedNumber(value, 0)).replace("$-", "-$");
    }
  };

  var tenths = function (num) {
    if (num === "") return "";
    if (num !== null && !isNaN(num)) return parseFloat(num).toFixed(1);
    return displayFallback;
  };

  var hundredths = function (num) {
    if (num !== null && !isNaN(num)) return parseFloat(num).toFixed(2);
    return displayFallback;
  };

  var tenthsBig = function (num) {
    num = parseFloat(num);

    if (isNaN(num)) {
      return displayFallback;
    } else if (num >= 1000) {
      return Math.round(num);
    } else {
      return parseFloat(num).toFixed(1);
    }
  };

  var commafy = function (num) {
    if (num === null || num === undefined) {
      return displayFallback;
    }
    if (!isNaN(num)) return parseFloat(num).toLocaleString();
    return num;
  };

  var unitfy = function (string, unit, noSpace = false) {
    if (isInvalidData(string)) {
      return displayFallback;
    }
    if (!unit) {
      return string;
    }
    return string + (noSpace ? "" : " ") + unit;
  };

  var convertUnitWithOptions = function (unit, unitOptions) {
    if (unit && unitOptions) {
      var optionFound = unitOptions.find((option) => option.value === unit);
      if (optionFound) {
        return optionFound.name;
      }
    }
    return "";
  };

  var formatPrice = function (string) {
    return formatNumber(string, { currency: "USD", style: "currency" });
  };

  var nan = function (num) {
    return isNaN(num);
  };

  var truncate = function (string) {
    if (string && string.length > 20) {
      return string.substring(0, 20) + "...";
    } else {
      return string;
    }
  };

  var truncateAt = function (str, length) {
    if (str && str.length > length) {
      return str.substring(0, length) + "...";
    } else {
      return str;
    }
  };

  var booleanYesNoUndefined = function (bool) {
    if (bool !== undefined && bool !== null) {
      return bool ? "Yes" : "No";
    }
  };

  var booleanToYesNo = function (bool) {
    return bool ? "Yes" : "No";
  };

  var arrayCount = function (array) {
    const count = array ? array.length : 0;
    return NumberInput.formatByType(count, "positiveInteger");
  };

  var sumColumn = function (objArray, key) {
    let anyNotNull;
    objArray.forEach((obj) => {
      if (obj[key] !== null && obj[key] !== undefined) {
        anyNotNull = true;
      }
    });
    if (!anyNotNull) {
      return null;
    }
    return objArray.reduce((sum, val) => {
      if (val[key] && !isNaN(Number(val[key]))) {
        return sum + Number(val[key]);
      }
      return sum;
    }, 0);
  };

  var formatToDoCount = function (todos) {
    const count = todos ? todos.length : 0;
    if (count >= 1000) {
      return "1,000+";
    } else {
      return NumberInput.formatByType(count, "positiveInteger");
    }
  };

  // keep it simple, for now, just try to get extension
  var filetype = function (filename) {
    return filename.substr(filename.lastIndexOf(".") + 1);
  };

  var orFallback = function (input, fallback = displayFallback) {
    if (isInvalidData(input, displayFallback)) {
      return fallback;
    }

    return input;
  };

  var isInvalidData = function (input, fallback = displayFallback) {
    return (
      input === null ||
      input === undefined ||
      input === "" ||
      input === displayFallback ||
      Number.isNaN(input)
    );
  };

  var orDefined = function (input, fallback) {
    if (input === undefined) {
      return fallback;
    }
    return input;
  };

  var noSpaces = function (str) {
    return str.replace(/\s/g, "_", str).toLowerCase();
  };

  var replaceNonWord = function (str) {
    return str.replace(/[^a-zA-Z0-9]/g, "_", str).toLowerCase();
  };

  var initials = function (str) {
    if (!str) return "";
    const words = str.match(/\w*/g).filter(String);

    if (words.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return words[0][0]?.toUpperCase() + words[words.length - 1][0]?.toUpperCase();
    }
  };

  var capFirstLetters = function (str) {
    if (!str) return "";
    if (str.toUpperCase() === "N/A" || str.toUpperCase() === "N-A") {
      return displayFallback;
    }
    return str.replace(
      /\w*/g,
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase(),
    );
  };

  var capFirstLetter = function (str) {
    if (!str) return "";
    if (typeof str === "object") {
      str.toString();
    }
    if (str.toUpperCase() === "N/A" || str.toUpperCase() === "N-A") {
      return displayFallback;
    }
    const firstLetter = str.charAt(0).toUpperCase();
    const restLower = str.slice(1).toLowerCase();
    return `${firstLetter}${restLower}`;
  };

  var capFirstLetterKeepAcronyms = function (str) {
    const currentDataView = Tree.get("dataView");
    const dataViewsWhereFilterShouldApply = ["construction-project"];
    if (!str || typeof str !== "string") {
      return displayFallback;
    }
    if (!dataViewsWhereFilterShouldApply.includes(currentDataView)) {
      return str;
    }
    const acronyms = str?.match(/(\b[A-Z][A-Z]+|\b[A-Z]\b)/g);
    const capFirstLetterLowerRest = capFirstLetter(str);
    return updateWithAcronyms(acronyms, capFirstLetterLowerRest);
  };

  var updateWithAcronyms = function (acronymArr, str) {
    for (let i = 0; i < acronymArr?.length; i++) {
      str = str?.replace(acronymArr[i].toLowerCase(), acronymArr[i].toUpperCase());
    }

    return str;
  };

  var capFirstLetterOfObject = function (obj) {
    if (obj) {
      return capFirstLetter(obj.toString());
    } else return displayFallback;
  };

  var formatPhone = function (num) {
    var str = num?.toString();
    if (str?.match(/[a-z]/gi)) {
      return displayFallback;
    }
    var matched = str?.match(/\d+\.?\d*/g);
    // 10 digit
    if (matched?.length === 3) {
      return "(" + matched[0] + ") " + matched[1] + "-" + matched[2];
      // 7 digit
    } else if (matched?.length === 2) {
      return matched[0] + "-" + matched[1];
    }
    // no formatting attempted only found integers (i.e. 1234567890)
    else if (matched?.length === 1) {
      // 10 digit
      if (matched[0].length === 10) {
        return (
          "(" +
          matched[0].substr(0, 3) +
          ") " +
          matched[0].substr(3, 3) +
          "-" +
          matched[0].substr(6)
        );
      }
      // 7 digit
      if (matched[0].length === 7) {
        return matched[0].substr(0, 3) + "-" + matched[0].substr(3);
      }
    }
    // Format failed, return number back
    return displayFallback;
  };

  var formatLatLng = function (latLng) {
    if (
      typeof latLng !== "object" ||
      isNaN(parseFloat(latLng.lat)) ||
      isNaN(parseFloat(latLng.lng))
    ) {
      throw new Error(`${latLng} is malformed.`);
    }

    return `${NumberInput.formatByType(latLng.lat, "latitude")}, ${NumberInput.formatByType(
      latLng.lng,
      "longitude",
    )}`;
  };

  var abbreviateAddress = function (str) {
    if (typeof str !== "string") {
      return str;
    }

    return str
      .replace(/\bnorth\b/gi, "N")
      .replace(/\bsouth\b/gi, "S")
      .replace(/\beast\b/gi, "E")
      .replace(/\bwest\b/gi, "W");
  };

  var removeDashesCapitalize = function (string) {
    if (string) {
      return string
        .replace(/-/g, " ")
        .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }
  };

  // Same as API RandomUtilities::convertToAcFtWhenLarge()
  var convertToAcFtWhenLarge = function (number) {
    if (number === null) {
      return displayFallback;
    }
    if (number == 0) {
      return "0 cu-ft";
    }
    if (number >= 10000) {
      const acFt = number / 43559.935;
      return acFt.toFixed(2) + " ac-ft";
    } else {
      return formatStandardNumbers(number) + " cu-ft";
    }
  };

  var convertToAcresWhenLarge = function (footprintSqft) {
    footprintSqft = parseFloat(footprintSqft);

    if (isNaN(footprintSqft)) {
      return displayFallback;
    } else if (footprintSqft === 0) {
      return "0 sq-ft";
    } else if (footprintSqft >= 10000) {
      const acres = footprintSqft / 43560;
      return acres.toFixed(2) + " acres";
    } else {
      return formatStandardNumbers(footprintSqft) + " sq-ft";
    }
  };

  var isUndefined = function (val) {
    return val === undefined;
  };

  var isNull = function (val) {
    return val === null;
  };

  var isFalse = function (val) {
    return val === false;
  };

  var isNonemptyString = function (val) {
    if (typeof val !== "string") {
      return false;
    }

    return val.length > 0;
  };

  var commaSeparated = function (arr) {
    arr = arr.filter((item) => item);
    if (Array.isArray(arr)) {
      return arr.join(", ");
    }
    return displayFallback;
  };

  var append = function (str, append) {
    if (str !== null && str !== undefined) {
      return str + append;
    }
  };

  var prepend = function (str, prepend, separator) {
    if (str !== null && str !== undefined) {
      if (prepend !== null && prepend !== undefined) {
        if (separator !== null && separator !== undefined) {
          return prepend + separator + str;
        }
        return prepend + str;
      }
      return str;
    }
  };

  var unitsCapitalization = function (str) {
    if (!str) return "";
    const unitsDictionary = {
      c: "°C",
      f: "°F",
      "ms/cm": "mS/cm",
      "us/cm": "uS/cm",
      ntu: "NTU",
      "mg/l": "mg/L",
    };
    return unitsDictionary[str];
  };

  var parameterCapitalization = function (str) {
    if (!str) return "";
    const parameterDictionary = {
      do: "DO",
      ph: "pH",
      pvc: "PVC",
    };

    if (parameterDictionary.hasOwnProperty(str)) {
      return parameterDictionary[str];
    } else {
      return str.replace(/^\w/, (letter) => letter.toUpperCase());
    }
  };

  var displayPhase = function (phase) {
    return ConstructionProjectStyles.getDisplayPhase(phase);
  };

  var numberInput = function (number, type) {
    return number === displayFallback ? displayFallback : NumberInput.formatByType(number, type);
  };

  var plural = function (word) {
    if (word.endsWith("y") && word !== "day") {
      return word.slice(0, -1) + "ies";
    }
    if (word === "person") {
      return "people";
    }
    if (word === "this") {
      return "these";
    }
    if (word === "is") {
      return "are";
    }
    if (word === "has") {
      return "have";
    }
    if (["s", "ss", "sh", "ch", "x", "z"].some((ending) => word.endsWith(ending))) {
      return word + "es";
    }
    if (word.endsWith("f")) {
      return word.slice(0, -1) + "ves";
    }
    if (word.endsWith("fe")) {
      return word.slice(0, -2) + "ves";
    }
    return word + "s";
  };

  var pluralIfMultiple = function (word, items) {
    if (
      items === undefined ||
      (Array.isArray(items) && items.length === 1) ||
      (Number.isInteger(items) && items === 1)
    ) {
      return word;
    }
    return plural(word);
  };

  var tenthsCommafy = function (num) {
    return commafyWithPlaces(num, 1);
  };

  var commafyWithPlaces = function (num, decimalPlaces = 2) {
    return formatFixedNumber(num, decimalPlaces);
  };

  var assembleNamePath = function (input, path) {
    let output = "";
    const pathArray = Array.from(path);
    pathArray.push(input);
    const firstElement = pathArray.shift();

    pathArray.forEach(function (item) {
      output += `[${item}]`;
    });

    return firstElement + output;
  };

  var uuid = function (input) {
    return input.toString() + UUID();
  };

  var kebabToCapFirstLetters = function (str) {
    if (!str) return "";
    return str
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  var lightDarkText = function (color) {
    return Color(color).isLight() ? "light-text" : "dark-text";
  };

  var formatLargeNumber = function (num, digits, prefix = "") {
    if (num === null || isNaN(num)) {
      return displayFallback;
    }
    return prefix + Misc.formatLargeNumber(num, digits);
  };

  var formatUnits = function (unitString) {
    if (typeof unitString !== "string" || unitString === null) {
      return "";
    }
    const possibleSquareFeetUnitFormats = [
      "squarefeet",
      "square-feet",
      "square-foot",
      "sq-feet",
      "sq-foot",
      "sq-fts",
      "sqft",
      "sq ft",
      "ft2",
      "ft^2",
      "sf",
      "ft²",
    ];
    const possibleFeetUnitFormats = ["feet", "fts", "foot", "ft", "ft.", "foot."];
    const possibleInchUnitFormats = ["inches", "ins", "in", "inch", "in.", "inch.", "''", '"'];
    const possibleAcresUnitFormats = ["acer", "ac", "acr", "acre", "acres"];

    unitString = unitString?.toLowerCase();
    if (possibleFeetUnitFormats.includes(unitString)) {
      return "ft";
    } else if (possibleInchUnitFormats.includes(unitString)) {
      return "in";
    } else if (possibleAcresUnitFormats.includes(unitString)) {
      return "acres";
    } else if (possibleSquareFeetUnitFormats.includes(unitString)) {
      return "sq-ft";
    } else return "";
  };

  var toKebabCase = function (str) {
    if (str === null || str === undefined) {
      return "";
    }

    return String(str)
      .replace(/[_\s]+/g, "-")
      .replace(/[^a-z0-9-]/gi, "")
      .toLowerCase();
  };

  var formatEmptyValue = function (val, unit) {
    let lowerCaseVal = val;
    if (typeof val === "string") {
      lowerCaseVal = val.toLowerCase();
    }

    if (
      isInvalidData(val) ||
      lowerCaseVal === "n/a" ||
      lowerCaseVal === "n-a" ||
      lowerCaseVal === displayFallback
    ) {
      return displayFallback;
    }

    if (unit) {
      return `${val} ${unit}`;
    } else {
      return val;
    }
  };

  // Same as API FormatUtilities::listWords()
  var listWords = function (words) {
    if (!Array.isArray(words)) {
      throw new Error(`Passed ${typeof words} must be an array`);
    }

    if (words.length === 0) {
      return "";
    } else if (words.length === 1) {
      return words[0];
    } else if (words.length === 2) {
      return `${words[0]} and ${words[1]}`;
    }

    let result = words[0];
    const iLast = words.length - 1;
    for (var i = 1; i <= iLast; i++) {
      if (i === iLast) {
        result += ", and ";
      } else {
        result += ", ";
      }

      result += words[i];
    }

    return result;
  };

  /*
    Takes an array of strings: ["One", "Two", "One"]
    And combines duplicates: "2: One (2), Two"
  */
  var displayStringsArray = function (arrayOfStrings) {
    if (arrayOfStrings.length === 0) {
      return displayFallback;
    }

    return `${arrayOfStrings.length}: ${joinDisplayStringsArray(arrayOfStrings)}`;
  };

  var joinDisplayStringsArray = function (arrayOfStrings) {
    const stringCounts = new Map();

    for (const string of arrayOfStrings) {
      if (stringCounts.has(string)) {
        stringCounts.set(string, stringCounts.get(string) + 1);
      } else {
        stringCounts.set(string, 1);
      }
    }

    return Array.from(stringCounts)
      .map(function ([string, count]) {
        return count > 1 ? `${string} (${count})` : string;
      })
      .join(", ");
  };

  var numberOfThings = function (number, nameOfThings) {
    if (isInvalidData(number)) {
      return displayFallback;
    }

    return `${formatNumber(number)} ${pluralIfMultiple(nameOfThings, number)}`;
  };

  return {
    displayFallback,
    setupFilters,
    updateAreaOfDisturbanceString,
    getFilters,
    plural,
    pluralIfMultiple,
    tenthsCommafy,
    commafyWithPlaces,
    convertUnitWithOptions,
    formatLatLng,
    squareFeetTosqft,
    capFirstLetterOfObject,
    capFirstLetters,
    sumColumn,
    removeDashesCapitalize,
    formatConstructionProjectScore,
    formatScore,
    formatPhone,
    formatIsoDateString,
    formatStandardNumbers,
    formatDate,
    formatTelrNumber,
    convertToAcFtWhenLarge,
    convertToAcresWhenLarge,
    abbreviateAddress,
    capFirstLetterKeepAcronyms,
    getStateAbbreviation,
    updateWithAcronyms,
    formatUnits,
    money,
    millions,
    kebabToCapFirstLetters,
    formatEmptyValue,
    listWords,
    wholeNumber,
    wholeNumberPercent,
    wholeNumberPercentZero,
    tenths,
    hundredths,
    commafy,
    formatReduction,
    nan,
    formatDueDate,
    addCommaToNumber,
    formatInputsMapPercent,
    lightDarkText,
    orFallback,
    daysToNow,
    capFirstLetter,
    unitfy,
    filetype,
    truncateAt,
    displayStringsArray,
    numberOfThings,
  };
};

module.exports = NunjucksFilters();

const DateTime = require("../dateTime");
const ConstructionProjectStyles = require("../construction/constructionProjectStyles");
const NumberInput = require("./numberInput");
const UUID = require("uuid");
const Color = require("color");
const Tree = require("../tree");
const Misc = require("../misc");
