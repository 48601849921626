"use strict";

const ToolSettings = function () {
  var settings = {};

  function saveSettings(data) {
    settings = data;
  }

  function getSetting(...keyPath) {
    let setting = settings;

    for (const key of keyPath) {
      if (setting === undefined) {
        return undefined;
      }

      setting = setting[key];
    }

    return setting;
  }

  async function requestSettings() {
    const cachedSettings = UserResource.getUserData("tool-settings");
    const data = await ApiCalls.getToolSettings(cachedSettings?.version);

    if (!data) {
      settings = cachedSettings;
    } else {
      UserResource.setUserData("tool-settings", data);
      settings = data;
    }

    return settings;
  }

  function addNameToOptions(setting, key, options, { optionKey = "value" } = {}) {
    const settingOptions = getSetting(setting, key);

    for (const option of options) {
      option.name = settingOptions[option[optionKey]].displayName;
    }

    return options;
  }

  function getDomainOptions(domainTable) {
    const domainOptions = getSetting("domain", domainTable);

    return domainOptions.map(function (option) {
      return { value: option.code, name: option.display };
    });
  }

  function getDomainDisplayName(domainTable, value) {
    if (value === null || value === undefined || value === "") {
      return null;
    }

    for (const option of getSetting("domain", domainTable)) {
      if (option.code === value) {
        return option.display;
      }
    }

    throw new Error(`No display name found for ${value} in domain ${domainTable}`);
  }

  return {
    saveSettings,
    getSetting,
    requestSettings,
    addNameToOptions,
    getDomainOptions,
    getDomainDisplayName,
  };
};

module.exports = ToolSettings();

const ApiCalls = require("../apiCalls");
const UserResource = require("../offline/userResource");
