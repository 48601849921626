"use strict";

const AssetsPipePopup = function () {
  var getPopupHtml = function (props) {
    const layerName = "assetsPipeEsriDynamicMap";
    const properties = {
      layerName,
      props,
      formattedDate: AssetsPopupFunctions.getFormattedDate(props.install_date),
      assetAge: AssetsPopupFunctions.getAssetAge(props.install_date),
      popupHeaderProps: AssetsPopupFunctions.getPopupHeaderProps(props),
      displayPofText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.pof_final_text,
      ),
      displayCofText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.cof_final_text,
      ),
      displayCriticalityText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.criticality_text,
      ),
      mode: AssetsPopupFunctions.getMode(),
      displayType: ToolSettings.getDomainDisplayName("pipe_type", props.pipe_type),
      displayMaterial: ToolSettings.getDomainDisplayName("material", props.material),
      displayDiameterUnits: UnitConversion.getUnitAbbreviation(props.diameter_units),
      displayLengthUnits: UnitConversion.getUnitAbbreviation(props.length_units),
      displayWidthUnits: UnitConversion.getUnitAbbreviation(props.width_units),
    };
    return PopupContent.getLayerPopup(properties, layerName);
  };

  return {
    getPopupHtml,
  };
};

module.exports = AssetsPipePopup();

const PopupContent = require("../../mapFunctions/popupContent");
const ToolSettings = require("../../settings/toolSettings");
const UnitConversion = require("../../unitConversion");
const AssetsPopupFunctions = require("../assetsPopupFunctions");
