"use strict";

const FacilityInspectionConstants = function () {
  var getNewFacilityInspectionDefaults = function (inspectionData) {
    inspectionData.inspectorName = Session.loadContext().full_name;
    inspectionData.startTime = DateTime.getNowIso();
    inspectionData.signatures = {
      inspectorName: inspectionData.inspectorName,
      inspectorDate: DateTime.getTodayIso(),
      siteRepDate: DateTime.getTodayIso(),
    };
    inspectionData.locked = false;
    inspectionData.inspectionCategory = "onsite";

    return inspectionData;
  };

  var prepareInspectionData = function (data) {
    data.locationObservations = prepareLocationObservation(data);
    data.facilityId = data.facility?.id;

    return {
      ...data,
      ...getObservationsData(data),
    };
  };

  var getObservationsData = function (data) {
    const facilityInspectionConstants = ToolSettings.getSetting(
      "indcom",
      "facility-inspection-constants",
    );

    const observationsByLocation = getObservationsByLocation();
    const openFindings = getOpenFindings(data, observationsByLocation);
    const siteDocumentsLocation = "General - Site Documents";
    const siteDocuments = observationsByLocation.get(siteDocumentsLocation);
    observationsByLocation.delete(siteDocumentsLocation);

    return {
      observationsByLocation: observationsByLocation,
      openFindings: openFindings,
      siteDocuments: siteDocuments,
      startedLocations: getStartedLocations(data.locationObservations),
      customCompliantRadioLabels: getCustomCompliantRadioLabels(),
    };

    function getObservationsByLocation() {
      const grouped = new Map();
      facilityInspectionConstants.locationObservationConstants.forEach(function (observation) {
        pushInspectionLocationObservationToMap(grouped, observation);
      });
      return grouped;
    }

    function getStartedLocations(locationObservations) {
      const locations = new Set();
      if (!locationObservations) return [];
      facilityInspectionConstants.locationObservationConstants.forEach(function (observation) {
        if (Object.keys(locationObservations).includes(String(observation.id))) {
          locations.add(observation.inspectionLocation);
        }
      });
      return Array.from(locations);
    }
  };

  function getCustomCompliantRadioLabels() {
    return ToolSettings.getSetting("indcom", "customCompliantRadioLabels");
  }

  var getOpenFindings = function (data, observationsByLocation) {
    const allValidInspectionTypes = FormSettings.getDropdownOptions("inspection-type")?.map(
      (o) => o.value,
    );

    const openFindings = new Map();
    Object.keys(data?.openFindings ?? []).forEach(function (openFindingKey) {
      const observation = getConstantById(parseInt(openFindingKey));

      pushInspectionLocationObservationToMap(openFindings, observation, allValidInspectionTypes);
      updateExistingLocationObservation(openFindingKey, observation, observationsByLocation);
    });
    return openFindings;
  };

  var getConstantById = function (id) {
    const constants = ToolSettings.getSetting("indcom", "facility-inspection-constants");
    return (
      constants?.locationObservationConstants?.find((constant) => constant.id === id) ||
      constants?.historicalObservationConstants?.find((constant) => constant.id === id)
    );
  };

  var prepareLocationObservation = function (data) {
    return $.extend(true, {}, data.openFindings, data.locationObservations);
  };

  var updateExistingLocationObservation = function (
    openFindingKey,
    observation,
    observationsByLocation,
  ) {
    const existingObservationByLocation = observationsByLocation.get(
      observation.inspectionLocation,
    );
    const index = existingObservationByLocation?.findIndex(
      (x) => x.id === parseInt(openFindingKey),
    );
    if (existingObservationByLocation && index !== -1) {
      existingObservationByLocation.splice(index, 1);
    }
  };

  var pushInspectionLocationObservationToMap = function (map, observation, validTypes = null) {
    if (!map.has(observation.inspectionLocation)) {
      map.set(observation.inspectionLocation, []);
    }

    if (validTypes === null) {
      validTypes = observation.comboInspectionTypes.join(" ");
    }

    const inspectionLocation = map.get(observation.inspectionLocation);
    inspectionLocation.push({
      observationText: observation.observationText,
      id: observation.id,
      validInspectionTypes: validTypes,
    });
  };

  return {
    getNewFacilityInspectionDefaults,
    prepareInspectionData,
    getConstantById,
    getCustomCompliantRadioLabels,
  };
};

module.exports = FacilityInspectionConstants();

const DateTime = require("../../dateTime");
const Session = require("../../login/session");
const ToolSettings = require("../../settings/toolSettings");
const FormSettings = require("../../settings/formSettings");
