"use strict";

const BaseEnforcementLetterManagement = function (EnforcementController) {
  const stringKey = `enforcement-letter-management`;
  const tabHeader = "Enforcement Letter Management";
  let $page;
  let newLetterFileDropzone;

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("click", "[name=upload-enforcement-letter]", uploadNewEnforcementLetter);
    $page.on("click", ".delete-button", showDeleteLetterFileModal);
    $page.on("click", ".edit-letter", renderLetterEditing);
    $page.on("click", ".save-letter", renderLetterUpdate);
    initializeNewFileDropzone($page);
    initializeExistingFileDropzones($page);
  };

  var unloadListeners = function () {
    $page.off("click", "[name=upload-enforcement-letter]", uploadNewEnforcementLetter);
    $page.off("click", ".delete-button", showDeleteLetterFileModal);
    $page.off("click", ".edit-letter", renderLetterEditing);
    $page.off("click", ".save-letter", renderLetterUpdate);
  };

  var getProps = function (data) {
    return {
      readOnly: data.readOnly ?? false,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var initializeNewFileDropzone = function ($page) {
    const $parent = $page.find(".new-upload");
    newLetterFileDropzone = initializeDropzone($parent, null, true, false, toggleUploadButton);
  };

  var initializeExistingFileDropzones = function () {
    const data = EnforcementController.getLatestData();
    data?.letterFiles?.forEach(function (letterFile) {
      const uuid = letterFile?.uuid;
      const $dropzone = $page.find(`.uploaded-letters [data-id=${uuid}]`);
      const dropzone = initializeDropzone($dropzone, letterFile?.filename, false, true);
      dropzone.setDownloadHandler(() => {
        ApiCalls.downloadEnforcementLetterFile(uuid);
      });
    });
  };

  var initializeNewCardListeners = function ($parent, $dropzone, letterFile) {
    CollapsingCard.initializeCards($parent);
    initializeDropzone($dropzone, letterFile?.file, true, true);
  };

  var toggleUploadButton = function (fileList) {
    const $uploadButton = $page.find("[name=upload-enforcement-letter]");
    $uploadButton.attr("disabled", !fileList.length);
  };

  var uploadNewEnforcementLetter = function () {
    createLetterFileCard();
    clearNewLetterFileFields();
  };

  var createLetterFileCard = function () {
    const $uploadedLetters = $page.find(".uploaded-letters");
    const data = EnforcementController.getLatestData();
    const newLetterCard = {
      uuid: UUID.v4(),
      new: true,
      signReturnDate: data?.signReturnDate,
      issueDate: data?.letterIssueDate,
      file: data?.newEnforcementLetterFile,
      filename: data?.newEnforcementLetterFile[0]?.name,
    };
    const html = nunjucks.render("modals/lidEnforcement/existingLetterFileCard.njk", {
      letterFile: newLetterCard,
    });
    $uploadedLetters.append(html);
    initializeNewCardListeners(
      $uploadedLetters,
      $uploadedLetters.find(`[data-id=${newLetterCard.uuid}]`),
      newLetterCard,
    );
    appendNewLetterData(newLetterCard);
    EnforcementController.updateLetterFilesList();
  };

  var clearNewLetterFileFields = function () {
    DateTimePicker.unsetDate($page, "letter-issue-date");
    DateTimePicker.unsetDate($page, "sign-return-date");
    EnforcementController.unsetFormField("letterIssueDate");
    EnforcementController.unsetFormField("signReturnDate");
    newLetterFileDropzone.removeNew(null, 0);
  };

  var showDeleteLetterFileModal = function (e) {
    e.stopPropagation();
    const $parentCard = $(this).parents(".card");
    const selectedLetterFiles = getCurrentlySelectedLetterFiles();
    if (selectedLetterFiles.includes($(e.currentTarget).data("id"))) {
      MessageModal.showReturnWarningModal(
        "You cannot delete this file because it was associated to one or more enforcement levels. Click 'Return' and disassociate this letter with the enforcement level(s) to continue.",
      );
    } else {
      MessageModal.showConfirmWarningModal(
        "Are you sure you want to delete this enforcement letter file? This will not delete the enforcement record. Click 'Delete' to continue or click 'Cancel' to return.",
        function () {
          const id = $parentCard.find("[data-id]").data("id");
          const index = getFileIndex(id);
          EnforcementController.setFormField(["letterFiles", index, "deleted"], true);
          $parentCard.remove();
          EnforcementController.updateLetterFilesList();
        },
        "Cancel",
        "Delete letter",
      );
    }
  };

  var appendNewLetterData = function (data) {
    const currentData = EnforcementController.getLatestData();
    const currentLength = currentData.letterFiles?.length ?? 0;
    EnforcementController.setFormField(["letterFiles", currentLength], data);
  };

  var renderLetterEditing = function (e) {
    const $cardToEdit = $(this).parents("[id^=card]").first();
    const fileData = getFileDataByUuid($cardToEdit.find("[data-id]").data("id"));
    const html = nunjucks.render("modals/lidEnforcement/modifyLetterFileData.njk", {
      letterFile: fileData,
      index: getFileIndex(fileData?.uuid),
    });
    $cardToEdit.html(html);
    initializeDatePickers($cardToEdit, fileData);
    const dropzone = initializeDropzone($cardToEdit, fileData?.file, fileData?.new ?? false);
    dropzone.setDownloadHandler(() => {
      ApiCalls.downloadEnforcementLetterFile(fileData?.uuid);
    });
  };

  var renderLetterUpdate = function (e) {
    const $cardToEdit = $(this).parents("[id^=card]").first();
    const fileData = getFileDataByUuid($cardToEdit.find("[data-id]").data("id"));

    const html = nunjucks.render("modals/lidEnforcement/existingLetterFileData.njk", {
      letterFile: fileData,
    });
    $cardToEdit.html(html);
    const dropzone = initializeDropzone($cardToEdit, fileData?.file, fileData?.new ?? false);
    dropzone.setDownloadHandler(() => {
      ApiCalls.downloadEnforcementLetterFile(fileData?.uuid);
    });
    updateCardTitle($cardToEdit.parent(), fileData);
    EnforcementController.updateLetterFilesList();
  };

  var initializeDatePickers = function ($card, fileData) {
    Form.initializeDatePickers($card, EnforcementController.getFormKey());
    if (fileData.issueDate) {
      DateTimePicker.setDateOnElement(
        $card.find("[name*=issue-date]").assertLength(1),
        fileData.issueDate,
      );
    }
    if (fileData.signReturnDate) {
      DateTimePicker.setDateOnElement(
        $card.find("[name*=sign-return-date]").assertLength(1),
        fileData.signReturnDate,
      );
    }
  };

  var initializeDropzone = function (
    $parent,
    letterFile,
    newFile = false,
    readOnly = true,
    fileChangeCallback = null,
  ) {
    letterFile = letterFile === null ? [] : letterFile;
    return Form.initializeDropzone(EnforcementController.getFormKey(), $parent, {
      newFiles: newFile ? letterFile : [],
      existingFiles: newFile ? null : letterFile,
      maxNumberFiles: 1,
      readOnly,
      fileChangeCallback,
    });
  };

  var getFileDataByUuid = function (uuid) {
    const data = EnforcementController.getLatestData();
    return data.letterFiles?.find((file) => file?.uuid === uuid);
  };

  var getFileIndex = function (uuid) {
    const data = EnforcementController.getLatestData();
    return data.letterFiles?.findIndex((file) => file?.uuid === uuid);
  };

  var updateCardTitle = function ($card, fileData) {
    const header = getHeaderTextFromFile(fileData);
    $card.find(".card-header").contents().first()[0].textContent = header;
  };

  var getHeaderTextFromFile = function (fileData, includeIssueDate = true) {
    const issueDate = DateTime.formatIsoString(fileData?.issueDate);
    const signReturnDate = DateTime.formatIsoString(fileData?.signReturnDate);
    const fileReturn = `${fileData.filename}, ${signReturnDate}`;

    return includeIssueDate ? `${issueDate}, ${fileReturn}` : fileReturn;
  };

  var getLetterFilesList = function () {
    return EnforcementController.getLatestData()
      ?.letterFiles?.filter((file) => !file.deleted)
      .map(function (letterFile) {
        return {
          name: getHeaderTextFromFile(letterFile, false),
          value: letterFile.uuid,
        };
      });
  };

  var getCurrentlySelectedLetterFiles = function () {
    const currentLetterFile = EnforcementController.getLatestData()?.enforcementLetter;
    const letterFileHistory =
      EnforcementController.getLatestData()?.enforcementHistory?.map(
        (history) => history.enforcementLetter,
      ) ?? [];
    letterFileHistory.push(currentLetterFile);
    return letterFileHistory;
  };

  return {
    unloadListeners,
    loadListeners,
    getProps,
    getStringKey,
    getTabHeader,
    getLetterFilesList,
  };
};

module.exports = BaseEnforcementLetterManagement;

const Form = require("../general/form");
const DateTimePicker = require("../general/dateTimePicker");
const UUID = require("uuid");
const CollapsingCard = require("../general/collapsingCard");
const DateTime = require("../dateTime");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
