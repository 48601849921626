"use strict";

const ConstructionProjectGeoServerLayer = function () {
  const layerName = "constructionProjectG2";
  const layerKey = "constructionProjectG2Layer";
  var canNotViewProjectIds = [];

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: ConstructionIcon.getConstructionProjectIconMarker(feature.properties),
    });
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadTabListenerForMap(map, mapLayers, mapId);
    const constructionProjectGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(
      layerName,
      mapId,
      constructionProjectGeoServerLayer,
    );

    constructionProjectGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    constructionProjectGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
    ConstructionProjectPopupGeoServerLayer.loadDomListeners();
    loadConditionPhaseToggleUpdatedListener();
    _loadGlobalListeners();
  };

  var _loadGlobalListeners = function () {
    $("body")
      .off("click", ".js-edit-project-enforcement", _editEnforcementAction)
      .on("click", ".js-edit-project-enforcement", _editEnforcementAction)
      .off("click", ".js-view-project-enforcement", _viewEnforcementAction)
      .on("click", ".js-view-project-enforcement", _viewEnforcementAction)
      .off("click", ".js-issue-flagged-enforcement", _issueFlaggedEnforcementAction)
      .on("click", ".js-issue-flagged-enforcement", _issueFlaggedEnforcementAction);
  };

  var _editEnforcementAction = function () {
    const id = $(this).data("id");
    ProjectEnforcementController.editEnforcementAction(id);
  };

  var _viewEnforcementAction = function () {
    const id = $(this).data("id");
    ProjectEnforcementController.viewEnforcementAction(id);
  };

  var _issueFlaggedEnforcementAction = function () {
    const projectId = $(this).data("projectId");
    const inspectionId = $(this).data("inspectionId");

    ProjectEnforcementController.loadNewEnforcement(projectId, {
      flaggedForEnforcement: true,
      flaggedForEnforcementInspectionId: inspectionId,
    });
  };

  var loadConditionPhaseToggleUpdatedListener = function (map, mapLayers) {
    Tree.select(["filters", "conditionPhaseToggle"]).on("update", function (e) {
      if (
        e.data.previousData !== e.data.currentData &&
        Tree.get("dataView") === "construction-project"
      ) {
        const toggle = e.data.currentData;

        if (toggle === "condition") {
          Filters.updateConditionCheckboxes(["construction", "post-active"]);
          Tree.set(["filters", "phase"], ["construction", "post-active"]);
        } else {
          Filters.updatePhaseCheckboxes([
            "planning",
            "planned",
            "construction",
            "post-active",
            "completed",
            "certified",
          ]);

          Tree.set(
            ["filters", "phase"],
            ["planning", "planned", "construction", "post-active", "completed", "certified"],
          );
        }
        MultipleSelect.updateDropdown($('.dropdown input[name="phase"]'));
        FilterSummary.render();
      }
    });
  };

  var loadTabListenerForMap = function (map, mapLayers, mapId) {
    Tree.select("activeTab").on("update", function (e) {
      if (Tree.get("dataView") === "construction-project") {
        const activeTab = e.data.currentData;

        if (activeTab === "data") {
          if (Tree.get("layers", "constructionProjectG2", "data")) {
            if (
              !MapFunctions.mapHasLayer(map, mapLayers.constructionProjectG2Layer) &&
              mapLayers.constructionProjectG2Layer !== null
            ) {
              map.addLayer(mapLayers.constructionProjectG2Layer);
            }
          }
        }
      }
    });
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeConstructionLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var setCanNotViewProjectIds = function (layerData) {
    const newArray = [];
    for (const datum of layerData) {
      if (datum.canViewProject === false) {
        newArray.push(datum.id);
      }
    }

    canNotViewProjectIds = newArray;
  };

  var getCanNotViewProjectIds = function () {
    return structuredClone(canNotViewProjectIds);
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      id: data.id,
      geometry: geometry,
      gid: data.group_id,
      groupId: data.group_id,
      city: data.city,
      contractor: data.contractor_name,
      highPriority: data.high_priority,
      isHighPriority: data.high_priority,
      zipcode: data.zip_code,
      streetAddress: data.street_address,
      developmentType: data.development_type,
      projectIdentifier: data.project_identifier,
      projectName: data.project_name,
      projectType: data.project_type,
      inspections: JSON.parse(data.inspections),
      nextInspection: JSON.parse(data.next_inspection_data),
      landOwnership: data.land_ownership,
      state: data.state,
      phase: data.project_phase,
      pauseStatus: data.pause_status,
      isPaused: data.pause_status === "paused",
      priorityInfo: JSON.parse(data.priority_information),
      hasUnresolvedEnforcementAction: data.has_unresolved_enforcement_actions,
      implementerType: data.implementer_type,
      maintainerType: data.maintainer_type,
      isScorePassing: data.score_passing,
      projectStartDate: data.project_start_date,
      projectEndDate: data.project_end_date,
      noInspection: !data.inspection_is_due,
      lastInspection: data.recent_inspection_date,
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      highway_name: data.highway_name,
      ...getProjectPermissions(data),
    };
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    let data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    data = organizeConstructionLayerData(data);
    if (!onlineFiltering) {
      setCanNotViewProjectIds(data);
    }
    return data;
  };

  var getProjectPermissions = function (project) {
    const limitedAccess = getLimitedAccessPermission(project);

    return {
      canEditProject: canEditProject(project, limitedAccess),
      canCertifyProject: UserPermissions.getPermission("can_certify_projects"),
      canInspectProject: canInspectProject(project),
      canViewProject: canViewProject(project, limitedAccess),
      canAdvanceProjectPhase: UserPermissions.getPermission("can_advance_phase"),
      canCreateEnforcement: UserPermissions.getPermission("can_create_enforcement"),
    };
  };

  // Same as API ConstructionProjectPolicy@edit
  var canEditProject = function (project, limitedAccess) {
    if (
      !UserPermissions.getPermission("can_edit_projects") ||
      !UserPermissions.doesNotIncludePhase("readOnlyProjectPhases", project.project_phase)
    ) {
      return false;
    }

    if (limitedAccess !== null) {
      return limitedAccess;
    }

    return true;
  };

  // @return:
  //          null  = not limited access
  //          true  = is limited access, has access
  //          false = is limited access, does not have access
  var getLimitedAccessPermission = function (project) {
    if (!UserPermissions.getPermission("is_limited_access")) {
      return null;
    }

    return (
      isCompanyAffiliatedWith(project.affiliate_companies) ||
      UserPermissions.getPermission("affiliated_access_project_ids").includes(project.id)
    );
  };

  // Based on API User@isProjectAffiliated
  var isCompanyAffiliatedWith = function (affiliate_companies) {
    const companyAccess = parseProjectAffiliatedCompanies(affiliate_companies);
    const userCompanyRoles =
      ToolSettings.getSetting("constants", "projectAffiliateAccessRoles")[
        UserPermissions.getPermission("user_role")
      ] ?? [];
    const userCompanyId = UserPermissions.getPermission("user_company_id");

    return companyAccess.some(
      (company) => company.id === userCompanyId && userCompanyRoles.includes(company.role),
    );
  };

  var parseProjectAffiliatedCompanies = function (affiliate_companies) {
    if (!affiliate_companies) {
      return [];
    }

    return JSON.parse(affiliate_companies).filter((company) => company.id !== null);
  };

  // Same as API ConstructionProjectPolicy@inspectProject
  var canInspectProject = function (project) {
    return (
      inActivePhases(project.project_phase) && UserPermissions.getPermission("can_inspect_projects")
    );
  };

  var inActivePhases = function (phase) {
    return ["construction", "post-active"].includes(phase);
  };

  // Based on API ConstructionProjectPolicy@view
  var canViewProject = function (project, limitedAccess) {
    if (limitedAccess !== null) {
      return limitedAccess;
    }

    return UserPermissions.doesNotIncludePhase("restrictedProjectPhases", project.project_phase);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeConstructionLayerData,
    getLayerData,
    getProjectPermissions,
    getCanNotViewProjectIds,
    getLimitedAccessPermission,
    canEditProject,
    canInspectProject,
  };
};

module.exports = ConstructionProjectGeoServerLayer();

const ConstructionIcon = require("../construction/constructionIcon");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const ConstructionProjectPopupGeoServerLayer = require("./constructionProjectPopupGeoServerLayer");
const UserPermissions = require("../login/userPermissions");
const ToolSettings = require("../settings/toolSettings");
const Filters = require("../mapFunctions/filters");
const MultipleSelect = require("../general/multipleSelect");
const FilterSummary = require("../filters/filterSummary");
const ProjectEnforcementController = require("../construction/newEnforcement/projectEnforcementController");
