"use strict";

const PostConstructionProjectGeoServerLayer = function () {
  const layerName = "postConstructionProjectG2";
  const layerKey = "postConstructionProjectG2Layer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: createIconFunction(feature),
    });
  };

  var createIconFunction = function (feature) {
    return LidIcon.getLidProjectMapIcon(feature.properties);
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadTabListenerForMap(map, mapLayers, mapId);
    loadDataViewDropdownListner(map, mapLayers, mapId);
    loadConditionPhaseToggleUpdatedListener(map, mapLayers);
    const postConstructionProjectGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(
      layerName,
      mapId,
      postConstructionProjectGeoServerLayer,
    );

    postConstructionProjectGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    postConstructionProjectGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
    PostConstructionProjectPopupGeoServerLayer.loadDomListeners();
    _loadGlobalListeners();
    _handleMessages();
  };

  var _loadGlobalListeners = function () {
    $("body")
      .off("click", ".js-edit-lid-enforcement", _editEnforcementAction)
      .on("click", ".js-edit-lid-enforcement", _editEnforcementAction)
      .off("click", ".js-view-lid-enforcement", _viewEnforcementAction)
      .on("click", ".js-view-lid-enforcement", _viewEnforcementAction);
  };

  var _editEnforcementAction = function () {
    const id = $(this).data("id");
    LidEnforcementController.editEnforcementAction(id);
  };

  var _viewEnforcementAction = function () {
    const id = $(this).data("id");
    LidEnforcementController.viewEnforcementAction(id);
  };

  const _handleMessages = function () {
    const groupId = Tree.get("activeGroup", "groupId");
    const channel = `projects.${groupId}`;

    Messages.subscribe({
      channel,
      event: "delete",
      callback: (data) => deleteRow(data.id),
    });
  };

  var loadTabListenerForMap = function (map, mapLayers, mapId) {
    Tree.select("dataView").on("update", function (e) {
      if (Tree.get("dataView") === "lid-project") {
        const activeTab = e.data.currentData;

        if (activeTab === "data") {
          if (Tree.get("layers", "postConstructionProjectG2", "data")) {
            if (
              !MapFunctions.mapHasLayer(map, mapLayers.postConstructionProjectG2) &&
              mapLayers.postConstructionProjectG2 !== null
            ) {
              map.addLayer(mapLayers.postConstructionProjectG2);
            }
          }
        }
      }
    });
  };

  var loadDataViewDropdownListner = function (map, mapLayers, mapId) {
    $(".dropdown-menu").on("click", "li", function (e) {
      if (Tree.get("dataView") === "lid-runoff") {
        const postConstructionProjectGeoServerLayer = Tree.get([layerName, mapId]);
        postConstructionProjectGeoServerLayer?.removeWmsLayer(map, mapLayers);
      }
    });
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeConstructionLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      id: data.id,
      geometry: geometry,
      gid: data.group_id,
      groupId: data.group_id,
      pcrCondition: data.pcr_condition,
      priorityInformation: JSON.parse(data.priority_information),
      streetAddress: data.street_address,
      projectIdentifier: data.project_identifier,
      projectName: data.project_name,
      performanceTier: data.highest_tier_number,
      phase: data.project_phase,
      alternativeCompliance: data.alternative_compliance,
      projectEndDate: data.project_end_date,
      implementerType: data.implementer_type,
      maintainerType: data.maintainer_type,
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      summaryLetterReady: data.summary_letter_ready,
      openEnforcements: data.open_enforcements,
      ...getProjectPermissions(data),
    };
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeConstructionLayerData(data);
  };

  var removeLayerOnRadioClick = function (that) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    const alreadyChecked = Tree.get(["layers", that.value, "isEnabled"]);
    if (popupCloseButn?.length) {
      popupCloseButn[0].click();
    }
    if ($(that).attr("value") === "lidProjectBmp" && Tree.get("activeTab") === "data") {
      const map = MainMap.getMap();
      const mapLayers = MainMap.getMapLayers();

      if (MapFunctions.mapHasLayer(map, mapLayers.postConstructionProjectG2Layer)) {
        map.removeLayer(mapLayers.postConstructionProjectG2Layer);
      }
    }

    if ($(that).attr("value") === "postConstructionProjectG2" && alreadyChecked) {
      Actions.setItemCount(0);
    }
  };

  var getProjectPermissions = function (project) {
    const limitedAccess = ConstructionProjectGeoServerLayer.getLimitedAccessPermission(project);
    return {
      canEditProject: ConstructionProjectGeoServerLayer.canEditProject(project, limitedAccess),
      canInspectProject: ConstructionProjectGeoServerLayer.canInspectProject(project),
      canCertifyProject: UserPermissions.getPermission("can_certify_projects"),
      canAdvanceProjectPhase: UserPermissions.getPermission("can_advance_phase"),
      canCreateEnforcement: UserPermissions.getPermission("can_issue_lid_enforcement"),
    };
  };

  var loadConditionPhaseToggleUpdatedListener = function (map, mapLayers) {
    Tree.select(["filters", "conditionPhaseToggle"]).on("update", function (e) {
      if (e.data.previousData !== e.data.currentData && Tree.get("dataView") === "lid-project") {
        const toggle = e.data.currentData;

        if (toggle === "condition") {
          Filters.updateConditionCheckboxes(["completed", "certified"]);
          Tree.set(["filters", "status"], ["completed", "certified"]);
        } else {
          Filters.updatePhaseCheckboxes([
            "planning",
            "planned",
            "construction",
            "post-active",
            "completed",
            "certified",
          ]);
          Tree.set(
            ["filters", "status"],
            ["planning", "planned", "construction", "post-active", "completed", "certified"],
          );
        }
        MultipleSelect.updateDropdown($('.dropdown input[name="status"]'));
        FilterSummary.render();
        handleLegendDisplayByConditionPhaseToggle(toggle);
        DisplayOptions.validateConditionPhaseToggleRadioButtons(toggle);
      }
    });
  };

  var handleLegendDisplayByConditionPhaseToggle = function (toggle) {
    const layerNeedFilters = Tree.get("layers", "lidProjectArea", "data")
      ? "lidProjectArea"
      : "lidProject";

    DisplayOptions.handleLegendDisplayByConditionPhaseToggle(layerName, toggle, layerNeedFilters);
  };

  var deleteRow = async function (id) {
    const postConstructionProjectGeoServerLayer = Tree.get([layerName, "main"]);
    await postConstructionProjectGeoServerLayer.deleteRow(Number(id));
    postConstructionProjectGeoServerLayer.loadGeoServerLayer(
      MainMap.getMap(),
      MainMap.getMapLayers(),
      "main",
      {
        refreshData: false,
        loadingScreen: true,
      },
    );
    FactSheetNew.closeIfId(id);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeConstructionLayerData,
    getLayerData,
    removeLayerOnRadioClick,
    handleLegendDisplayByConditionPhaseToggle,
  };
};

module.exports = PostConstructionProjectGeoServerLayer();

const Actions = require("../actions");
const LidIcon = require("../lid/lidIcon");
const UserPermissions = require("../login/userPermissions");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const MainMap = require("../mapFunctions/mainMap");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const ConstructionProjectGeoServerLayer = require("./constructionProjectGeoServerLayer");
const PostConstructionProjectPopupGeoServerLayer = require("./postConstructionProjectPopupGeoServerLayer");
const DisplayOptions = require("../mapFunctions/displayOptions");
const Filters = require("../mapFunctions/filters");
const Messages = require("../messages");
const FactSheetNew = require("../general/factSheetNew");
const LidEnforcementController = require("../lid/lidEnforcement/lidEnforcementController");
const MultipleSelect = require("../general/multipleSelect");
const FilterSummary = require("../filters/filterSummary");
