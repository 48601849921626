"use strict";

const ToDoMapTable = function () {
  const expandedSections = new Set();
  var dataView;

  var init = function () {
    loadListeners();
    BmpFcsTodoController.setTodoMapBmpFcsRadioButtons();
    loadTodosIfNecessary();
  };

  var _setDataView = function (newDataView) {
    dataView = newDataView;
  };

  var loadTodosIfNecessary = function () {
    const todoData = Tree.get(["todos", dataView, "unfilteredData"]);
    const activeTab = Tree.get("activeTab");

    if (activeTab === "todo" && (!todoData || todoData.length === 0)) {
      load(dataView);
    }
  };

  var loadListeners = function () {
    _setDataView(Tree.get("dataView"));

    Tree.select(["todos", dataView, "unfilteredData"]).off("update", update);
    Tree.select(["todos", dataView, "unfilteredData"]).on("update", update);

    Tree.select("activeTab").off("update", onActiveTabChange);
    Tree.select("activeTab").on("update", onActiveTabChange);

    Tree.select(["todos", dataView, "selectedSubject"]).off("update", updateToDoMapLayers);
    Tree.select(["todos", dataView, "selectedSubject"]).on("update", updateToDoMapLayers);

    Tree.select(["todos", dataView, "todoIdSets"]).off("update", updateToDoMapLayers);
    Tree.select(["todos", dataView, "todoIdSets"]).on("update", updateToDoMapLayers);

    Tree.select(["table", "dataSort"]).off("update", onDataSortUpdate);
    Tree.select(["table", "dataSort"]).on("update", onDataSortUpdate);

    Tree.select("activeTab").off("update", onTabOrNaveUpdate);
    Tree.select("activeTab").on("update", onTabOrNaveUpdate);
    Tree.select("navToggleSelection").off("update", onTabOrNaveUpdate);
    Tree.select("navToggleSelection").on("update", onTabOrNaveUpdate);

    const $todoMapTableContainer = $("#todo-map-table-container");
    const $bottomFloatingInputsTable = $("#bottomFloatingInputsTable");

    $todoMapTableContainer.off("click", ".todo-subject-header h3", toggleToDoSubjectHeader);
    $todoMapTableContainer.on("click", ".todo-subject-header h3", toggleToDoSubjectHeader);
    $todoMapTableContainer.off("click", ".expand-btn", expandTodoMapTable);
    $todoMapTableContainer.on("click", ".expand-btn", expandTodoMapTable);
    $todoMapTableContainer.off("click", ".collapse-btn", collapseTodoMapTable);
    $todoMapTableContainer.on("click", ".collapse-btn", collapseTodoMapTable);
    $todoMapTableContainer.off("click", ".clear-btn", clearTodoMapTableSelection);
    $todoMapTableContainer.on("click", ".clear-btn", clearTodoMapTableSelection);
    $bottomFloatingInputsTable.off("click", ".rollups-container", onRollUpClick);
    $bottomFloatingInputsTable.on("click", ".rollups-container", onRollUpClick);
    $todoMapTableContainer.off("click", ".todo-filter-button", toDoFilterButtonClick);
    $todoMapTableContainer.on("click", ".todo-filter-button", toDoFilterButtonClick);
    $todoMapTableContainer.off("click", ".todo-filter-label", toDoFilterLabelClick);
    $todoMapTableContainer.on("click", ".todo-filter-label", toDoFilterLabelClick);
  };

  var update = async function () {
    _setDataView(Tree.get("dataView"));
    let data = Tree.get(["todos", dataView, "unfilteredData"]);
    if (!data) {
      return;
    }
    data = Misc.shallowCloneArrayObjects(data);
    const todoMapTableData = ToDoFunctions.prepareTodoDataIntoSubjects(data);
    await _prepareToDoData(todoMapTableData);
    createTodoIdSets(todoMapTableData);
    storeTotalTodoCounts(todoMapTableData);
    renderToDoMapTable(todoMapTableData);
  };

  var onDataSortUpdate = function () {
    if (Tree.get("activeTab") === "todo" && Tree.get("navToggleSelection") === "map") {
      collapseTodoMapTable();
    }
  };

  var enableDropdownIncidentsInsight = function (tab) {
    if ((tab === "insight" || tab === "todo") && Tree.get("dataView") === "incidents") {
      $(".navbar-dropdown").removeClass("disabled");
    }
  };

  var onTabOrNaveUpdate = function (e) {
    const $todoMapTableContainer = $("#todo-map-table-container");
    const $bottomFloatingInputsTable = $("#bottomFloatingInputsTable");

    const activeTab = Tree.get("activeTab");
    enableDropdownIncidentsInsight(activeTab);
    const navToggleSelection = Tree.get("navToggleSelection");

    if (activeTab === "todo" && navToggleSelection === "map") {
      $todoMapTableContainer.show();
      MainMapController.handleMapListToggleDisplay();

      Mobile.setVisibleOnMobile($bottomFloatingInputsTable, true);
    } else {
      $todoMapTableContainer.hide();
      Mobile.setVisibleOnMobile($bottomFloatingInputsTable, false);
      MainMapController.handleMapListToggleDisplay();
    }
  };

  var onActiveTabChange = function (e) {
    const dataView = Tree.get("dataView");
    const activeTab = e.data.currentData;

    handleAttributechangesBasedOnTabSbmps(dataView, activeTab);
    loadTodosIfNecessary();

    Actions.selectCatchmentsByFilter();
    if (!_isLidBmpLayerEnabled()) {
      updateToDoMapLayers();
    }

    DataViewFunctions.getCurrentDataViewProperty("activeTabChangeCallback", dataView)?.(
      activeTab,
      dataView,
    );

    FilterSummary.render();
  };

  var _isLidBmpLayerEnabled = function () {
    return Tree.get(["layers", "lidProjectBmp", "isEnabled"]);
  };

  var handleAttributechangesBasedOnTabSbmps = function (dataView, activeTab) {
    const isGis = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const layerType = sessionStorage.getItem("geoServerLayerType");

    if (dataView === "lid-project" && activeTab === "todo") {
      $("#radio-lid-project-main").val("lidProject");
      $("#check-cluster-lid-project-main").attr("data-layer", "lidProject");
      $("#check-cluster-lid-project-main").attr("name", "cluster");
      $("#radio-phase-lid-project-main").attr("data-layer", "lidProject");
    }
    if (dataView === "lid-project" && activeTab === "data") {
      $("#radio-lid-project-main").val("postConstructionProjectG2");
      $("#check-cluster-lid-project-main").attr("data-layer", "postConstructionProjectG2");
      $("#check-cluster-lid-project-main").attr("name", "super-cluster");
      $("#radio-phase-lid-project-main").attr("data-layer", "postConstructionProjectG2");
      if (isGis && layerType === "WFS") {
        $("#check-cluster-lid-project-main").prop("checked", true);
      } else if (isGis && layerType === "WMS") {
        $("#check-cluster-lid-project-main").prop("checked", false);
      }
    }
    if (dataView === "bmp" && activeTab === "todo") {
      $("#radio-bmps-main").val("bmps");
      $("#check-cluster-bmps-main").attr("data-layer", "bmps");
      $("#check-cluster-bmps-main").attr("name", "cluster");
      $("#radio-phase-bmps-main").attr("data-layer", "bmps");
      $("#radio-condition-bmps-main").attr("data-layer", "bmps");
    }
    if (dataView === "bmp" && activeTab === "data") {
      const conditionalPhaseLogic = Tree.get("filters", "conditionPhaseToggle");
      $("#radio-bmps-main").val("structuralBmps");
      $("#check-cluster-bmps-main").attr("data-layer", "structuralBmps");
      $("#check-cluster-bmps-main").attr("name", "super-cluster");
      $("#radio-phase-bmps-main").attr("data-layer", "structuralBmps");
      $("#radio-condition-bmps-main").attr("data-layer", "structuralBmps");
      if (isGis && layerType === "WFS") {
        $("#check-cluster-bmps-main").prop("checked", true);
      } else if (isGis && layerType === "WMS") {
        $("#check-cluster-bmps-main").prop("checked", false);
      }
      if (conditionalPhaseLogic === "condition") {
        $("#radio-condition-bmps-main").prop("checked", true);
        setTimeout(function () {
          Filters.disableNonConditionPhases(["completed", "certified"]);
        }, 100);
      }
    }
  };

  var toggleToDoSubjectHeader = function (e) {
    var $todoSubjectParentDiv = $(this).closest(".todo-subject-parent-div");
    if (isTodoTableCollapsing($todoSubjectParentDiv)) return;

    if ($todoSubjectParentDiv.hasClass("unavailable")) {
      $(this).find(".expand-arrow").toggleClass("open");
      return;
    }

    if ($(this).find(".expand-arrow").not(".unavailable").hasClass("open")) {
      if ($todoSubjectParentDiv.hasClass("selected")) {
        collapseExpandedHeader.call(this, e);
      } else {
        reselectExpandedHeader.call(this, e);
      }
    } else {
      expandCollapsedHeader.call(this, e);
    }
  };

  var collapseExpandedHeader = function (e) {
    var $todoSubjectParentDiv = $(this).closest(".todo-subject-parent-div");
    var subject = $todoSubjectParentDiv.data("subject");
    expandedSections.delete(subject);
    $(this).find(".expand-arrow").removeClass("open");
    $todoSubjectParentDiv.removeClass("active");
    $todoSubjectParentDiv.removeClass("selected");

    var selected = $(".todo-subject-parent-div.selected").length;
    if (!selected) {
      ToDoFunctions.setToDoSubject(null);
    }

    handleExpandCollapseButtons();
  };

  var reselectExpandedHeader = function (e) {
    e.stopPropagation(); //prevent data-toggle
    clearTodoMapTableSelection();

    var $todoSubjectParentDiv = $(this).closest(".todo-subject-parent-div");
    var selectedSubject = $todoSubjectParentDiv.data("subject");
    ToDoFunctions.setToDoSubject(selectedSubject);

    $todoSubjectParentDiv.addClass("selected");
  };

  var expandCollapsedHeader = function (e) {
    clearTodoMapTableSelection();

    var $todoSubjectParentDiv = $(this).closest(".todo-subject-parent-div");
    const selectedSubject = $todoSubjectParentDiv.data("subject");
    ToDoFunctions.setToDoSubject(selectedSubject);
    expandedSections.add(selectedSubject);

    $todoSubjectParentDiv.addClass("active");
    $todoSubjectParentDiv.addClass("selected");
    if (!$(this).find(".expand-arrow").hasClass("unavailable")) {
      $(this).find(".expand-arrow").addClass("open");
    }
    handleExpandCollapseButtons();
  };

  var handleExpandCollapseButtons = function () {
    const data = Misc.shallowCloneArrayObjects(Tree.get(["todos", dataView, "unfilteredData"]));
    const categories = ToDoFunctions.prepareTodoDataIntoSubjects(data);
    let categoriesCount = 0;
    Object.keys(categories).forEach(function (key) {
      if (categories[key].data.length != 0) {
        categoriesCount++;
      }
    });
    if (
      $("#todo-map-table-container").find(".expand-arrow.open").not(".unavailable").length ==
      categoriesCount
    ) {
      showCollapseAllButton();
    } else {
      showExpandAllButton();
    }
  };

  var expandTodoMapTable = function (e, todoSubject, popUpOpenClick) {
    if (!popUpOpenClick) {
      clearTodoMapTableSelection();
    }

    const $todoMapTableContainer = $("#todo-map-table-container");
    let $todoSubjectParentDivs = $todoMapTableContainer.find(".todo-subject-parent-div");

    if (todoSubject) {
      $todoSubjectParentDivs = $todoMapTableContainer.find(
        ".todo-subject-parent-div[data-subject='" + todoSubject + "']",
      );
    }

    $todoSubjectParentDivs.each(function () {
      const subject = $(this).data("subject");
      expandedSections.add(subject);
    });

    $todoSubjectParentDivs
      .find(".todo-list[aria-expanded=false]")
      .parent()
      .siblings()
      .children()
      .click();
    $todoSubjectParentDivs.addClass("active");

    $todoSubjectParentDivs.find(".todo-list").addClass("collapse").addClass("in");

    $todoSubjectParentDivs.find(".expand-arrow").not(".unavailable").addClass("open");

    handleExpandCollapseButtons();
  };

  var collapseTodoMapTable = function () {
    const $todoMapTableContainer = $("#todo-map-table-container");

    showExpandAllButton();

    $todoMapTableContainer.find(".open").not(".unavailable").removeClass("open");
    $todoMapTableContainer.find(".in").not(".unavailable").removeClass("in");
    $todoMapTableContainer
      .find(".todo-subject-parent-div")
      .removeClass("selected")
      .removeClass("active");
    expandedSections.clear();
    ToDoFunctions.setToDoSubject(null);
  };

  var expandTodoHeaderContainsIdbmp = function (idBmp) {
    if (Tree.get(["todos", dataView, "selectedSubject"])) {
      return;
    }

    const todoIdSets = Tree.get(["todos", dataView, "todoIdSets"]);

    for (const key in todoIdSets) {
      const set = todoIdSets[key];
      if (set.has(idBmp)) {
        expandTodoMapTable(null, key, true);
      }
    }
  };

  var clearTodoMapTableSelection = function () {
    const $todoMapTableContainer = $("#todo-map-table-container");
    $todoMapTableContainer.find(".todo-subject-parent-div").removeClass("selected");
    $todoMapTableContainer.find(".map-hover").removeClass("map-hover");

    ToDoFunctions.setToDoSubject(null);
  };

  var load = function () {
    const filters = Tree.get("filters");
    ToDoListController.loadTodosIfNecessary(filters, false);
  };

  var createTodoIdSets = function (todoMapTableData) {
    var todoIdSets = {};

    for (const key in todoMapTableData) {
      const todos = todoMapTableData[key].data;
      const set = new Set();

      todos.forEach(function (item) {
        if (item.id) {
          set.add(item.id);
        } else {
          set.add(item.relatedId);
        }
      });

      todoIdSets[key] = set;
    }

    Tree.set(["todos", dataView, "todoIdSets"], todoIdSets);
    return todoIdSets;
  };

  var storeTotalTodoCounts = function (todoMapTableData) {
    const totalTodoCounts = {};
    for (const key in todoMapTableData) {
      totalTodoCounts[key] = todoMapTableData[key].data.length;
    }
    Tree.set(["todos", dataView, "totalTodoCounts"], totalTodoCounts);
  };

  var renderToDoMapTable = function (todoMapTableData) {
    $("#todo-map-table").html("");
    DataViewFunctions.getUserEnabledToDoSubjects(dataView).forEach((subject) => {
      const subjectConfig = ToDoConfig.getToDoSubjectConfig([subject]);
      const html = nunjucks.render("table/toDo/toDoMapTableSection.njk", {
        subject,
        ...subjectConfig,
        todos: todoMapTableData[subject].data,
        filterLabels: Tree.get(["toDoFilterLabels", dataView])?.[subject],
        showToDoFilterButton: ToDoFunctions.getIsToDoFilterEnabled(subject),
        expandedSections: Array.from(expandedSections),
        selected: subject === Tree.get(["todos", dataView, "selectedSubject"]),
        dataView: Tree.get("dataView"),
      });
      $("#todo-map-table").append(html);
    });
    $(".todo-spatial-title").text(Tree.get("mapTitle"));
    ToDoList.setToDoFilterButtonDisplay("#todo-map-table", ".todo-subject-parent-div");
  };

  var showExpandAllButton = function () {
    const $todoMapTableContainer = $("#todo-map-table-container");
    $todoMapTableContainer.find(".expand-btn").show();
    $todoMapTableContainer.find(".collapse-btn").hide();
  };

  var showCollapseAllButton = function () {
    const $todoMapTableContainer = $("#todo-map-table-container");
    $todoMapTableContainer.find(".expand-btn").hide();
    $todoMapTableContainer.find(".collapse-btn").show();
  };

  var isTodoTableCollapsing = function ($element) {
    return $element.find(".todo-list").hasClass("collapsing");
  };

  var toDoFilterButtonClick = function (e) {
    var $button = $(this);
    var $parentDiv = $button.parents(".todo-subject-parent-div");
    var sectionName = $parentDiv.data("subject");
    ToDoFiltersModal.renderAndShow(sectionName);
    e.stopPropagation();
  };

  var toDoFilterLabelClick = function (e) {
    if ($(e.target).hasClass("close-button")) {
      const $label = $(this);
      const filterSection = $label.data("filterSection");
      const toDoSection = $label.parents(".todo-subject-parent-div").data("subject");
      ToDoList.setToDoFilterSectionToDefault(toDoSection, filterSection);
      $label.remove();
    }
    e.stopPropagation();
  };

  var onRollUpClick = function () {
    // Trigger table.js headerClick on rollup click
    $(this).closest(".catchment-table-header").find("h3").click();
  };

  var updateToDoMapLayers = function () {
    const dataView = Tree.get("dataView");
    var layerName = DataViewFunctions.getDataViewConfig(dataView)["defaultLayers"][0];
    if (!layerName || !ToDoFunctions.isToDoLayer(layerName)) return;
    const forceEnabled = Tree.get("dataView") !== dataView;

    LayerFunctions.filterToDoLayer(layerName, undefined, undefined, undefined, forceEnabled);
    BmpFcsTodoController.updateBmpFcsLayersByTodo();
  };

  var _prepareToDoData = function (todoMapTableData) {
    for (const subject in todoMapTableData) {
      const prepareDataCallback = ToDoConfig.getToDoSubjectConfig(
        [subject, "prepareDataCallback"],
        false,
      );

      if (prepareDataCallback) {
        todoMapTableData[subject]["data"] = prepareDataCallback(todoMapTableData[subject]["data"]);
      }
    }
  };

  return {
    init,
    load,
    update,
    expandTodoMapTable,
    expandTodoHeaderContainsIdbmp,
    collapseTodoMapTable,
    toggleToDoSubjectHeader,
    reselectExpandedHeader,
    renderToDoMapTable,
    updateToDoMapLayers,
    _setDataView,
    _prepareToDoData,
  };
};

module.exports = ToDoMapTable();

const Actions = require("../actions");
const BmpFcsTodoController = require("../bmpfcs/bmpFcsToDoController");
const DataViewFunctions = require("../dataViewFunctions");
const LayerFunctions = require("../layerFunctions");
const Mobile = require("../mobile");
const ToDoConfig = require("../config/toDoConfig");
const ToDoFiltersModal = require("./toDoFiltersModal");
const ToDoFunctions = require("./toDoFunctions");
const ToDoList = require("./toDoList");
const ToDoListController = require("./toDoListController");
const Tree = require("../tree");
const MainMapController = require("./mainMapController");
const Filters = require("./filters");
const FilterSummary = require("../filters/filterSummary");
const Misc = require("../misc");
