"use strict";

const AddBmpsTable = function () {
  var $tbl;
  var savedSelectedIdBmp = null;
  var addBmpsTableHandlers = {
    newBmpClickEnabledUpdateHandler: null,
  };
  var layerModule;

  var init = function (layerName) {
    layerModule = LayerFunctions.getLayerModule(layerName);
    loadListeners();
  };

  var loadListeners = function () {
    $tbl = $("#floating-add-bmps-table");
    $tbl.off();
    $tbl.on("click", ".delete-btn", deleteBmpClick);
    $tbl.on("blur", "input[name='bmp-name']", bmpNameInput);
    $tbl.on("change", "select[name='bmp_type']", bmpTypeSelectChange);
    $tbl.on("click", "tr", tableRowClick);
    $tbl.on("focus", "input[name='bmp-name']", function () {
      const $tr = $(this).closest("tr[data-id-bmp]");
      const dataIdBmp = $tr.attr("data-id-bmp");
      Tree.set("focusedInputName", dataIdBmp);
    });
  };

  var configureAddBmpsTableHandlers = function (newHandlers) {
    addBmpsTableHandlers = {
      newBmpClickEnabledUpdateHandler: newHandlers.newBmpClickEnabledUpdateHandler,
    };
  };

  var render = function (data) {
    $("#add-bmps-table-container").show();
    var bmpTypeOptions = bmpFcsInventoryConstants.bmpTypeOptionsNoAbbreviations;
    assignBmpTableIcons(data);
    addRenderingProps(data);

    var html = nunjucks.render("table/inventory/addBmpsTableRows.njk", {
      data,
      bmpTypeOptions,
    });

    $("#add-bmps-table tbody").html(html);

    $(".add-bmp-table-arrow-button").off();
    $(".add-bmp-table-arrow-button").on("click", hideRowsSwitchIcon);

    checkEmptyBmpName();
    if (savedSelectedIdBmp) {
      highlightTableAndOpenPopup(savedSelectedIdBmp);
    }
  };

  var assignBmpTableIcons = function (bmps) {
    bmps.forEach(function (bmp) {
      bmp.tableIcon = BmpFcsIcon.getTableIcon(
        bmp.bmpScore,
        bmp.bmpTypeObj.isCentralized,
        bmp.isFcs,
        bmp.bmpObservationDue,
        bmp.phase,
      );
    });
  };

  var hideRowsSwitchIcon = function () {
    $(".add-bmp-table-arrow-button > img").remove();
    $(".add-bmp-table-arrow-button").prepend(
      '<img class="add-bmps-table-arrows" src="/ram/images/assetManagement/summary_button.png">',
    );
    $(".add-bmp-table-arrow-button")
      .addClass("add-bmp-table-up-arrow-button")
      .removeClass("add-bmp-table-arrow-button");
    $(".add-bmps-table-rows").hide();
    removeTableArrowListeners();
    $(".add-bmp-table-up-arrow-button").on("click", showRowsSwitchIcon);
  };

  var showRowsSwitchIcon = function () {
    $(".add-bmp-table-up-arrow-button > img").remove();
    $(".add-bmp-table-up-arrow-button").prepend(
      '<img class="add-bmps-table-arrows" src="/ram/images/assetManagement/up_arrow.png">',
    );
    $(".add-bmp-table-up-arrow-button")
      .addClass("add-bmp-table-arrow-button")
      .removeClass("add-bmp-table-up-arrow-button");
    $(".add-bmps-table-rows").show();
    removeTableArrowListeners();
    $(".add-bmp-table-arrow-button").on("click", hideRowsSwitchIcon);
  };

  var removeTableArrowListeners = function () {
    $(".add-bmp-table-up-arrow-button").off();
    $(".add-bmp-table-arrow-button").off();
  };

  var addRenderingProps = function (data) {
    data.forEach((item) => {
      item.readOnly = !AddBmpsMap.bmpIsEditable(item);
    });
  };

  var clearAddBmpsTable = function () {
    $("#add-bmps-table tbody").empty();
  };

  // @TODO do this via css
  var setAddBmpsTableHeight = function () {
    const tableHeight = $("#inventory-modal").find(".modal-body").height();
    $("#add-bmps-table-container").css("max-height", tableHeight - 215 + "px");
  };

  var deleteBmpClick = function () {
    const data = $(this).closest("tr").data();
    const idBmp = data.idBmp;

    if (layerModule.canDisassociateBmp(idBmp)) {
      MessageModal.showThreeButtonConfirmWarningModal(
        null,
        () => removeBmpDataByIdBmp(idBmp, "delete"),
        () => removeBmpDataByIdBmp(idBmp, "disassociate"),
        "Cancel",
        "Delete Asset",
        "Disassociate Asset",
        nunjucks.render("dynamicModals/deleteAssociatedBMPMessage.njk", {
          bmpName: data.bmpName,
        }),
      );
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        () => removeBmpDataByIdBmp(idBmp, "delete"),
        "Cancel",
        "Delete Asset",
        nunjucks.render("dynamicModals/deleteBMPMessage.njk", {
          bmpName: data.bmpName,
        }),
      );
    }
  };

  var removeBmpDataByIdBmp = function (idBmp, action) {
    layerModule.removeBmpDataByIdBmp(idBmp, { action });
    $(".modal-dynamic-content").hide();
  };

  var bmpTypeSelectChange = function () {
    var value = $(this).val();
    var idBmp = $(this).closest("tr").data("idBmp");

    updateBmpTypeByIdBmp(idBmp, value);
  };

  var bmpNameInput = function () {
    var value = $(this).val();
    var data = $(this).closest("tr").data();
    var idBmp = data["idBmp"];
    var bmpName = String(data["bmpName"]);

    if (value !== bmpName) {
      checkUniqueBmpName(idBmp, value);
    }
  };

  var checkUniqueBmpName = function (idBmp, value) {
    if (value.length) {
      const groupId = Tree.get("activeGroup", "groupId");

      var uniqueCallback = function (isUniqueInDatabase) {
        var isUniqueInTemp = checkIsUniqueInTemp(idBmp, value);

        if (isUniqueInDatabase && isUniqueInTemp) {
          updateBmpNameByIdBmp(idBmp, value);
        } else {
          MessageModal.showSimpleWarningModal(
            "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
            true,
          );
          updateBmpNameByIdBmp(idBmp, "");
          checkEmptyBmpName();
        }
      };

      (Tree.get("tool") === "esg" ? EsgApiCalls : ApiCalls).checkUniqueBmpName(
        groupId,
        value,
        uniqueCallback,
      );
    } else {
      disableNewBmpClick();
      updateBmpNameByIdBmp(idBmp, value);
    }
  };

  var checkIsUniqueInTemp = function (idBmp, newValue) {
    var currentData = layerModule.getCurrentData();
    for (let i = 0; i < currentData.length; i++) {
      const datum = currentData[i];
      if (datum.idbmp !== idBmp && datum.bmpName === newValue) {
        return false;
      }
    }
    return true;
  };

  var updateBmpTypeByIdBmp = function (idBmp, value) {
    const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
    var newData = {
      bmpTypeUpdated: true,
      bmpTypeObj: {
        sortOrder: value,
        id: value,
        abbreviation: BmpFcsFunctions.getBmpTypeAbbr(value),
        name: BmpFcsFunctions.getBmpTypeNameByNumber(value),
        isCentralized: BmpFcsFunctions.isCentralizedBMPType(value),
      },
    };
    layerModule.updateDataByIdBmp(idBmp, newData);
  };

  var updateBmpNameByIdBmp = function (idBmp, value) {
    var newData = {
      bmpNameUpdated: true,
      bmpName: value,
    };
    layerModule.updateDataByIdBmp(idBmp, newData);
  };

  var checkEmptyBmpName = function () {
    var hasEmptyBmpName = getHasEmptyBmpName();
    if (hasEmptyBmpName) {
      disableNewBmpClick();
    } else {
      enableNewBmpClick();
    }
  };

  var getHasEmptyBmpName = function () {
    var hasEmptyValue = false;
    $tbl.find("tr").each(function () {
      const value = $(this).find("input[name='bmp-name']").val();
      if (value === "") {
        hasEmptyValue = true;
      }
    });
    return hasEmptyValue;
  };

  var enableNewBmpClick = function () {
    addBmpsTableHandlers.newBmpClickEnabledUpdateHandler(true);
  };

  var disableNewBmpClick = function () {
    addBmpsTableHandlers.newBmpClickEnabledUpdateHandler(false);
  };

  var tableRowClick = function (e) {
    var idBmp = $(this).data("idBmp");
    var bmpName = $(this).data("bmpName");
    var abbreviation = $(this).data("bmpAbbreviation");
    if (idBmp) {
      savedSelectedIdBmp = idBmp;
      highlightTableAndOpenPopup(idBmp);
    }
    showAssetsPage(idBmp, bmpName, abbreviation);
  };

  var highlightTableRow = function (idBmp) {
    var $addBmpsTable = $("#add-bmps-table");
    removeTableHighlight();
    $addBmpsTable.find(`tr[data-id-bmp="${idBmp}"]`).addClass("selected");
    const bmpName = $addBmpsTable.find(`tr[data-id-bmp="${idBmp}"]`).data("bmpName");
    const abbreviation = $addBmpsTable.find(`tr[data-id-bmp="${idBmp}"]`).data("bmpAbbreviation");

    showAssetsPage(idBmp, bmpName, abbreviation);
  };

  var showAssetsPage = function (idBmp, bmpName, abbreviation) {
    if (idBmp && bmpName && layerModule.canEditBmpOfType(abbreviation)) {
      $(".assets-page").hide();
      $(`.assets-page[data-id-bmp="${idBmp}"]`).show();
    }
  };

  var removeTableHighlight = function () {
    $("#add-bmps-table").find("tr.selected").removeClass("selected");
  };

  var highlightTableAndOpenPopup = function (idBmp) {
    var marker = layerModule.getMarkerReference(idBmp);
    if (marker) marker.openPopup();
    highlightTableRow(idBmp);
  };

  var resetSavedSelectedIdBmp = function () {
    savedSelectedIdBmp = null;
  };

  var scrollToAndHighlightTableRow = function (idBmp) {
    const Table = require("./table");

    var $container = $("#add-bmps-table-container");
    var $tableRow = $container.find(`tr[data-id-bmp="${idBmp}"]`);

    Table.scrollTo($container, $tableRow);
    highlightTableRow(idBmp);
  };

  return {
    init,
    configureAddBmpsTableHandlers,
    clearAddBmpsTable,
    setAddBmpsTableHeight,
    render,
    scrollToAndHighlightTableRow,
    removeTableHighlight,
    resetSavedSelectedIdBmp,
  };
};
module.exports = AddBmpsTable();

const ApiCalls = require("../apiCalls");
const EsgApiCalls = require("../esg/esgApiCalls");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const AddBmpsMap = require("./addBmpsMap");
const bmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const LayerFunctions = require("../layerFunctions");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
