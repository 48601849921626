"use strict";

function FacilitySelfInspectionController() {
  const htmlPath = "modals/facilitySelfInspection";
  const formKey = "facility-self-inspection";

  let $modal;
  let initialData;
  let initialFormData;

  async function loadNew(facilityId, readOnly = false) {
    initialData = await ApiCalls.getNewFacilitySelfInspection(facilityId);

    _showModal(readOnly);
  }

  function _showModal(readOnly) {
    initialFormData = {
      selfInspection: {
        letterName: Session.getUser().full_name,
      },
    };
    _showTabbedModal(readOnly);
    loadListeners();
    setAdditionalModalHandlers();
    _renderUpdates();
  }

  function _showTabbedModal(readOnly) {
    const tabs = [FacilitySelfInspectionPage];
    const renderData = _mapTabProps(tabs);
    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, {
      readOnly,
      extraButtons: [
        { text: "Preview", type: "primary", callback: downloadPreviewPdf, class: "preview-button" },
      ],
    });
    TabbedModal.setHeaderText(
      nunjucks.render(`${htmlPath}/facilitySelfInspectionHeader.njk`, renderData),
    );
  }

  function _mapTabProps(tabs) {
    const data = getAllData();

    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...initialFormData,
      ...tabProps,
      base: Config.get().apiUrl,
    };
  }

  function loadListeners() {
    Form.initializeAndLoadListeners($modal, formKey);
    FormSettings.customizeForm($modal, formKey);
    Form.manuallySetFormDataField(formKey, [], initialFormData);
    $modal.on("2N:FormInput", _renderUpdates);
  }

  function unloadListeners() {
    $modal.off("2N:FormInput", _renderUpdates);
    Form.clearForm(formKey);
  }

  function setAdditionalModalHandlers() {
    TabbedModal.setCancelHandler(unloadListeners);
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  }

  async function saveButtonClick() {
    if (await confirmSave()) {
      return await saveAndCloseModal();
    }

    return false;
  }

  async function confirmSave() {
    const actionState = getCurrentActionState();
    try {
      await MessageModal.showConfirmWarningModalAsPromise(actionState.saveWarning, {
        okMessage: actionState.saveText,
        returnMessage: "Go Back",
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async function saveAndCloseModal() {
    try {
      await Form.getReadyToSavePromise(formKey);
      const uploadData = getUploadData();
      await ApiCalls.createFacilitySelfInspection(uploadData);
      TabbedModal.hide();
      unloadListeners();
      IndcomFacilityGeoServerLayer.invalidateLayerData();
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return true;
  }

  function getUploadData() {
    const updates = Form.getDataFromForm(formKey, false);
    updates.action = getCurrentAction();

    return {
      updates: updates,
      inspectionId: initialData.inspection.id,
      selfInspectionId: initialData.selfInspectionReceived?.id,
    };
  }

  function formHasUpdates() {
    return !Form.isFormEmpty(formKey);
  }

  function getAllData() {
    return $.extend(true, {}, initialData, Form.getDataFromForm(formKey, false));
  }

  function setFormData(path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  }

  function _getRemainingRequiredFields(requiredFields) {
    const data = getAllData();
    const locationObservationResponses = (data.locationObservationResponses ?? []).filter(
      (finding) => finding.compliant,
    );

    return requiredFields.filter(function (field) {
      if (field === "locationObservationResponses") {
        if (!data.selfInspectionReceived) {
          return false;
        }

        return locationObservationResponses.length !== initialData.locationObservations.length;
      }

      return !Form.getDataAtPath(data, Form.getPathPartsFromName(field));
    });
  }

  function _renderUpdates() {
    const actionState = getCurrentActionState();
    _updateSaveButton(actionState);
    _toggleFollowUpSection(actionState);
    _renderReviewSummary();
  }

  function _updateSaveButton(actionState) {
    const isDisabled = _getRemainingRequiredFields(actionState.requiredFields).length > 0;
    TabbedModal.disableSaveButton(isDisabled);
    TabbedModal.setSaveButtonText(actionState.saveText);

    const $previewButton = $modal.find(".preview-button");
    $previewButton.toggle(actionState.showPreview);
    $previewButton.prop("disabled", isDisabled);
  }

  function _toggleFollowUpSection(actionState) {
    $modal.find(".js-follow-up").toggle(actionState.showFollowUp);
  }

  function getCurrentActionState() {
    const action = getCurrentAction();
    return getActionState(action);
  }

  function getCurrentAction() {
    const data = getAllData();
    const locationObservationsInfo = getCurrentLocationObservationsInfo(data);

    if (data.action) {
      return data.action;
    } else if (!data.selfInspectionReceived) {
      return "unresponsive";
    } else if (locationObservationsInfo.allCorrected) {
      return "respond-approve";
    } else {
      return "respond-reject";
    }
  }

  function getActionState(action) {
    const status = {
      "respond-approve": {
        requiredFields: ["locationObservationResponses"],
        saveText: "Save & Approve",
        saveWarning: `This will close all open findings associated with this facility. `,
        showPreview: false,
        showFollowUp: false,
      },
      "respond-reject": {
        requiredFields: [
          "locationObservationResponses",
          `selfInspection[followUpInspectionType]`,
          `selfInspection[dueDate]`,
          `selfInspection[letterName]`,
          `selfInspection[signature]`,
        ],
        saveText: "Reject & Generate",
        saveWarning: `Clicking “Reject & Generate” will save the information entered and generate a PDF letter with a new Collaboration Portal response link for this facility. ${getPdfSaveEndText()}`,
        showPreview: true,
        showFollowUp: true,
      },
      unresponsive: {
        requiredFields: [
          "locationObservationResponses",
          `selfInspection[followUpInspectionType]`,
          `selfInspection[dueDate]`,
          `selfInspection[letterName]`,
          `selfInspection[signature]`,
        ],
        saveText: "Save & Generate",
        saveWarning: `Clicking “Save & Generate” will save the information entered and generate a PDF letter with a new Collaboration Portal response link for this facility. ${getPdfSaveEndText()}`,
        showPreview: true,
        showFollowUp: true,
      },
    }[action];

    if (!status) {
      throw new Error(`Invalid action state ${action}`);
    }

    status.action = action;

    return status;
  }

  function getCurrentLocationObservationsInfo(data) {
    const locationObservations = data.locationObservations ?? [];
    const totalCount = locationObservations.length;
    const locationObservationResponses = data.locationObservationResponses ?? [];
    const correctedCount = locationObservations.filter(
      (finding) =>
        locationObservationResponses[finding.id]?.compliant === "yes" &&
        finding.compliant === "yes",
    ).length;

    return {
      correctedCount,
      notCorrectedCount: totalCount - correctedCount,
      totalCount,
      allCorrected: correctedCount === totalCount,
    };
  }

  function getPdfSaveEndText() {
    if (Session.notificationEnabled("pdf-letters")) {
      return "The PDF will be emailed to you shortly.";
    }

    return "The PDF will be available from the facility history shortly.";
  }

  function downloadPreviewPdf() {
    InspectionPreview.preview(async function () {
      await Form.getReadyToSavePromise(formKey);
      return getUploadData();
    }, ApiCalls.previewFacilitySelfInspectionPdf);
  }

  function _renderReviewSummary() {
    const data = getAllData();
    const locationObservationsInfo = getCurrentLocationObservationsInfo(data);
    $modal
      .find(".js-review-summary")
      .html(nunjucks.render(`${htmlPath}/pageReviewSummarySection.njk`, locationObservationsInfo));
  }

  function getInitialFormDataObject() {
    return initialFormData;
  }

  return {
    loadNew,
    getAllData,
    saveAndCloseModal,
    setFormData,
    getCurrentActionState,
    getCurrentLocationObservationsInfo,
    getInitialFormDataObject,
  };
}

module.exports = FacilitySelfInspectionController();

const Config = require("../../config");
const Form = require("../../general/form");
const FormSettings = require("../../settings/formSettings");
const TabbedModal = require("../../general/tabbedModal");
const UploadValidator = require("../../general/uploadValidator");
const ApiCalls = require("../../apiCalls");
const FacilitySelfInspectionPage = require("./facilitySelfInspectionPage");
const MessageModal = require("../../modals/messageModal");
const IndcomFacilityGeoServerLayer = require("../indcomFacilityGeoServerLayer");
const InspectionPreview = require("../../general/inspectionPreview");
const Session = require("../../login/session");
