"use strict";

const MuniOpenChannelPopup = function () {
  const popups = {};

  var getPopupHtml = function (properties) {
    properties.permissions = UserPermissions.getAllPermissions();
    return PopupContent.getLayerPopup(properties, "muniOpenChannel");
  };

  var onPopupOpen = async function (properties) {
    const data = await getPopupData(properties.id);
    const html = getPopupHtml(data);
    $(".leaflet-popup-content").html(html);

    const layerName = "muniOpenChannel";
    const layerModule = LayerFunctions.getLayerModule(layerName);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteOpenChannel(data.id, data.name, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteOpenChannel = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Open Channel ${name}? Click "Delete" to permanently delete the Open Channel. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteOpenChannel(id);
        invalidateLayerDataFunction();
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "openn-channels");
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var getPopupData = async function (id) {
    if (popups[id]) {
      return popups[id];
    }
    const popupData = await ApiCalls.getMuniOpenChannelPopup(id);
    popupData.icon = RoutineMaintenanceIcon.getMaintenanceIcon(
      "muni-open-channel",
      popupData.lastMaintenanceInspection,
      popupData.lastMaintenanceInspection?.needsRepair,
    );
    popupData.displayWidthUnits = UnitConversion.getUnitAbbreviation(popupData.widthUnits);
    // eslint-disable-next-line require-atomic-updates
    popups[id] = popupData;
    return popupData;
  };

  var getPopupLatLng = async function (id) {
    const data = await getPopupData(id);
    return data?.midpointLatLng;
  };

  return {
    getPopupHtml,
    onPopupOpen,
    getPopupLatLng,
  };
};

module.exports = MuniOpenChannelPopup();

const ApiCalls = require("../../apiCalls");
const PopupContent = require("../../mapFunctions/popupContent");
const MessageModal = require("../../modals/messageModal");
const LayerFunctions = require("../../layerFunctions");
const UserPermissions = require("../../login/userPermissions");
const Analytics = require("../../general/analytics");
const RoutineMaintenanceIcon = require("../../general/routineMaintenanceIcon");
const UnitConversion = require("../../unitConversion");
