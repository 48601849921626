"use strict";

const ProjectInventoryModalController = function () {
  const projectInventoryFormKey = "project-inventory";

  var loadFormListener = function () {
    unloadFormListener();
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  };

  var unloadFormListener = function () {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
  };

  var onFormInput = function (e, value, path) {
    checkEnableSaveButton(value, path);

    setModalTitle();
  };

  var getProjectInventoryData = async function (maybeId) {
    var processedData = {
      priorityInfo: {},
      permitInfo: {},
      lidInfo: {},
      locationInfo: {},
      permitsAndPlans: [],
      stageData: {},
      treatmentTypes: null,
      bmps: [],
    };

    if (maybeId) {
      const data = await ApiCalls.getConstructionProjectInventory(maybeId);
      processedData = processReturnedData(data);
    }

    return processedData;
  };

  var invokeProjectInventoryModal = async function (maybeId, startingPage, options) {
    const processedData = await getProjectInventoryData(maybeId);
    InventoryModal.setOpenInventory(projectInventoryFormKey);
    return initializeProjectInventoryModalPages(processedData, startingPage, options);
  };

  var reloadInventoryModal = function (startingPage, options) {
    if (options?.data?.id) {
      setUpdatedBmpData(options.data);
      setUpdatedTreatmentTypeData(options.data);
    }
    const existingData = Tree.get("asset", projectInventoryFormKey);
    InventoryModal.setOpenInventory(projectInventoryFormKey);
    InventoryModal.setHideInventoryOnClose(true);
    InventoryModal.restoreAllButtons();
    return initializeProjectInventoryModalPages(existingData, startingPage, options);
  };

  var setUpdatedTreatmentTypeData = function (newData) {
    const assets = getExistingConstructionData("treatmentTypes");
    const index = assets?.findIndex((asset) => asset?.associatedAsset == newData.id);
    if (index !== -1) {
      Tree.set(
        ["asset", projectInventoryFormKey, "treatmentTypes", index, "footprintSquareFeet"],
        newData.footprintSqft,
      );
      Tree.set(
        ["asset", projectInventoryFormKey, "treatmentTypes", index, "capacityAcreFeet"],
        newData.treatmentCapacityAcreFeet,
      );
      Tree.set(
        ["asset", projectInventoryFormKey, "treatmentTypes", index, "treatedImperviousAreaAcres"],
        newData.imperviousAreaAcres,
      );
    } else {
      throw new Error(`Index of associated asset ${newData.id} not found`);
    }
  };

  var setUpdatedBmpData = function (newData) {
    const bmps = getExistingConstructionData("bmps");
    const index = bmps?.findIndex((bmp) => bmp?.id == newData.id);
    if (index !== -1) {
      Tree.set(["asset", projectInventoryFormKey, "bmps", index], newData);
    } else {
      throw new Error(`Index of associated asset ${newData.id} not found`);
    }
  };

  var getLatestConstructionData = function (path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(projectInventoryFormKey, path);
  };

  var getAllConstructionData = function () {
    return InventoryModal.getAllData(projectInventoryFormKey);
  };

  var getExistingConstructionData = function (path) {
    path = Array.isArray(path) ? path : [path];
    return Tree.get(["asset", projectInventoryFormKey, ...path]);
  };

  var processReturnedData = function (data) {
    const geoGeom = JSON.parse(data.geom);
    data.longitude = geoGeom.coordinates[0];
    data.latitude = geoGeom.coordinates[1];
    data.selectedCatchments = [data.catchmentId];
    data.areaPolygons = data.areaPolygons.map((polygon) => {
      return {
        id: polygon.id,
        apn: polygon.apn,
        percent: polygon.percent,
        catch_name: polygon.catchid,
        polygon_id: polygon.polygonId,
        acres: polygon.acres,
      };
    });
    data.areaPolygons.forEach((polygon) => {
      if (!data.selectedCatchments.includes(polygon.catch_name)) {
        data.selectedCatchments.push(polygon.catch_name);
      }
    });
    data.stageData = ProjectStageData.getRenderStageData(data.stageData);
    return data;
  };

  var initializeProjectInventoryModalPages = async function (
    processedData,
    startingPage,
    options = {},
  ) {
    const pageOptions = {
      existingLocation: !!(processedData || {}).projectIdentifier,
      ...options,
    };
    Tree.set(["asset", projectInventoryFormKey], processedData);
    resetAllPages();

    const inputPages = getConstructionInventoryPages(processedData);

    await InventoryModal.showModal(inputPages, startingPage, pageOptions, {
      saveAndCloseHandler: clickSave,
      cancelHandler: resetInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
    });
    InventoryModal.disableSaveButton();
    InventoryModal.resetModalTitle();
    setModalTitle();
    if (processedData.projectIdentifier !== undefined) {
      InventoryModal.allowedSaveState(true);
    }
    loadFormListener();
  };

  var resetAllPages = function () {
    const allPages = [
      ProjectInventorySetLocation,
      ProjectInventoryAreaSelection,
      ProjectInventoryGeneralInfo,
      ProjectInventoryPermitInfo,
      ProjectInventoryContacts,
      ProjectInventoryUsers,
      ProjectInventoryFiles,
      ProjectInventoryAddBmps,
      ProjectInventoryPriority,
    ];

    allPages.forEach((page) => {
      if (page.resetState) {
        page.resetState();
      }
    });
  };

  var getConstructionInventoryPages = function (processedData) {
    if (Tree.get("dataView") === "construction-project-delivery") {
      const pages = [
        ProjectInventorySetLocation,
        ProjectInventoryAreaSelection,
        ProjectInventoryGeneralInfo,
        ProjectInventoryContacts,
        ProjectInventoryUsers,
        ProjectInventoryFiles,
        ProjectInventoryAddBmps,
      ];

      return pages;
    } else {
      const pages = [
        ProjectInventorySetLocation,
        ProjectInventoryAreaSelection,
        ProjectInventoryGeneralInfo,
        ProjectInventoryPermitInfo,
        ProjectInventoryAddBmps,
        ProjectInventoryAssetDetails,
        ProjectInventoryContacts,
        ProjectInventoryUsers,
        ProjectInventoryFiles,
        ProjectInventoryPriority,
      ];

      return pages;
    }
  };

  var renderPageContent = function (html) {
    InventoryModal.setModalDynamicContent(html);
  };

  var setModalTitle = function () {
    const projectIdentifier = getLatestConstructionData("projectIdentifier");
    if (projectIdentifier === undefined || projectIdentifier === "") {
      InventoryModal.setModalTitle("Add new project");
    } else {
      InventoryModal.setModalTitle("Project ID: ", projectIdentifier);
    }

    setProjectPhase();
  };

  var setProjectPhase = function () {
    const data = getAllConstructionData();
    const phase = getPhase(data);

    setFormDataFieldIfDifferentFromInitial("phase", phase);

    const displayPhase = ConstructionProjectStyles.getDisplayPhase(phase);
    InventoryModal.setModalTitle("Project Phase: ", displayPhase, "phase");
  };

  var _saveOrWarnInvalid = function (fields) {
    if (fields.length > 0) {
      Tree.set("hasSaved", true);
      showSaveAndWarnModal(fields);
      highlightInvalidFields(fields);
    } else {
      saveAndCloseModal();
    }
  };

  var showSaveAndWarnModal = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    const messages = fields.map((field) => field.message);
    const uniqueMessages = new Set(messages);
    const projectIdentifier = getLatestConstructionData("projectIdentifier");
    const incompleteFields = {
      entityName: [projectIdentifier],
      entityKind: "Inventory",
      incompleteFieldNames: [...uniqueMessages],
    };

    if (fieldNames.includes("project-start-date") || fieldNames.includes("project-end-date")) {
      SaveIncompleteModal.showWarnSaveIncompleteModal(
        incompleteFields.entityName,
        incompleteFields.entityKind,
        incompleteFields.incompleteFieldNames,
        "The project cannot be saved without this data given the selected project phase.",
      );
    } else {
      SaveIncompleteModal.showConfirmSaveIncompleteModal(
        incompleteFields.entityName,
        incompleteFields.entityKind,
        incompleteFields.incompleteFieldNames,
        saveAndCloseModal,
        InventoryModal.restoreAllButtons,
        true,
      );
    }
  };

  var highlightInvalidFields = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    CommonModalFunctions.highlightFormFields($("#inventory-modal"), fieldNames);
  };

  var checkForMissingRequiredFields = function () {
    const fields = FormValidator.getRequireNotBlankFields();

    const bmps = Tree.get("layers", "projectAssociatedBmp", "dataModal") || [];
    bmps.forEach((bmp) => {
      if (bmp.bmpTypeObj?.isCentralized) {
        if (
          bmp.drainageAreaAcres === null ||
          (bmp.drainageAreaAcres !== null && isNaN(bmp.drainageAreaAcres))
        ) {
          fields.push("bmp_drainarea");
        }
      }
    });

    addMissingRequiredDateFields(fields);

    const formatted = ProjectInventoryConstants.getFieldNamesAndMessage(fields);
    _saveOrWarnInvalid(formatted);
  };

  var addMissingRequiredDateFields = function (missingFields) {
    const project = getAllConstructionData();
    if (project.phase === "construction") {
      if (!project.projectStartDate) {
        missingFields.push("project-start-date");
      }
    } else if (project.phase === "completed") {
      if (!project.projectStartDate) {
        missingFields.push("project-start-date");
      }
      if (!project.projectEndDate) {
        missingFields.push("project-end-date");
      }
    }
  };

  var setRadiosToDefaults = function (bmps) {
    bmps.forEach((bmp) => {
      if (!bmp.diversion) {
        bmp.diversion = "false";
      }
      if (!bmp.bmp_flow) {
        bmp.bmp_flow = "false";
      }
      if (!bmp.drainsout) {
        bmp.drainsout = "false";
      }
    });
  };

  var handleAssetsCleanUp = function () {
    Tree.set("hasSaved", false);
    $("#add-bmps-table-container").hide();
    unloadFormListener();
  };

  var clickSave = function () {
    return new Promise((resolve) => {
      checkForMissingRequiredFields();
      resolve(false);
    });
  };

  var removeCommasFromNumberString = function (numberString) {
    if (typeof numberString === "number") {
      return numberString;
    } else {
      return numberString.replace(/,/g, "");
    }
  };

  var saveAndCloseModal = async function () {
    const dataView = Tree.get("dataView");
    try {
      var projectToUpload = Form.getDataFromForm(projectInventoryFormKey);
      _processProjectFormData(projectToUpload);
      _addManualAreaData(projectToUpload);
      if (projectToUpload.bmps) {
        processAddedBmpsUnitConversion(projectToUpload.bmps);
        processNumberStringsAddedBmps(projectToUpload.bmps);
        _addAddressToBmps(projectToUpload.bmps);
      }

      DrawController.hideDrawLayer();
      DrawController.resetDrawnItemsLayer();
      if (getExistingConstructionData("id") !== undefined) {
        const projectId = getExistingConstructionData("id");
        const data = Form.toFormData({
          constructionProjectId: projectId,
          constructionProjectUpdates: projectToUpload,
        });

        const responseData = await ApiCalls.updateConstructionProject(data);
        refreshAndHideProjectInventoryModal();
        handleAssetsCleanUp();
        reopenPopupForUpdatedData(dataView, projectId);
        LidProjectFactSheetController.reload(responseData);
      } else {
        if (projectToUpload.bmps) {
          setRadiosToDefaults(projectToUpload?.bmps);
        }
        setYesNoCheckboxDefaults(projectToUpload);
        setPriorityDefault(projectToUpload);
        const data = Form.toFormData({ constructionProject: projectToUpload });
        await ApiCalls.createConstructionProject(data);
        refreshAndHideProjectInventoryModal();
        handleAssetsCleanUp();
      }
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  };

  var _addAddressToBmps = function (bmps) {
    const projectAddress = getExistingConstructionData("streetAddress");
    if (projectAddress) {
      bmps.forEach((bmp) => {
        if (!bmp.address || bmp.address === "") {
          bmp.address = projectAddress;
        }
      });
    }
  };

  var _processProjectFormData = function (projectToUpload) {
    const allData = getAllConstructionData();
    processAreaSelection(projectToUpload);
    if (projectToUpload.contacts !== undefined) {
      cleanContactIds(projectToUpload);
    }
    if (projectToUpload.users !== undefined) {
      cleanUserIds(projectToUpload);
    }
    if (projectToUpload.permitsAndPlans !== undefined) {
      cleanPermitsAndPlansIds(projectToUpload);
      removeUploadOrLocation(projectToUpload);
    }
    if (projectToUpload.areaPolygons !== undefined) {
      cleanAreaPolygons(projectToUpload);
    }
    if (projectToUpload.bmps !== undefined) {
      addBmpIdFromExisting(projectToUpload, allData.bmps);
      cleanBmpTypeFields(projectToUpload);
    }

    ProjectTreatmentTypes.prepareTreatmentTypeUploadData(projectToUpload, allData);
  };

  var _addManualAreaData = function (projectToUpload) {
    if (
      projectToUpload.priorityInfo?.manualArea === undefined &&
      projectToUpload.priorityInfo?.manualAreaUnits === undefined &&
      projectToUpload.areaManually === undefined
    )
      return;
    const data = getAllConstructionData();
    projectToUpload.priorityInfo ||= {};
    if (projectToUpload.areaManually === undefined)
      projectToUpload.areaManually = data.areaManually;
    projectToUpload.priorityInfo.manualArea ||= data.priorityInfo.manualArea;
    projectToUpload.priorityInfo.manualAreaUnits ||= data.priorityInfo.manualAreaUnits || "acres";
  };

  var addBmpIdFromExisting = function (projectToUpload, existingBmps) {
    projectToUpload.bmps.forEach((bmp, index) => {
      // Deals with Updates to Non-existing Bmps
      if (!bmp.bmp && bmp.idbmp === undefined) {
        bmp.idbmp = existingBmps[index].idbmp;
      }
    });
  };

  var cleanBmpTypeFields = function (projectToUpload) {
    projectToUpload.bmps.forEach((bmp) => {
      if (bmp.bmp) {
        delete bmp.bmpTypeName;
        delete bmp.bmpTypeAbbr;
      }
    });
  };

  var processAreaSelection = function (projectToUpload) {
    const drawMethod = DrawController.getSelectedMethod();
    if (drawMethod === "drawing-tools" && DrawController.getDrawnItems()) {
      projectToUpload.drawMethod = drawMethod;
      const polygons = DrawController.getDrawnDrainageGeom();
      if (polygons && polygons.length > 0) {
        projectToUpload.drawnGeom = polygons;
      }
    }
  };

  var cleanAreaPolygons = function (projectToUpload) {
    const reducedAreaPolygons = projectToUpload.areaPolygons.map((polygon) => {
      return { id: polygon.id, percent: polygon.percent };
    });
    projectToUpload.areaPolygons = reducedAreaPolygons;
  };

  var cleanContactIds = function (projectToUpload) {
    const contacts = projectToUpload.contacts;
    const existingContactIds = (getExistingConstructionData("contacts") || []).map((contact) => {
      return contact.id;
    });

    Misc.appendExistingAttribute(existingContactIds, contacts, "id");
    Misc.removeAttributeValues(existingContactIds, contacts, "id");
    projectToUpload.contacts = Object.values(contacts);
  };

  var cleanUserIds = function (projectToUpload) {
    const users = projectToUpload.users;
    const existingUserIds =
      getExistingConstructionData("users")?.map((user) => {
        return user.id;
      }) ?? [];

    Misc.appendExistingAttribute(existingUserIds, users, "id");
    Misc.removeAttributeValues(existingUserIds, users, "id");
    projectToUpload.users = Object.values(users);
  };

  var cleanPermitsAndPlansIds = function (projectToUpload) {
    const permitsAndPlans = projectToUpload.permitsAndPlans;
    const existingPermitsAndPlansIds = (getExistingConstructionData("permitsAndPlans") || []).map(
      (permitPlan) => {
        return permitPlan.id;
      },
    );

    Misc.appendExistingAttribute(existingPermitsAndPlansIds, permitsAndPlans, "id");
    Misc.removeAttributeValues(existingPermitsAndPlansIds, permitsAndPlans, "id");
    projectToUpload.permitsAndPlans = Object.values(permitsAndPlans);
  };

  var removeUploadOrLocation = function (projectToUpload) {
    return projectToUpload.permitsAndPlans.forEach((pp) => {
      if (pp.locationType === "upload") {
        pp.fileAccessDescription = "";
        pp.externalUrl = "";
      } else if (pp.locationType === "location") {
        pp.fileUploadLocation = "";
        pp.externalUrl = "";
      } else if (pp.locationType === "external-url") {
        pp.fileAccessDescription = "";
        pp.fileUploadLocation = "";
      }
    });
  };

  var setPriorityDefault = function (newProjectData) {
    if (newProjectData.highPriority === undefined) {
      newProjectData.highPriority = false;
    }
  };

  var setYesNoCheckboxDefaults = function (newProjectData) {
    if (newProjectData.isStartDateEstimate === undefined) {
      newProjectData.isStartDateEstimate = false;
    }
    if (newProjectData.isEndDateEstimate === undefined) {
      newProjectData.isEndDateEstimate = false;
    }
  };

  var refreshAndHideProjectInventoryModal = function () {
    const tool = Tree.get("tool");
    reloadTodos();
    if (tool === "construction" || tool === "projdelivery") {
      reloadConstructionProjectsAndAreaLayers();
      if (Tree.get(["factSheet", "open"]) && getExistingConstructionData("id") !== undefined) {
        const projectId = getExistingConstructionData("id");
        ProjectFactSheetController.initFactSheet(projectId);
      }
    } else if (tool === "lid") {
      LidProjectLayer.invalidateLayerData();
      PostConstructionProjectGeoServerLayer.invalidateLayerData();
      LidProjectAreaLayer.loadLayer();
      Actions.loadLidProjectBmps();
    }
    InventoryModal.hide();
  };

  var reopenPopupForUpdatedData = function (dataView = Tree.get("dataView"), projectId = null) {
    if (dataView === "construction-project") {
      ConstructionProjectGeoServerLayer.loadLayer();
      projectId = !projectId
        ? Tree.get(["layers", "constructionProject", "selectedId"])
        : projectId;
      if (projectId) {
        MapFunctions.openGeoServerPopup("constructionProjectG2", projectId);
      }
    }
    if (dataView === "lid-project") {
      if ($(".leaflet-popup-close-button")?.length) {
        $(".leaflet-popup-close-button")[0].click();
      }
      projectId = !projectId ? Tree.get(["layers", "lidProject", "selectedId"]) : projectId;
      Tree.get("activeTab") === "data"
        ? PostConstructionProjectGeoServerLayer.loadLayer()
        : LidProjectLayer.loadLayer("main");

      if (projectId) {
        MapFunctions.openGeoServerPopup("postConstructionProjectG2", projectId);
      }
    }
  };

  var reloadTodos = function () {
    if (Tree.get("activeTab") === "todo") {
      ToDoListController.loadTodosIfNecessary();
    }
  };

  var reloadConstructionProjectsAndAreaLayers = function () {
    ConstructionProjectLayer.loadLayer();
    ConstructionProjectGeoServerLayer.invalidateLayerData();
    ConstructionProjectAreaLayer.invalidateLayer();
  };

  var goToPage = function (stringKey, options) {
    InventoryModal.restoreButtonsToActive();
    InventoryModal.setActivePageByKey(stringKey, options);
  };

  var setFormDataField = function (path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(projectInventoryFormKey, path, value);
  };

  var setFormDataFieldIfDifferentFromInitial = function (path, value) {
    if (!Form.isInitialized(projectInventoryFormKey)) {
      return;
    }

    path = Array.isArray(path) ? path : [path];

    const initial = getExistingConstructionData(path);
    const changed = getChangedFormData(path);

    if (initial === value && typeof changed === typeof value) {
      unsetFormDataField(path);
    } else if (initial !== value && changed !== value) {
      setFormDataField(path, value);
    }
  };

  var unsetFormDataField = function (path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(projectInventoryFormKey, path);
    return Form.getDataFromForm(projectInventoryFormKey, false);
  };

  var getChangedFormData = function (path) {
    path = Array.isArray(path) ? path : [path];
    return Form.getFormDataAtPath(projectInventoryFormKey, path);
  };

  var resetInventory = function () {
    Form.clearForm(projectInventoryFormKey);
    DrawController.hideDrawLayer();
    DrawController.resetDrawnItemsLayer();
    unloadFormListener();
  };

  var formHasUpdates = function () {
    return !Object.keys(Form.getDataFromForm(projectInventoryFormKey, false)).length === 0;
  };

  function noUpdatesInForm() {
    return Object.keys(Form.getDataFromForm(projectInventoryFormKey, false)).length === 0;
  }

  var checkEnableSaveButton = function (value, path) {
    const existingValue = getExistingConstructionData(path);
    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(projectInventoryFormKey, path);
    }

    if (formHasUpdates()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  };

  var fetchPossibleInstallationInspectionTypes = async function (bmpType) {
    const types = await ApiCalls.getPossibleInstallationInspectionTypes(bmpType).then((result) => {
      return result;
    });
    return types;
  };

  var fetchPossibleRunoffOffsetProjects = async function (receivingWater, projectId) {
    const projects = await ApiCalls.getPossibleRunoffOffsetProjectsInReceivingWater(
      receivingWater,
      projectId,
    );
    setFormDataField(["possibleRelatedProjects"], projects);
  };

  var getProjectMarker = function (zoom) {
    const projectData = getAllConstructionData();
    const originalIconSize = true;
    const isTodo = Tree.get("activeTab") === "todo";

    projectData.zoom = zoom;
    return Tree.get("tool") === "construction" || Tree.get("tool") === "projdelivery"
      ? ConstructionIcon.getConstructionProjectIconMarker(projectData, originalIconSize)
      : LidIcon.getLidProjectMapIcon(projectData, isTodo);
  };

  var getPhase = function (data) {
    if (!data) {
      data = { stageData: {} };
    }

    if (!data.phase) {
      data.phase = getDefaultPhase();
    }

    return data.phase;
  };

  var getDefaultPhase = function () {
    const dataView = Tree.get("dataView");
    return dataView === "lid-project"
      ? "completed"
      : dataView === "construction-project-delivery"
        ? "planning"
        : "planned";
  };

  var isDrainageLayerDisabledInCurrentPage = function () {
    var activePageStringKey = InventoryModal.getActivePageStringKey();
    var pagesToDisableDrainageLayer = ["project-add-bmps"];
    return pagesToDisableDrainageLayer.includes(activePageStringKey);
  };

  var loadExistingProjectMarker = function (zoom) {
    const markerIcon = getProjectMarker(zoom);
    const latlng = {
      lat: getLatestConstructionData("latitude"),
      lng: getLatestConstructionData("longitude"),
    };
    const marker = new L.marker(latlng, {
      icon: markerIcon,
      zIndexOffset: 2000,
    });
    ModalMap.setSingleMarker(marker);
  };

  var processAddedBmpsUnitConversion = function (bmps) {
    handleTreatmentCapacityUnitConversion(bmps);
    handleFootprintUnitConversion(bmps);
    handleWetPoolCapacityUnitConversion(bmps);
  };

  var handleWetPoolCapacityUnitConversion = function (bmps) {
    bmps.forEach((bmp) => {
      if (bmp.bmp_wet) {
        if (bmp.wetPoolCapacityUnit === "acreFeet") {
          bmp.bmp_wet = UnitConversion.acreFeetToCubicFeet(bmp.bmp_wet);
          delete bmp.wetPoolCapacityUnit;
        } else {
          delete bmp.wetPoolCapacityUnit;
        }
      }
    });
  };

  var handleFootprintUnitConversion = function (bmps) {
    bmps.forEach((bmp) => {
      if (bmp.bmp_footprint) {
        if (bmp.footprintUnit === "acres") {
          bmp.bmp_footprint = UnitConversion.acresToSquareFeet(bmp.bmp_footprint);
          delete bmp.footprintUnit;
        } else {
          delete bmp.footprintUnit;
        }
      }
    });
  };

  var handleTreatmentCapacityUnitConversion = function (bmps) {
    bmps.forEach((bmp) => {
      if (bmp.bmp_wqcap) {
        if (bmp.treatmentCapacityUnit === "acreFeet") {
          bmp.bmp_wqcap = UnitConversion.acreFeetToCubicFeet(bmp.bmp_wqcap);
          delete bmp.treatmentCapacityUnit;
        } else if (bmp.treatmentCapacityUnit === "gallons") {
          bmp.bmp_wqcap = UnitConversion.gallonsToCubicFeet(bmp.bmp_wqcap);
          delete bmp.treatmentCapacityUnit;
        } else {
          delete bmp.treatmentCapacityUnit;
        }
      }
    });
  };

  var processNumberStringsAddedBmps = function (bmps) {
    bmps.forEach((bmp) => {
      [
        "bmp_wqcap",
        "bmp_footprint",
        "bmp_wet",
        "bmp_drawdown",
        "intake_rate",
        "bmp_trate",
        "bmp_irate",
        "bmpImparea",
        "flowLength",
      ].forEach((field) => {
        if (bmp[field]) {
          bmp[field] = removeCommasFromNumberString(bmp[field]);
        }
      });
    });
  };

  return {
    invokeProjectInventoryModal,
    renderPageContent,
    setModalTitle,
    goToPage,
    reloadConstructionProjectsAndAreaLayers,
    saveAndCloseModal,
    setFormDataField,
    unsetFormDataField,
    getChangedFormData,
    getLatestConstructionData,
    getProjectMarker,
    fetchPossibleInstallationInspectionTypes,
    fetchPossibleRunoffOffsetProjects,
    getPhase,
    isDrainageLayerDisabledInCurrentPage,
    _processProjectFormData,
    loadExistingProjectMarker,
    getProjectInventoryData,
    getExistingConstructionData,
    _addAddressToBmps,
    getConstructionInventoryPages,
    reloadInventoryModal,
    processAddedBmpsUnitConversion,
    handleTreatmentCapacityUnitConversion,
    removeCommasFromNumberString,
    reopenPopupForUpdatedData,
    getAllConstructionData,
  };
};

module.exports = ProjectInventoryModalController();

const Actions = require("../actions");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const Form = require("../general/form");
const ConstructionIcon = require("../construction/constructionIcon");
const ConstructionProjectLayer = require("./constructionProjectLayer");
const ConstructionProjectAreaLayer = require("./constructionProjectAreaLayer");
const ConstructionProjectStyles = require("./constructionProjectStyles");
const InventoryModal = require("../general/inventoryModal");
const LidIcon = require("../lid/lidIcon");
const LidProjectLayer = require("../lid/lidProjectLayer");
const LidProjectAreaLayer = require("../lid/lidProjectAreaLayer");
const Misc = require("../misc");
const ProjectInventorySetLocation = require("./projectInventorySetLocation");
const ProjectInventoryAreaSelection = require("./projectInventoryAreaSelection");
const ProjectInventoryGeneralInfo = require("./projectInventoryGeneralInfo");
const ProjectInventoryPermitInfo = require("./projectInventoryPermitInfo");
const ProjectInventoryContacts = require("./projectInventoryContacts");
const ProjectInventoryFiles = require("./projectInventoryFiles");
const ProjectInventoryAddBmps = require("./projectInventoryAddBmps");
const ProjectInventoryAssetDetails = require("./projectInventoryAssetDetails");
const ProjectInventoryPriority = require("./projectInventoryPriority");
const DrawController = require("../mapFunctions/drawController");
const ProjectFactSheetController = require("./projectFactSheetController");
const ProjectStageData = require("./projectStageData");
const ToDoListController = require("../mapFunctions/toDoListController");
const ModalMap = require("../mapFunctions/modalMap");
const ProjectTreatmentTypes = require("./projectTreatmentTypes");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const UploadValidator = require("../general/uploadValidator");
const FormValidator = require("../general/formValidator");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const SaveIncompleteModal = require("../modals/saveIncompleteModal");
const UnitConversion = require("../unitConversion");
const ProjectInventoryUsers = require("./projectInventoryUsers");
const MapFunctions = require("../mapFunctions/mapFunctions");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const PostConstructionProjectGeoServerLayer = require("../constructionG2/postConstructionProjectGeoServerLayer");
const LidProjectFactSheetController = require("../lid/lidProjectFactSheetController");
