"use strict";

const FacilityInspectionController = function () {
  const htmlPath = "modals/facilityInspection";
  const formKey = "facility-inspection";

  let $modal;
  let tabs;
  let dataStore;

  var loadNewFacilityInspection = async function (
    facilityId,
    { readOnly = false, defaultData = {} } = {},
  ) {
    var newDataStore;

    try {
      newDataStore = await ResourceController.get("facilityInspection").createNew(facilityId);
    } catch (e) {
      if (!(e instanceof DataStoreError)) {
        throw e;
      }

      return;
    }

    _setNewDefaultData(newDataStore, defaultData);
    _showFacilityInspectionModal(newDataStore, readOnly);
  };

  var _setNewDefaultData = function (newDataStore, defaultData) {
    const existingData = newDataStore.getWithUpdates();
    const updateData = newDataStore.getForm();

    for (const key in defaultData) {
      if (!(key in existingData)) {
        updateData[key] = defaultData[key];
      }
    }

    newDataStore.setForm(updateData);
  };

  var loadExistingFacilityInspection = async function (inspectionId, readOnly = false) {
    var newDataStore;

    try {
      newDataStore = await ResourceController.get("facilityInspection").openExisting(inspectionId);
    } catch (e) {
      if (!(e instanceof DataStoreError)) {
        throw e;
      }

      return;
    }
    _showFacilityInspectionModal(newDataStore, readOnly);
  };

  var _showFacilityInspectionModal = function (newDataStore, readOnly) {
    dataStore = newDataStore;
    tabs = [
      FacilityInspectionGeneralInfo,
      FacilityInspectionOpenFindings,
      FacilityInspectionSiteDocuments,
      FacilityRequirements,
      FacilityInspectionSubmission,
    ];

    const renderData = mapTabProps(tabs);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });
    TabbedModal.setHeaderText(getHeaderHtml(renderData));
    Form.initializeAndLoadListeners($modal, formKey, { dataStore });
    FormSettings.customizeForm($modal, formKey);
    loadListeners();
    setAdditionalModalHandlers();
    onChangeInspectionType();
    selectInspectionTypeCdot();
  };

  var loadListeners = function () {
    unloadListeners();
    $modal.on("2N:FormInput", ".compliant input", rerenderSummary);
    $modal.on("2N:FormInput", "[name='inspection-type']", onChangeInspectionType);
  };

  var unloadListeners = function () {
    $modal.off("2N:FormInput", ".compliant input", rerenderSummary);
    $modal.off("2N:FormInput", "[name='inspection-type']", onChangeInspectionType);
  };

  var onChangeInspectionType = function () {
    FacilityRequirements.onChangeInspectionType($modal);
    toggleVisibleTabs();
    rerenderSummary();
  };

  var rerenderSummary = function () {
    FacilityInspectionSubmission.renderSummary(getAllData());
  };

  var reloadListeners = function () {
    if (!$modal) {
      return;
    }

    Form.initializeDatePickers($modal, formKey);
    Form.initializeAutoNumericFields($modal);
  };

  var mapTabProps = function (tabs) {
    const data = dataStore.getWithUpdates();

    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
      base: Config.get().apiUrl,
    };
  };

  var getHeaderHtml = function (data) {
    return nunjucks.render("modals/facilityInspection/facilityInspectionHeader.njk", data);
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {
      dataStore.discard();
      unloadListeners();
    });
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var saveButtonClick = function () {
    return new Promise((resolve) => {
      const fields = FacilityInspectionValidator.getMissingRequiredFields(getAllData());
      _saveOrWarnInvalid(fields);
      resolve(false);
    });
  };

  var _saveOrWarnInvalid = function (fields) {
    showSaveAndWarnModal(fields);
    highlightInvalidFields(fields);
  };

  var saveAndCloseModal = async function () {
    let uploadingData;

    try {
      await Form.getReadyToSavePromise(formKey);
      if (dataStore.getForm().locked) {
        await dataStore.lock();
      } else {
        await dataStore.save();
      }

      TabbedModal.hide();
      unloadListeners();
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return uploadingData;
  };

  var getDataToUpload = function () {
    return getUpdateData(dataStore.getInitial(), dataStore.getForm(), dataStore.isNew());
  };

  var getUpdateData = function (initialData, dataToUpload, isNew) {
    if (isNew) {
      dataToUpload = mergeDefaultsIntoUpdates(initialData, dataToUpload);
    }

    const actionItemsCheckboxValues = FacilityInspectionSubmission.getActionItemsCheckboxValues();
    if (actionItemsCheckboxValues.length > 0) {
      dataToUpload.actionItems = actionItemsCheckboxValues;
    }

    if (isNew) {
      return {
        facilityId: initialData.facility.id,
        updates: dataToUpload,
      };
    } else {
      return {
        id: initialData.id,
        updates: dataToUpload,
      };
    }
  };

  var mergeDefaultsIntoUpdates = function (initialData, dataToUpload) {
    const result = $.extend(
      true,
      {
        inspectorName: initialData.inspectorName,
        startTime: initialData.startTime,
        signatures: initialData.signatures,
        locked: initialData.locked,
        inspectionCategory: initialData.inspectionCategory,
      },
      dataToUpload,
    );

    return result;
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getAllData = function () {
    return dataStore.getWithUpdates();
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var getFormKey = function () {
    return formKey;
  };

  var showSaveAndWarnModal = function (fields) {
    const messages = fields.map((field) => field.message);
    const uniqueMessages = new Set(messages);

    const incompleteFields = {
      entityName: dataStore.getInitial().facility.facilityName,
      entityKind: "inspection",
      incompleteFieldNames: [...uniqueMessages],
    };
    const buttonCallbacks = {
      cancelCallback: null,
      saveCallback: saveAndCloseModal,
      submitCallback: function () {
        setFormData(["locked"], true);
        saveAndCloseModal();
      },
    };

    SaveIncompleteModal.showWarnSaveSubmitModal(incompleteFields, buttonCallbacks);
  };

  var highlightInvalidFields = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    CommonModalFunctions.highlightFormFields($modal, fieldNames);
  };

  var selectInspectionTypeCdot = function () {
    if (Session.isCdot()) {
      $('[name="inspection-type"] option:eq(1)')
        .prop("selected", true)
        .trigger("input")
        .parents("fieldset")
        .hide();
    }
  };

  var toggleVisibleTabs = function () {
    const inspectionType = getAllData()?.inspectionType ?? null;
    ["site-documents", "facility-requirements", "open-findings"].forEach(function (tabIdentifier) {
      const $availableDocuments = $modal.find(`#${tabIdentifier} .match`);
      TabbedModal.toggleTab(tabIdentifier, $availableDocuments.length > 0 && inspectionType);
    });
  };

  return {
    getAllData,
    loadNewFacilityInspection,
    loadExistingFacilityInspection,
    saveAndCloseModal,
    setFormData,
    getFormKey,
    reloadListeners,
    getDataToUpload,
    _showFacilityInspectionModal,
    getUpdateData,
    _saveOrWarnInvalid,
    _setNewDefaultData,
  };
};

module.exports = FacilityInspectionController();

const FacilityInspectionGeneralInfo = require("./facilityInspectionGeneralInfo");
const FacilityInspectionSiteDocuments = require("./facilityInspectionSiteDocuments");
const FacilityRequirements = require("./facilityRequirements");
const FacilityInspectionSubmission = require("./facilityInspectionSubmission");

const Session = require("../../login/session");
const CommonModalFunctions = require("../../modals/commonModalFunctions");
const Config = require("../../config");
const Form = require("../../general/form");
const FormSettings = require("../../settings/formSettings");
const SaveIncompleteModal = require("../../modals/saveIncompleteModal");
const TabbedModal = require("../../general/tabbedModal");
const UploadValidator = require("../../general/uploadValidator");
const FacilityInspectionValidator = require("./facilityInspectionValidator");
const FacilityInspectionOpenFindings = require("./facilityInspectionOpenFindings");
const ResourceController = require("../../offline/resourceController");
const DataStoreError = require("../../errors/dataStoreError");
