"use strict";

const BootstrapExtensions = function () {
  var run = function () {
    preventMultipleModalBackdrops();
    preventDropDownOverflow();
  };

  var preventMultipleModalBackdrops = function () {
    $(document.body).on("shown.bs.modal", function () {
      if ($(".modal-backdrop").length > 1) {
        $(".modal-backdrop").not(":first").remove();
      }
    });
  };

  var preventDropDownOverflow = function () {
    $(document)
      .on("shown.bs.dropdown", ".dropdown", _applyDropupClass)
      .on("hidden.bs.dropdown", ".dropdown", function () {
        $(this).removeClass("dropup");
      });
  };

  var _applyDropupClass = function (e) {
    var $dropdown = $(this);
    var $ul = $dropdown.children(".dropdown-menu");
    var $button = $dropdown.children(".dropdown-toggle");
    var $container =
      $dropdown.parents(".data-list-container").length > 0 &&
      $dropdown.parents(".data-list-container").height() > 0
        ? $dropdown.parents(".data-list-container")
        : $(window);

    if (
      _hasMoreSpaceAbove(
        $ul.offset().top - ($container.offset()?.top ?? 0),
        $button.height(),
        $ul.height(),
        $container.scrollTop(),
        $container.height(),
      )
    ) {
      $(this).addClass("dropup");
    }
  };

  var _hasMoreSpaceAbove = function (ulOffsetTop, buttonHeight, ulHeight, scrollTop, windowHeight) {
    var spaceUp = ulOffsetTop - buttonHeight - ulHeight - scrollTop;
    var spaceDown = scrollTop + windowHeight - (ulOffsetTop + ulHeight);

    return spaceDown < 0 && (spaceUp >= 0 || spaceUp > spaceDown);
  };

  return {
    run,
    _hasMoreSpaceAbove,
  };
};

module.exports = BootstrapExtensions();
