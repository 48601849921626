"use strict";

const FacilityInspectionSummary = function () {
  var facilityIdentifier;

  var getSummaryHtml = function (data, observationsData) {
    if (data) {
      facilityIdentifier = data.facility?.facilityIdentifier;
    }

    return nunjucks.render("modals/facilityInspection/templates/summary.njk", {
      facilityIdentifier: facilityIdentifier,
      includeLetterGrade: includeLetterGrade(),
      ...observationsData,
    });
  };

  var getObservationsData = function () {
    const findingsSelector =
      ".location-observation.filtering.match .compliant, .compliant.open-finding";
    const compliantFindingsCount = $(findingsSelector).find("input[value='yes']:checked").length;
    const nonCompliantFindingsCount = $(findingsSelector).find("input[value='no']:checked").length;
    const illicitDischargeFindings = $(
      "input[type='radio'][name='locationObservations[392][compliant]'][value='no']:checked",
    ).length;
    const siteLetterGrade = getSiteLetterGrade(nonCompliantFindingsCount, illicitDischargeFindings);
    return {
      compliantFindingsCount: compliantFindingsCount,
      nonCompliantFindingsCount: nonCompliantFindingsCount,
      totalFindingsCount: compliantFindingsCount + nonCompliantFindingsCount,
      siteLetterGrade: siteLetterGrade,
      siteLetterGradeClass: getSiteLetterGradeClass(siteLetterGrade),
    };
  };

  var getSiteLetterGrade = function (nonCompliantCount, illicitDischargeFindings) {
    if (nonCompliantCount > 5 || illicitDischargeFindings > 0) {
      return "F";
    } else if (nonCompliantCount === 5) {
      return "D";
    } else if (nonCompliantCount === 4) {
      return "C";
    } else if (nonCompliantCount === 3) {
      return "B";
    } else {
      return "A";
    }
  };

  var getSiteLetterGradeClass = function (siteLetterGrade) {
    if (["A", "B"].includes(siteLetterGrade)) {
      return "green";
    } else {
      return "red";
    }
  };

  var includeLetterGrade = function () {
    return !Session.isCdot();
  };

  return {
    getSummaryHtml,
    getObservationsData,
  };
};

module.exports = FacilityInspectionSummary();

const Session = require("../../login/session");
