"use strict";

const BaseEnforcementController = function (
  formKey,
  htmlPath,
  headerTemplate,
  getTabModules,
  newEnforcementApiFunction,
  getEnforcementApiFunction,
  createEnforcementApiFunction,
  updateEnforcementApiFunction,
  getAssetNameFunction,
  getAssetIdFunction,
  { afterHideFunction, beforeSaveFunction } = {},
) {
  let $modal;
  let tabs;
  let data;

  var loadListeners = function () {
    Form.initializeAndLoadListeners($modal, formKey);
    FormSettings.customizeForm($modal, formKey);
  };

  var unloadListeners = function () {};

  var loadNewEnforcement = async function (id, options = {}) {
    const newData = await newEnforcementApiFunction(id);
    const defaultData = EnforcementConstants.getNewEnforcementDefaults(newData);

    if (options?.flaggedForEnforcement) {
      defaultData.flaggedForEnforcement = true;
      defaultData.flaggedForEnforcementInspectionId = options.flaggedForEnforcementInspectionId;
    }

    showEnforcementModal(defaultData, true);
  };

  var viewEnforcementAction = async function (id) {
    const enforcementData = await getEnforcementApiFunction(id);
    showEnforcementModal({ ...enforcementData, escalationAction: null, readOnly: true });
  };

  var editEnforcementAction = async function (id) {
    const enforcementData = await getEnforcementApiFunction(id);
    showEnforcementModal({
      ...enforcementData,
      escalationAction: "keep-open",
      issueDate: DateTime.getNowIso(),
    });
  };

  var showEnforcementModal = function (newData, newEnforcement = false) {
    const { EnforcementAction, EnforcementLetterManagement, EnforcementHistory } = getTabModules();

    data = newData;

    tabs = newEnforcement
      ? [EnforcementAction, EnforcementLetterManagement]
      : data?.readOnly
        ? [EnforcementHistory, EnforcementLetterManagement]
        : [EnforcementAction, EnforcementHistory, EnforcementLetterManagement];

    const renderData = mapTabProps(tabs, data);
    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath);

    loadListeners();
    setAdditionalModalHandlers();
    updateLetterFilesList();
    TabbedModal.setHeaderText(getHeaderHtml(renderData));
    if (data?.readOnly) {
      TabbedModal.hideSaveButton();
    }
  };

  var getHeaderHtml = function (data) {
    return nunjucks.render(headerTemplate, data);
  };

  var mapTabProps = function (tabs, data) {
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
    };
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {
      unloadListeners();
    });
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var saveButtonClick = function () {
    return new Promise((resolve) => {
      const fields = EnforcementValidator.getMissingRequiredFields(getAllData());
      _saveOrWarnInvalid(fields);
      resolve(false);
    });
  };

  var _saveOrWarnInvalid = function (fields) {
    showSaveWarningModal(fields);
    highlightInvalidFields(fields);
  };

  var highlightInvalidFields = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    CommonModalFunctions.highlightFormFields($modal, fieldNames);
  };

  var showSaveWarningModal = function (fields) {
    const messages = fields.map((field) => field.message);
    const uniqueMessages = new Set(messages);

    if (uniqueMessages.size > 0) {
      const currentAction = getLatestData().escalationAction;
      const actionText = currentAction === "keep-open" ? "issue" : currentAction;
      const warningText =
        SaveIncompleteModal.getIncompleteFieldsBodyText(
          getAssetNameFunction(data),
          "escalation action",
          Array.from(uniqueMessages),
        ) +
        `<p>You cannot ${actionText} the enforcement until all required fields are completed. Click 'Return' to go back and complete the required fields. </p>`;
      MessageModal.showReturnWarningModal(warningText, true);
    } else {
      MessageModal.showConfirmWarningModal(
        getWarningMessageFromLevel(),
        saveAndCloseModal,
        "Return",
        "Save",
      );
    }
  };

  var getWarningMessageFromLevel = function () {
    const escalationAction = getLatestData()?.escalationAction;
    return EnforcementConstants.getWarningMessageFromLevel(escalationAction);
  };

  var saveAndCloseModal = async function () {
    let uploadingData;

    try {
      await Form.getReadyToSavePromise(formKey);
      const dataToUpload = getDataToUpload();
      uploadingData = await sendToApi(dataToUpload);
      TabbedModal.hide();
      afterHideFunction?.();
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return uploadingData;
  };

  var setFormField = function (path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(formKey, path, value);
  };

  var unsetFormField = function (path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(formKey, path);
    return Form.getDataFromForm(formKey, false);
  };

  var getDataToUpload = function () {
    const dataToUpload = _getUpdateData(Form.getDataFromForm(formKey, false));
    beforeSaveFunction?.(dataToUpload.updates);

    return dataToUpload;
  };

  var sendToApi = async function (dataToUpload) {
    if (dataToUpload?.updates?.id) {
      dataToUpload.enforcementId = dataToUpload?.updates?.id;
      await updateEnforcementApiFunction(Form.toFormData(dataToUpload));
    } else {
      await createEnforcementApiFunction(Form.toFormData(dataToUpload));
    }

    return dataToUpload;
  };

  var getExistingData = function () {
    return data;
  };

  var getLatestData = function () {
    return $.extend(true, {}, data, Form.getDataFromForm(formKey, false));
  };

  var _getUpdateData = function (dataUpdates) {
    let dataToUpload = $.extend(true, {}, dataUpdates);
    dataToUpload = mergeDefaultsIntoUpdates(dataToUpload);

    delete dataToUpload.constants;

    return {
      ...getAssetIdFunction(data),
      updates: dataToUpload,
    };
  };

  var getAllData = function () {
    const formData = Form.getDataFromForm(formKey, false);
    return $.extend(true, {}, data, formData);
  };

  var mergeDefaultsIntoUpdates = function (dataToUpload) {
    const result = $.extend({}, data, dataToUpload);
    return result;
  };

  var getFormKey = function () {
    return formKey;
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var updateLetterFilesList = function () {
    const letterFilesHtml = getLetterFilesHtml();
    $modal.find("[name=enforcement-letter]").replaceWith(letterFilesHtml);
  };

  var getLetterFilesHtml = function () {
    const { EnforcementLetterManagement } = getTabModules();
    return nunjucks.render("modals/enforcement/_enforcementLetterOptions.njk", {
      enforcementLetter: getLatestData()?.enforcementLetter,
      props: { enforcementLetterOptions: EnforcementLetterManagement.getLetterFilesList() },
    });
  };

  return {
    viewEnforcementAction,
    editEnforcementAction,
    loadNewEnforcement,
    showEnforcementModal,
    getFormKey,
    getExistingData,
    getLatestData,
    setFormField,
    unsetFormField,
    getDataToUpload,
    getLetterFilesHtml,
    updateLetterFilesList,
  };
};

module.exports = BaseEnforcementController;

const DateTime = require("../dateTime");
const Form = require("../general/form");
const TabbedModal = require("../general/tabbedModal");
const UploadValidator = require("../general/uploadValidator");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const MessageModal = require("../modals/messageModal");
const SaveIncompleteModal = require("../modals/saveIncompleteModal");
const FormSettings = require("../settings/formSettings");
const EnforcementConstants = require("./enforcementConstants");
const EnforcementValidator = require("./enforcementValidator");
