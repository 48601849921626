"use strict";

const AssetsOutfallPopup = function () {
  var getPopupHtml = function (props) {
    const layerName = "assetsOutfallEsriDynamicMap";
    const properties = {
      layerName,
      props,
      formattedDate: AssetsPopupFunctions.getFormattedDate(props.install_date),
      assetAge: AssetsPopupFunctions.getAssetAge(props.install_date),
      popupHeaderProps: AssetsPopupFunctions.getPopupHeaderProps(props),
      displayPofText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.pof_final_text,
      ),
      displayCofText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.cof_final_text,
      ),
      displayCriticalityText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.criticality_text,
      ),
      mode: AssetsPopupFunctions.getMode(),
      displayOutletType: ToolSettings.getDomainDisplayName("outlet_type", props.outlet_type),
      displayOutletDiameterUnits: UnitConversion.getUnitAbbreviation(props.outlet_diameter_units),
      displayOutletLengthUnits: UnitConversion.getUnitAbbreviation(props.outlet_length_units),
      displayOutletWidthUnits: UnitConversion.getUnitAbbreviation(props.outlet_width_units),
      diameterLabel:
        FormSettings.getLabelSettingsByFormKey("outfall-inventory")?.find(
          (label) => label.key === "outlet-diameter",
        )?.newLabel ?? null,
    };
    return PopupContent.getLayerPopup(properties, layerName);
  };

  return {
    getPopupHtml,
  };
};

module.exports = AssetsOutfallPopup();

const PopupContent = require("../../mapFunctions/popupContent");
const AssetsPopupFunctions = require("../assetsPopupFunctions");
const ToolSettings = require("../../settings/toolSettings");
const UnitConversion = require("../../unitConversion");
const FormSettings = require("../../settings/formSettings");
