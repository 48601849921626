class IndcomFacilitySelfInspectionModal {
  static formKey = "indcom-facility-self-inspection";
  static $modal = $("#assessment-modal");
  static initialData;
  static selfInspection;
  static readOnly = false;

  static async show() {
    IndcomFacilitySelfInspectionModal.initialData =
      await PortalApiCalls.getIndcomFacilitySelfInspectionProject();
    IndcomFacilitySelfInspectionModal.#render();
    IndcomFacilitySelfInspectionModal.#loadDomListeners();

    Analytics.sendScreenView("portal", IndcomFacilitySelfInspectionModal.formKey);
  }

  static async showReadOnly(id) {
    const data = await ApiCalls.getFacilitySelfInspection(id);
    IndcomFacilitySelfInspectionModal.selfInspection = data.selfInspection;
    IndcomFacilitySelfInspectionModal.initialData = data.initialData;
    IndcomFacilitySelfInspectionModal.readOnly = true;

    IndcomFacilitySelfInspectionModal.#render();
    IndcomFacilitySelfInspectionModal.#loadDomListeners();
  }

  static #loadDomListeners() {
    IndcomFacilitySelfInspectionModal.$modal.on(
      "click",
      ".save-button",
      IndcomFacilitySelfInspectionModal._warnAndSave,
    );
    IndcomFacilitySelfInspectionModal.$modal.on(
      "click",
      ".cancel-button",
      IndcomFacilitySelfInspectionModal.close,
    );
    IndcomFacilitySelfInspectionModal.$modal.on(
      "2N:FormInput",
      IndcomFacilitySelfInspectionModal.#onInput,
    );

    Form.initializeAndLoadListeners(
      IndcomFacilitySelfInspectionModal.$modal,
      IndcomFacilitySelfInspectionModal.formKey,
    );
    Form.initializeDropzone(
      IndcomFacilitySelfInspectionModal.formKey,
      IndcomFacilitySelfInspectionModal.$modal,
      {
        existingFiles: IndcomFacilitySelfInspectionModal.selfInspection?.files,
        readOnly: IndcomFacilitySelfInspectionModal.readOnly,
        downloadCallback: ApiCalls.downloadFacilitySelfInspectionFile,
      },
    );

    DateTimePicker.setDatePickerRange(
      IndcomFacilitySelfInspectionModal.$modal,
      "receivedDate",
      false,
      DateTime.parseIsoDate(IndcomFacilitySelfInspectionModal.initialData.lidNoticeSentDate),
    );
  }

  static #unloadDomListeners() {
    IndcomFacilitySelfInspectionModal.$modal.off(
      "click",
      ".save-button",
      IndcomFacilitySelfInspectionModal._warnAndSave,
    );
    IndcomFacilitySelfInspectionModal.$modal.off(
      "click",
      ".cancel-button",
      IndcomFacilitySelfInspectionModal.close,
    );
    IndcomFacilitySelfInspectionModal.$modal.off(
      "2N:FormInput",
      IndcomFacilitySelfInspectionModal.#onInput,
    );
  }

  static #onInput() {
    IndcomFacilitySelfInspectionModal.#checkEnableSaveButton();
  }

  static #checkEnableSaveButton() {
    const canSaveLetter = IndcomFacilitySelfInspectionModal._hasAllRequiredFields();
    IndcomFacilitySelfInspectionModal.$modal
      .find(".save-button")
      .toggleClass("disabled", !canSaveLetter);
  }

  static #render() {
    const now = new Date(Date.now());

    const html = nunjucks.render("modals/portal/indcomFacilitySelfInspection.njk", {
      initialData: IndcomFacilitySelfInspectionModal.initialData,
      selfInspection: IndcomFacilitySelfInspectionModal.selfInspection,
      nonCompliantFindings: IndcomFacilitySelfInspectionModal.#getNonCompliantFindings(),
      displayDate:
        IndcomFacilitySelfInspectionModal.selfInspection?.displayDate ??
        DateTime.formatDateAsDisplayDate(now),
      customCompliantRadioLabels: FacilityInspectionConstants.getCustomCompliantRadioLabels(),
      readOnly: IndcomFacilitySelfInspectionModal.readOnly,
    });
    IndcomFacilitySelfInspectionModal.$modal.html(html);
    IndcomFacilitySelfInspectionModal.$modal.modal("show");
  }

  static #getNonCompliantFindings() {
    if (IndcomFacilitySelfInspectionModal.selfInspection) {
      return IndcomFacilitySelfInspectionModal.selfInspection.locationObservations.map(
        function (finding) {
          return {
            id: finding.id,
            compliant: finding.compliant,
            observationText: finding.observationText,
            responseNotes: finding.notes,
            responsePhotos: finding.photos,
          };
        },
      );
    }

    return IndcomFacilitySelfInspectionModal.initialData.nonCompliantFindings;
  }

  static #getUpdateData() {
    return {
      updates: Form.getDataFromForm(IndcomFacilitySelfInspectionModal.formKey, false),
    };
  }

  static async _warnAndSave() {
    try {
      await MessageModal.showConfirmWarningModalAsPromise(
        `Are you ready to submit? Once you click “Submit” you will not be able to edit your response at a later time. Click “Submit” to officially submit your response, or click “Go Back” to return to the previous window and review your response.`,
        { returnMessage: "Go Back", okMessage: "Submit" },
      );
    } catch (e) {
      return;
    }

    await Form.getReadyToSavePromise(IndcomFacilitySelfInspectionModal.formKey);
    const updateData = IndcomFacilitySelfInspectionModal.#getUpdateData();
    await PortalApiCalls.saveNewIndcomFacilitySelfInspection(updateData);
    PortalController.goWithSecret("portal-indcom-facility-self-inspection-confirmation");
  }

  static close() {
    Form.clearForm(IndcomFacilitySelfInspectionModal.formKey);
    IndcomFacilitySelfInspectionModal.#unloadDomListeners();
    IndcomFacilitySelfInspectionModal.$modal.modal("hide");
  }

  static _hasAllRequiredFields() {
    let requiredFilled = 0;
    const requiredFields = ["signature", "name"];
    const data = Form.getDataFromForm(IndcomFacilitySelfInspectionModal.formKey, false);

    for (const key in data) {
      if (requiredFields.includes(key) && !!data[key]) {
        requiredFilled++;
      }
    }

    return (
      requiredFilled === requiredFields.length &&
      IndcomFacilitySelfInspectionModal.#hasAllRequiredFindingResponses(data)
    );
  }

  static #hasAllRequiredFindingResponses(data) {
    for (const initialFinding of IndcomFacilitySelfInspectionModal.initialData
      .nonCompliantFindings) {
      const updatedFinding = data?.nonCompliantFindings?.[initialFinding.id];

      if (!updatedFinding || !updatedFinding.notes) {
        return false;
      }
    }

    return true;
  }
}

module.exports = IndcomFacilitySelfInspectionModal;

const Analytics = require("../../general/analytics");
const PortalApiCalls = require("../portalApiCalls");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
const PortalController = require("../portalController");
const DateTimePicker = require("../../general/dateTimePicker");
const DateTime = require("../../dateTime");
const FacilityInspectionConstants = require("../../indcom/facilityInspection/facilityInspectionConstants");
const ApiCalls = require("../../apiCalls");
