"use strict";

const AssetsCleanOutPopup = function () {
  var getPopupHtml = function (props) {
    const layerName = "assetsCleanOutEsriDynamicMap";
    const properties = {
      layerName,
      props,
      formattedDate: AssetsPopupFunctions.getFormattedDate(props.install_date),
      assetAge: AssetsPopupFunctions.getAssetAge(props.install_date),
      popupHeaderProps: AssetsPopupFunctions.getPopupHeaderProps(props),
      displayPofText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.pof_final_text,
      ),
      displayCofText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.cof_final_text,
      ),
      displayCriticalityText: ToolSettings.getDomainDisplayName(
        "consequence_of_failure",
        props.criticality_text,
      ),
      mode: AssetsPopupFunctions.getMode(),
      displayType: ToolSettings.getDomainDisplayName("cleanout_type", props.type),
    };
    return PopupContent.getLayerPopup(properties, layerName);
  };

  return {
    getPopupHtml,
  };
};

module.exports = AssetsCleanOutPopup();

const PopupContent = require("../../mapFunctions/popupContent");
const AssetsPopupFunctions = require("../assetsPopupFunctions");
const ToolSettings = require("../../settings/toolSettings");
