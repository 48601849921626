"use strict";

const MuniCleanoutPopup = function () {
  var getPopupHtml = function (properties) {
    properties.permissions = UserPermissions.getAllPermissions();
    return PopupContent.getLayerPopup(properties, "muniCleanout");
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteCleanout(data.id, data.name, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteCleanout = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Cleanout ${name}? Click "Delete" to permanently delete the Cleanout. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteCleanout(id);
        invalidateLayerDataFunction();
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "cleanouts");
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };
  var onPopupOpen = async function (properties, event = null) {
    const id = properties.id;
    var data = await getPopupData(id);
    const html = getPopupHtml(data);
    var layerName = "muniCleanout";
    const layerModule = LayerFunctions.getLayerModule(layerName);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var getPopupData = async function (id) {
    const popupData = await ApiCalls.getMuniCleanoutPopup(id);
    popupData.icon = RoutineMaintenanceIcon.getMaintenanceIcon(
      "muni-cleanout",
      popupData.lastMaintenanceInspection,
      popupData.lastMaintenanceInspection?.needsRepair,
    );

    popupData.displayDiameterUnits = UnitConversion.getUnitAbbreviation(popupData.diameterUnits);
    popupData.displayLengthUnits = UnitConversion.getUnitAbbreviation(popupData.lengthUnits);
    popupData.displayWidthUnits = UnitConversion.getUnitAbbreviation(popupData.widthUnits);

    return popupData;
  };

  return {
    getPopupHtml,
    onPopupOpen,
  };
};

module.exports = MuniCleanoutPopup();

const ApiCalls = require("../apiCalls");
const LayerFunctions = require("../layerFunctions");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const UserPermissions = require("../login/userPermissions");
const Analytics = require("../general/analytics");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
const UnitConversion = require("../unitConversion");
