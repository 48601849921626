"use strict";

const GeoServerMapLayer = function (layerName, layerKey, createMarkerFunction = null) {
  const layerConfig = LayerFunctions.getLayerConfig(layerName);
  const wfsLayerKey = `${layerKey}Wfs`;
  const wmsLayerKey = `${layerKey}Wms`;
  var wfsMarkers;
  var superclusterIndex;
  var superclusterReady = false;
  var isWfsLayerFetching = false;
  var openPopupLayer; // Can't use `isPopupOpen()` because we rebind the popup, breaking Leaflet's reference
  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      var currentMapLayer = mapLayers[layerKey];
      if (isEnabled) {
        enableLayer(map, mapLayers, mapId, currentMapLayer, sameSpatialFilter);
      } else {
        disableLayer(map, mapLayers, mapId, currentMapLayer);
      }
    });

    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const hasAssociatedG2Layer =
      DataViewFunctions.getCurrentDataViewProperty("hasAssociatedG2Layer");
    const isGisDataViewPrimaryLayer = layerConfig?.isGisDataViewPrimaryLayer;
    if (
      ((isGisDataView && isGisDataViewPrimaryLayer) || hasAssociatedG2Layer) &&
      mapId === "main"
    ) {
      if (!sessionStorage.getItem("geoServerLayerType")) {
        sessionStorage.setItem("geoServerLayerType", "WFS");
      }

      const onChangeSuperCluster = async function () {
        sessionStorage.setItem("geoServerLayerType", this.checked ? "WFS" : "WMS");
        await rerenderGeoServerLayer(map, mapLayers, mapId);
      };
      const superClusterSelector = `input[name='super-cluster'][data-layer='${layerName}']`;
      $("body")
        .off("change", superClusterSelector)
        .on("change", superClusterSelector, onChangeSuperCluster);
    }
  };

  var enableLayer = function (map, mapLayers, mapId, currentMapLayer, sameSpatialFilter) {
    if (!layerConfig?.enableCatchmentLayer) {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").addClass("esri-dynamic-map-layer");
    }

    if (!Tree.get("enabledGeoServerLayers").includes(layerName)) {
      Tree.select("enabledGeoServerLayers").push(layerName);
    }

    loadGeoServerLayer(map, mapLayers, mapId, { refreshData: false });
  };

  var disableLayer = function (map, mapLayers, mapId, currentMapLayer) {
    const enabledGeoServerLayers = Tree.get("enabledGeoServerLayers").filter(
      (enabledLayer) => enabledLayer !== layerName,
    );
    Tree.set("enabledGeoServerLayers", enabledGeoServerLayers);
    if (!Tree.get("enabledEsriLayers")?.length && !Tree.get("enabledGeoServerLayers")?.length) {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").removeClass(
        "esri-dynamic-map-layer",
      );
    }
    if (MapFunctions.mapHasLayer(map, currentMapLayer)) {
      map.removeLayer(currentMapLayer);
    }
  };

  var invalidateLayerData = async function (map, mapLayers, mapId, { loadingScreen = true } = {}) {
    const isEnabled = Tree.get("layers", layerName, "isEnabled");

    Tree.set(["layers", layerName, "data"], null);
    if (ToDoFunctions.isToDoLayer(layerName)) {
      Tree.set(["todos", Tree.get("dataView"), "unfilteredData"], null);
    }

    if (isEnabled) {
      await loadGeoServerLayer(map, mapLayers, mapId, { loadingScreen });
    } else {
      MainMap.invalidateLayerByName(layerName);
    }
  };

  var loadGeoServerLayer = async function (
    map,
    mapLayers,
    mapId,
    { refreshData = true, loadingScreen = true } = {},
  ) {
    if (isWfsLayerFetching) return;
    if (!_checkIfLayerIsAvailableInTodoTab(layerName)) return;
    const noWfsLayer = LayerFunctions.getLayerConfig(layerName)["noWfsLayer"];

    if (
      (DataViewFunctions.getCurrentDataViewProperty("isGisDataView") ||
        DataViewFunctions.getCurrentDataViewProperty("hasAssociatedG2Layer")) &&
      !noWfsLayer
    ) {
      mapLayers[wfsLayerKey] = await createWfsLayer(map, mapLayers, refreshData, loadingScreen);
    }

    rerenderGeoServerLayer(map, mapLayers, mapId);
    if (refreshData) {
      ToDoFunctions.invalidateToDoData(layerName);
      ToDoFunctions.loadTodoByLayer(layerName, mapId);
      GeoServerLayerFunctions.openGeoServerPopupByLayerName(layerName);
      if (mapLayers[wfsLayerKey]) {
        reopenPopupLayers(mapLayers[wfsLayerKey]);
      }
    }
  };

  var rerenderGeoServerLayer = function (map, mapLayers, mapId) {
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const hasAssociatedG2Layer =
      DataViewFunctions.getCurrentDataViewProperty("hasAssociatedG2Layer");
    const layerConfig = LayerFunctions.getLayerConfig(layerName);
    removeWmsLayer(map, mapLayers);

    if (isGisDataView || hasAssociatedG2Layer) {
      if (
        (sessionStorage.getItem("geoServerLayerType") === "WFS" &&
          layerConfig["clusteringEnabled"]) ||
        (mapId === "modal" && layerConfig?.isGisDataViewPrimaryLayer)
      ) {
        mapLayers[layerKey] = mapLayers[wfsLayerKey];
      } else {
        removeWfsLayer(map, mapLayers);
        addWmsLayer(mapLayers);
        mapLayers[wmsLayerKey] = mapLayers[layerKey];
      }
    } else if (layerConfig["isEsriLayer"]) {
      addWmsLayer(mapLayers);
    } else {
      // @TODO: find a better solution for data-view-only G2 layers
      removeWfsLayer(map, mapLayers);
      return;
    }

    if (
      mapLayers[layerKey] &&
      Tree.get("layers", layerName, Actions.getLayerIsEnabledPathByMapId(mapId))
    ) {
      mapLayers[layerKey].addTo(map);
    }
  };

  var removeWfsLayer = function (map, mapLayers) {
    const layer = mapLayers?.[wfsLayerKey];

    if (MapFunctions.mapHasLayer(map, layer)) {
      map.removeLayer(layer);
    }
  };

  var removeWmsLayer = function (map, mapLayers) {
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const hasAssociatedG2Layer =
      DataViewFunctions.getCurrentDataViewProperty("hasAssociatedG2Layer");

    const currentMapLayer =
      isGisDataView || hasAssociatedG2Layer ? mapLayers?.[wmsLayerKey] : mapLayers?.[layerKey];

    if (MapFunctions.mapHasLayer(map, currentMapLayer)) {
      map.removeLayer(currentMapLayer);
    }
  };

  var addWmsLayer = function (mapLayers) {
    var onlineFiltering = "onlineFiltering" in layerConfig ? layerConfig["onlineFiltering"] : true;
    mapLayers[layerKey] = GeoServerLayerFunctions.getWmsLayer(layerName, onlineFiltering);
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", async function () {
      if (Tree.get("layers", layerName, Actions.getLayerIsEnabledPathByMapId(mapId))) {
        await loadGeoServerLayer(map, mapLayers, mapId, { refreshData: false });
      }
    });

    if (Tree.get("dataView") === "asset-management") {
      Tree.select("assetManagementRadioMode").on("update", function (e) {
        loadGeoServerLayer(map, mapLayers, mapId, { refreshData: false });
      });
    }

    if (layerName === "telrResultBmp") {
      Tree.select("dataScenario").on("update", function (e) {
        const dataScenario = e.data.currentData;
        if (
          Tree.get("layers", "telrResultBmp", "isEnabled") &&
          !dataScenario.includes("baseline")
        ) {
          loadGeoServerLayer(map, mapLayers, mapId, { refreshData: false });
        }
      });
    }
  };

  var createWfsLayer = async function (map, mapLayers, refreshData, loadingScreen) {
    const dataView = Tree.get("dataView");
    if (wfsMarkers) {
      unloadPopupEventListeners(wfsMarkers);
    }
    if (MapFunctions.mapHasLayer(map, wfsMarkers)) {
      map.removeLayer(wfsMarkers);
    }
    if (MapFunctions.mapHasLayer(map, mapLayers[wfsLayerKey])) {
      map.removeLayer(mapLayers[wfsLayerKey]);
    }
    const popupHtmlFunction = GeoServerLayerFunctions.getPopupHtmlFunction(layerName);

    _setWfsMarkers(
      L.geoJSON(null, {
        pointToLayer: createClusterIcon,
        onEachFeature: function (feature, layer) {
          if (!feature.properties.cluster && !Map.popupsDisabled()) {
            layer.bindPopup(
              () => popupHtmlFunction(structuredClone(feature.properties)),
              Layers.getPopupOptions(),
            );
          }
        },
      }),
    ).addTo(map);

    map.off("moveend", triggerSuperclusterLayerUpdate);
    map.on("moveend", triggerSuperclusterLayerUpdate);

    map.off("popupclose", onPopupClose);
    map.on("popupclose", onPopupClose);

    wfsMarkers.off("click");
    wfsMarkers.on("click", function (e) {
      var clusterId = e.layer.feature.properties.cluster_id;
      var center = e.latlng;
      var expansionZoom;
      if (clusterId) {
        expansionZoom = superclusterIndex.getClusterExpansionZoom(clusterId);
        map.flyTo(center, expansionZoom);
      }
    });

    _setSuperclusterReady(false);
    var allFeatures = await loadWfsLayerData(mapLayers, refreshData, loadingScreen);

    var validDataViews = [
      "bmp",
      "construction-project",
      "lid-project",
      "indcom-facilities",
      "muni-manhole",
    ];

    if (validDataViews.includes(dataView)) {
      setSpatialArrays(allFeatures);
    }

    allFeatures = allFeatures.map(function (data) {
      return {
        type: "Feature",
        geometry: data.geometry,
        properties: data,
      };
    });

    superclusterIndex = new Supercluster({
      radius: 100,
      maxZoom: 16,
      minPoints: 5,
    }).load(allFeatures);
    _setSuperclusterReady(true);
    _updateSuperclusterLayer(map);
    _loadPopupEventListeners(wfsMarkers);

    return wfsMarkers;
  };

  var setSpatialArrays = function (data) {
    let catchmentOptions = new Set();
    let receivingWaterOptions = new Set();
    let urbanDrainageOptions = new Set();
    let highwayOptions = new Set();
    let zoneOptions = new Set();

    for (const item of data) {
      item.drains_to_c && urbanDrainageOptions.add(item.drains_to_c);
      item.drains_to_rw && receivingWaterOptions.add(item.drains_to_rw);
      item.catchment_name && catchmentOptions.add(item.catchment_name);
      item.highway_name && highwayOptions.add(item.highway_name);
      item.zone_names && zoneOptions.add(item.zone_names);
    }

    const customSort = (a, b) => {
      const regex = /([a-zA-Z\s]+)(\d*)/;
      const matchA = a.match(regex) || ["", a, ""];
      const matchB = b.match(regex) || ["", b, ""];

      if (matchA[1] < matchB[1]) return -1;
      if (matchA[1] > matchB[1]) return 1;

      return parseInt(matchA[2] || "0") - parseInt(matchB[2] || "0");
    };

    catchmentOptions = Array.from(catchmentOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    receivingWaterOptions = Array.from(receivingWaterOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    urbanDrainageOptions = Array.from(urbanDrainageOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    highwayOptions = Array.from(highwayOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    zoneOptions = Array.from(zoneOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));

    Tree.set("catchmentOptions", catchmentOptions);
    Tree.set("receivingWaterOptions", receivingWaterOptions);
    Tree.set("urbanDrainageOptions", urbanDrainageOptions);
    Tree.set("highwayOptions", highwayOptions);
    Tree.set("zoneOptions", zoneOptions);
  };

  var triggerSuperclusterLayerUpdate = function (event) {
    _updateSuperclusterLayer(event.target);
  };

  var _updateSuperclusterLayer = function (map) {
    if (!superclusterReady || !map.getZoom()) return;

    var bounds = map.getBounds();
    var bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
    var zoom = map.getZoom();
    var clusters = superclusterIndex.getClusters(bbox, zoom);

    clearLayersWithoutOpenPopup(wfsMarkers);
    wfsMarkers.addData(clusters);
    wfsMarkers.isSuperClusterLayer = true;
  };

  var clearLayersWithoutOpenPopup = function (wfsMarkers) {
    const popup = null;
    for (const layer of wfsMarkers.getLayers()) {
      if (layer !== openPopupLayer) {
        wfsMarkers.removeLayer(layer);
      }
    }

    return popup;
  };

  var reopenPopupLayers = function (wfsMarkers) {
    for (const layer of wfsMarkers.getLayers()) {
      if (layer === openPopupLayer) {
        layer.closePopup();
        layer.openPopup();
      }
    }
  };

  var createClusterIcon = function (feature, latlng) {
    if (!feature.properties.cluster) {
      if (
        createMarkerFunction &&
        openPopupLayer &&
        openPopupLayer.feature.properties.id === feature.properties.id
      ) {
        const icon = createMarkerFunction(feature, latlng).getIcon();
        return openPopupLayer.setIcon(icon).setLatLng(latlng);
      } else {
        return createMarkerFunction(feature, latlng);
      }
    } else {
      const count = feature.properties.point_count;
      const dataView = Tree.get("dataView");

      const size = count < 100 ? "small" : count < 1000 ? "medium" : "large";
      const isPropertiesView = dataView === "properties";
      const htmlString = isPropertiesView
        ? "<div class='esg-blue'><span>" +
          feature.properties.point_count_abbreviated +
          "</span></div>"
        : "<div><span>" + feature.properties.point_count_abbreviated + "</span></div>";

      const className = isPropertiesView
        ? "marker-cluster marker-cluster-" + size + " esg-blue"
        : "marker-cluster marker-cluster-" + size;

      const icon = L.divIcon({
        html: htmlString,
        className,
        iconSize: L.point(40, 40),
      });

      return L.marker(latlng, {
        icon: icon,
      });
    }
  };

  var addRow = async function (row, geometry) {
    const resource = ResourceController.get(layerName);
    await resource.saveOffline({ ...row });
  };

  var updateRow = async function (updates, id, geometry, oldId = null) {
    const resource = ResourceController.get(layerName);
    const data = await resource.getOfflineById(oldId || id);
    if (oldId !== id) await resource.deleteOffline(id);
    await resource.saveOffline({
      geometry,
      ...data,
      ...updates,
      id,
    });
  };

  var deleteRow = async function (id) {
    const resource = ResourceController.get(layerName);
    await resource.deleteOffline(id);
  };

  var getAll = async function () {
    const resource = ResourceController.get(layerName);
    return await resource.getAll();
  };

  var replaceAllStoredData = async function (data) {
    const resource = ResourceController.get(layerName);
    await resource.replaceAllStoredData(data);
  };

  var loadWfsLayerData = async function (mapLayers, refreshData, loadingScreen) {
    Tree.set(["layers", layerName, "isFetching"], true);
    isWfsLayerFetching = true;

    const resource = ResourceController.get(layerName);
    var data;

    if (resource) {
      const dataPath = Actions.getLayerDataPathByMapId("main");
      data = await resource.getAll({
        filters: getOfflineMapFilters(),
        refreshData: refreshData || Tree.get("layers", layerName, dataPath) === null,
        loadingScreen,
      });
    }

    data = _removeUpdateAssetIfNecessary(data);

    setTreeLayerData(data);

    if (layerConfig?.isGisDataViewPrimaryLayer) {
      Actions.setItemCount(data.length);
    }

    isWfsLayerFetching = false;

    return data;
  };

  var _removeUpdateAssetIfNecessary = function (data) {
    const openInventory = InventoryModal.getOpenInventory();
    const selectedInventoryAsset = InventoryModal.getAllData(openInventory);

    if (selectedInventoryAsset?.id) {
      data = data.filter((row) => {
        return selectedInventoryAsset?.id !== row?.id;
      });
    }
    return data;
  };

  var setTreeLayerData = function (data) {
    const dataPath = Actions.getLayerDataPathByMapId("main");
    Tree.set(["layers", layerName, "isFetching"], false);
    Tree.set(["layers", layerName, dataPath], data);

    if (DataViewFunctions.getCurrentDataViewProperty("defaultLayers")?.includes(layerName)) {
      Table.render();
    }
  };

  var getOfflineMapFilters = function () {
    const filters = Filters.getOfflineFilters();

    if (filters.toDoFilters) {
      filters.selectedSubject = Tree.get(["todos", Tree.get("dataView"), "selectedSubject"]);
    }

    return filters;
  };

  var onPopupClose = function () {
    GeoServerLayerFunctions.clearOpenPopupsByLayerName(layerName);
  };

  var _setWfsMarkers = function (newWfsMarkers) {
    wfsMarkers = newWfsMarkers;
    return wfsMarkers;
  };

  var _setSuperclusterReady = function (newSuperclusterReady) {
    superclusterReady = newSuperclusterReady;
    return superclusterReady;
  };

  var _setSuperclusterIndex = function (newSuperclusterIndex) {
    superclusterIndex = newSuperclusterIndex;
    return superclusterIndex;
  };

  var _loadPopupEventListeners = function (layer) {
    unloadPopupEventListeners(layer);
    layer.on("popupopen", _onPopupOpen);
    layer.on("popupclose", _onPopupClose);
  };

  var unloadPopupEventListeners = function (layer) {
    layer.off("popupopen", _onPopupOpen);
    layer.off("popupclose", _onPopupClose);
  };

  var _onPopupClose = function (e) {
    openPopupLayer = null;
    MapFunctions.toggleLayerInFront(e.layer, false);
  };

  var _onPopupOpen = function (e) {
    const onPopupOpenFunction = GeoServerLayerFunctions.getOnPopupOpenFunction(layerName);
    openPopupLayer = e.layer;
    if (onPopupOpenFunction) {
      onPopupOpenFunction(e.layer.feature.properties, layerName);
    }
  };

  var _checkIfLayerIsAvailableInTodoTab = function (layerName) {
    const currentDataView = Tree.get("dataView");
    const currentTab = Tree.get("activeTab");
    let layerAvailableInTodoTab = true;

    if (
      currentDataView === "lid-project" &&
      currentTab === "todo" &&
      layerName === "postConstructionProjectG2"
    ) {
      layerAvailableInTodoTab = false;
      return layerAvailableInTodoTab;
    } else {
      return layerAvailableInTodoTab;
    }
  };

  return {
    removeWmsLayer,
    removeWfsLayer,
    loadGeoServerLayer,
    loadToggledListenerForMap,
    loadTreeUpdateListenersForMap,
    invalidateLayerData,
    loadWfsLayerData,
    addRow,
    deleteRow,
    updateRow,
    getAll,
    replaceAllStoredData,
    _setWfsMarkers,
    _setSuperclusterReady,
    _setSuperclusterIndex,
    _updateSuperclusterLayer,
    _checkIfLayerIsAvailableInTodoTab,
    _loadPopupEventListeners,
    setSpatialArrays,
    _removeUpdateAssetIfNecessary,
  };
};

module.exports = GeoServerMapLayer;

const Actions = require("../actions");
const DataViewFunctions = require("../dataViewFunctions");
const Filters = require("./filters");
const GeoServerLayerFunctions = require("./geoServerLayerFunctions");
const InventoryModal = require("../general/inventoryModal");
const LayerFunctions = require("../layerFunctions");
const Layers = require("./layers");
const MainMap = require("./mainMap");
const MapFunctions = require("./mapFunctions");
const ResourceController = require("../offline/resourceController");
const Supercluster = require("supercluster");
const ToDoFunctions = require("./toDoFunctions");
const Tree = require("../tree");
const Table = require("../mapFunctions/table");
const Map = require("../esg/map");
