"use strict";

const NewProjectInspectionSummary = function () {
  var projectIdentifier;

  var getSummaryHtml = function (data) {
    if (data) {
      projectIdentifier = data.project?.projectIdentifier;
    }
    const observationData = getObservationsData();
    Tree.set("currentProjectInspectionObservationData", observationData);

    return nunjucks.render("modals/newProjectInspection/templates/summary.njk", {
      projectIdentifier: projectIdentifier,
      ...observationData,
    });
  };

  var getObservationsData = function () {
    const singleObsSelector =
      ".observation-constant.filtering.match .compliant, .open-finding .compliant";
    const singleObsCompliantCount = $(singleObsSelector).find("input[value='yes']:checked").length;
    const singleObsNonCompliantCount = $(singleObsSelector).find(
      "input[value='no']:checked",
    ).length;

    const multiObsSelector =
      ".observation-constant.filtering.match .condition, .open-finding .condition";
    const multiObsCondition10Count = $(multiObsSelector).find(`option[value="10"]:selected`).length;
    const multiObsCondition8Count = $(multiObsSelector).find(`option[value="8"]:selected`).length;
    const multiObsCondition5Count = $(multiObsSelector).find(`option[value="5"]:selected`).length;
    const multiObsCondition0Count = $(multiObsSelector).find(`option[value="0"]:selected`).length;
    const multiObsCompliantCount = multiObsCondition10Count;
    const multiObsNonCompliantCount =
      multiObsCondition8Count + multiObsCondition5Count + multiObsCondition0Count;

    const totalCompliantCount = singleObsCompliantCount + multiObsCompliantCount;
    const totalNonCompliantCount = singleObsNonCompliantCount + multiObsNonCompliantCount;
    const totalFindingsCount = totalCompliantCount + totalNonCompliantCount;

    const findingsScoreSum =
      singleObsCompliantCount +
      multiObsCondition10Count +
      multiObsCondition8Count * 0.8 +
      multiObsCondition5Count * 0.5;
    const findingsScoreCount = totalFindingsCount;

    return {
      compliantFindingsCount: totalCompliantCount,
      nonCompliantFindingsCount: totalNonCompliantCount,
      totalFindingsCount,
      ...getScoreData(findingsScoreSum, findingsScoreCount),
    };
  };

  var getScoreData = function (findingsScoreSum, findingsScoreCount) {
    var scoreNumber = findingsScoreSum / findingsScoreCount;
    scoreNumber = isNaN(scoreNumber) ? 1 : scoreNumber;
    const passFailScore = getPassFailScore(scoreNumber);

    return getScores(passFailScore ?? scoreNumber);
  };

  var getScores = function (scoreNumber) {
    scoreNumber = Misc.floorDecimals(scoreNumber, 1);
    const scoreGrade = getScoreGrade(scoreNumber);
    const scoreClass = getScoreClass(scoreNumber);

    return {
      scoreNumber,
      siteLetterGrade: scoreGrade,
      siteLetterGradeClass: scoreClass,
    };
  };

  var getScoreGrade = function (scoreNumber) {
    if (scoreNumber < 0.6) {
      return "F";
    } else if (scoreNumber < 0.7) {
      return "D";
    } else if (scoreNumber < 0.8) {
      return "C";
    } else if (scoreNumber < 0.9) {
      return "B";
    } else {
      return "A";
    }
  };

  var getScoreClass = function (scoreNumber) {
    if (scoreNumber < 0.7) {
      return "red";
    } else {
      return "green";
    }
  };

  var getPassFailScore = function (scoreNumber) {
    const inspectionType = NewProjectInspectionController.getAllData().inspectionType;
    if (inspectionType !== "close-out") {
      return null;
    }

    const passes = scoreNumber === 1;

    return passes ? 1 : 0;
  };

  return {
    getSummaryHtml,
  };
};

module.exports = NewProjectInspectionSummary();

const Misc = require("../../misc");
const NewProjectInspectionController = require("./newProjectInspectionController");
const Tree = require("../../tree");
