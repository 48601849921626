"use strict";

const ConstructionIcon = function () {
  var getConstructionProjectIconMarker = function (constructionProjectData, originalIconSize) {
    const largeGroup = Session.isLargeGroup();
    const customResize = largeGroup
      ? [10, 10, 20, 20, 25, 30, 30, 35, 35, 40, 43, 43]
      : [11, 13, 18, 32, 32, 36, 43, 43];
    const updatedIconSizeOptions = MapFunctions.resizeIconsOnZoom(
      Tree.get("zoomLevel"),
      40,
      20,
      "icon-text-large",
      "icon-text-medium",
      customResize,
      largeGroup ? [8, 8, 8, 10, 16, 16, 16, 20, 20, 20, 20, 20] : [5, 5, 10, 15, 17, 17, 20, 20],
    );

    const iconSvgClass = getConstructionProjectIconClass(constructionProjectData, true);
    let formatedIconClass = "construction-marker-icon " + iconSvgClass;
    if (updatedIconSizeOptions.zoomClass !== undefined) {
      formatedIconClass =
        "construction-marker-icon " + iconSvgClass + " " + updatedIconSizeOptions.zoomClass;
    }
    return new L.DivIcon({
      className: formatedIconClass,
      iconSize: originalIconSize ? [24, 29] : updatedIconSizeOptions.size,
      iconAnchor: updatedIconSizeOptions.anchor,
      shadowSize: [34, 45],
      shadowAnchor: [9, 35],
      popupAnchor: [0, 3],
    });
  };

  /**
   * @param constructionProjectData obj
   *   @param phase str
   *   @param highPriority bool
   *   @param isScorePassing bool
   *   @param hasUnresolvedEnforcementAction bool               Whether to show the enforcement action outline
   *   @param inspectionDue bool
   *   @param nextInspection obj
   *    @param isDue bool
   */
  var addComplianceIconClassParts = function (classParts, data, includePriority) {
    const isPriority = includePriority && data.isHighPriority;
    addComplianceActiveIconClassParts(classParts, data, isPriority);
  };

  var addComplianceActiveIconClassParts = function (classParts, data, isPriority) {
    if (data.hasEnforcementAction) {
      classParts.push("enforcement");
    }
    if (isPriority) {
      classParts.push("priority");
    }
    if (data.conditionName) {
      classParts.push(data.conditionName);
    }
  };

  var getOtherIconClasses = function (data) {
    if (Tree.get("dataView") !== "construction-project-delivery" && data.inspectionDue) {
      return [MapStyles.dueIconClass];
    }

    return [];
  };

  var getConstructionProjectIconClass = function (
    constructionProjectData,
    includePriority = false,
    isTodo = false,
  ) {
    const data = processConstructionProjectData(constructionProjectData);
    const currentDataView = Tree.get("dataView");
    if (currentDataView === "construction-project-delivery") {
      includePriority = false;
    }
    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase" && !isTodo) {
      return getConstructionProjectIconClassByPhase(data, includePriority);
    } else {
      return getConstructionProjectIconClassByCondition(data, includePriority);
    }
  };

  var getConstructionProjectIconClassByPhase = function (data, includePriority) {
    const priority = includePriority && data.isHighPriority ? "-priority" : "";
    return `construction-phase-${data.phase}${priority}`;
  };

  var getConstructionProjectIconClassByCondition = function (data, includePriority) {
    const classParts = ["construction"];
    addComplianceIconClassParts(classParts, data, includePriority);
    return [classParts.join("-"), ...getOtherIconClasses(data)].join(" ");
  };

  var getConstructionProjectConditionName = function (isScorePassing) {
    if (isScorePassing === null || isScorePassing === undefined) {
      return "no-inspection";
    }
    if (isScorePassing) {
      return "satisfactory";
    } else {
      return "unsatisfactory";
    }
  };

  var getNewConstructionProjectIconMarker = function () {
    const originalIconSize = true;
    const phase = ProjectInventoryModalController.getLatestConstructionData("phase");
    const data = { phase, isScorePassing: null };
    data.phase = ProjectInventoryModalController.getPhase(data);
    return getConstructionProjectIconMarker(data, originalIconSize);
  };

  var processConstructionProjectData = function (constructionProjectData) {
    return {
      phase: constructionProjectData.phase,
      hasEnforcementAction: constructionProjectData.hasUnresolvedEnforcementAction,
      isHighPriority: constructionProjectData.highPriority,
      inspectionDue: constructionProjectData.nextInspection
        ? constructionProjectData.nextInspection.isDue
        : (constructionProjectData.isDue ?? null),
      conditionName: getConstructionProjectConditionName(constructionProjectData.isScorePassing),
    };
  };

  var getMarkerOpacity = function (mapId, currentLayerId) {
    return mapId === "modal"
      ? MapFunctions.getModalMapMarkerOpacity(
          currentLayerId,
          ProjectInventoryModalController.getExistingConstructionData("id"),
        )
      : 1;
  };

  return {
    getConstructionProjectIconMarker,
    getConstructionProjectIconClass,
    getNewConstructionProjectIconMarker,
    getMarkerOpacity,
    getConstructionProjectIconClassByPhase,
  };
};

module.exports = ConstructionIcon();

const MapFunctions = require("../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const Tree = require("../tree");
const Session = require("../login/session");
